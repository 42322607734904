import axios from "axios"
import {
  EOfferDuration,
  EOfferType,
  EServiceOptionType,
  EServiceType,
  EJdgEnergyDestinationType,
  EEnergyDestinationType,
  EEInvoiceType,
  EEInvoiceAddressType,
  ECommercialEnergyDestinationType,
} from "../models/OffersEnums"
import { AgreementsRequest } from "../models/OffersInterfaces"
import { EAddressType, ECorrespondenceAddressType } from "../models/OffersEnums"
import { ContractType } from "../components/ToggleButtons"
import { PowerData } from "../components/WattageSelector"

// axios.interceptors.response.use(
//   response => {
//     return response
//   },
//   function (error) {
//     return Promise.reject(error.response)
//   }
// )

export interface FormParams {
  captcha: string
  formValue
}

export interface PPEaddressResponse {
  postalCode: string
  city: string
  street: string
  buildingNumber: string
  apartmentNumber: string
}

export interface CustomerDataResponse {
  commercialClient: boolean
  companyName: string
  taxNumber: string
  firstName: string
  lastName: string
  email: boolean
  phone: string
  ppeAddress: {
    street: string
    city: string
  }
  address: {
    street: string
    city: string
  }
  correspondenceAddress: {
    street: string
    city: string
  }
}

export const getCurrentComercialCustomerData = async (
  taxNumber: string,
  customerNumber: string
): Promise<CustomerDataResponse> => {
  const params: string[] = ["TaxNumber=" + taxNumber, "CustomerNumber=" + customerNumber]
  const url: string = process.env.GATSBY_API_URL + "Customer/comercialClientAgreementData?" + params.join("&")

  return (await axios.get<CustomerDataResponse>(url)).data
}

export const getCurrentIndividualCustomerData = async (
  pesel: string,
  customerNumber: string
): Promise<CustomerDataResponse> => {
  const params: string[] = ["Pesel=" + pesel, "CustomerNumber=" + customerNumber]
  const url: string = process.env.GATSBY_API_URL + "Customer/individualClientAgreementData?" + params.join("&")

  return (await axios.get<CustomerDataResponse>(url)).data
}

export const getPPE = async (pzoZot, meterNumber) => {
  const params = ["DocumentNumber=" + pzoZot, "MeterNumber=" + meterNumber]
  const url: string = process.env.GATSBY_API_URL + "Meters/address?" + params.join("&")

  return (await axios.get<PPEaddressResponse>(url)).data
}

export const getCityRequest = async (postalCode) => {
  const url: string = process.env.GATSBY_API_URL + "Address/" + postalCode

  return (
    await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
  ).data
}

export const submitRenewalAgreements = async ({ captcha, formValue }: FormParams) => {
  const requestData: AgreementsRequest = (formValue as unknown) as AgreementsRequest
  const wrapper = {
    "RequestWrapper.request.customer.PPE.city": requestData.customer.PPE.city,
    "RequestWrapper.request.customer.PPE.street": requestData.customer.PPE.street,
    "RequestWrapper.request.customer.PzoZot": requestData.customer.PzoZot,
    "RequestWrapper.request.customer.customerType": requestData.customer.customerType,
    "RequestWrapper.request.customer.foreignClient": requestData.customer.foreignClient,
    "RequestWrapper.request.customer.companyName": requestData.customer.companyName,
    "RequestWrapper.request.customer.email": requestData.customer.email,
    "RequestWrapper.request.customer.emailAgree": requestData.customer.emailAgree,
    "RequestWrapper.request.customer.firstName": requestData.customer.firstName,
    "RequestWrapper.request.customer.lastName": requestData.customer.lastName,
    "RequestWrapper.request.customer.meterNumber": requestData.customer.meterNumber,
    "RequestWrapper.request.customer.meterQuantity": requestData.customer.meterQuantity,
    "RequestWrapper.request.customer.phone": requestData.customer.phone,
    "RequestWrapper.request.customer.phonePrefix": requestData.customer.phonePrefix,
    "RequestWrapper.request.customer.phoneAgree": requestData.customer.phoneAgree,
    "RequestWrapper.request.customer.taxNumber": requestData.customer.taxNumber,
    "RequestWrapper.request.customer.pesel": requestData.customer.pesel,
    "RequestWrapper.request.customer.customerNumber": requestData.customer.customerNumber,
    "RequestWrapper.request.offer.isLong": requestData.offer.isLong,
    "RequestWrapper.request.offer.offerType": requestData.offer.offerType,
    "RequestWrapper.request.service.serviceType": EServiceType.NONE,
    "RequestWrapper.request.service.serviceOptionType": EServiceOptionType.NONE,
    "RequestWrapper.request.offerData.address.city": requestData.offerData.address.city,
    "RequestWrapper.request.offerData.address.street": requestData.offerData.address.street,
    "RequestWrapper.request.offerData.addressType": requestData.offerData.addressType,
    "RequestWrapper.request.offerData.aggreeType": requestData.offerData.aggreeType,
    "RequestWrapper.request.offerData.correspondenceAddress.city": requestData.offerData.correspondenceAddress.city,
    "RequestWrapper.request.offerData.correspondenceAddress.street": requestData.offerData.correspondenceAddress.street,
    "RequestWrapper.request.offerData.correspondenceAddressType": requestData.offerData.correspondenceAddressType,
    "RequestWrapper.request.offerData.document.documentNumber": requestData.offerData.document.documentNumber,
    "RequestWrapper.request.offerData.document.documentType": requestData.offerData.document.documentType,
    "RequestWrapper.request.offerData.eInvoiceAddress": requestData.offerData.eInvoiceAddress,
    "RequestWrapper.request.offerData.eInvoiceAddressType": requestData.offerData.eInvoiceAddressType,
    "RequestWrapper.request.offerData.eInvoiceType": requestData.offerData.eInvoiceType,
    "RequestWrapper.request.offerData.energyDestination": requestData.offerData.energyDestination,
    "RequestWrapper.request.offerData.energyDestinationType": requestData.offerData.energyDestinationType,
    "RequestWrapper.request.offerData.jdgEnergyDestination": requestData.offerData.jdgEnergyDestination,
    "RequestWrapper.request.offerData.jdgEnergyDestinationType": requestData.offerData.jdgEnergyDestinationType,
    "RequestWrapper.request.offerData.ownershipSource": requestData.offerData.ownershipSource,
    "RequestWrapper.request.offerData.ownershipSourceType": requestData.offerData.ownershipSourceType,
    "RequestWrapper.request.offerData.ownershipType": requestData.offerData.ownershipType,
    "RequestWrapper.request.offerData.partnerAddress.city": requestData.offerData.partnerAddress.city,
    "RequestWrapper.request.offerData.partnerAddress.street": requestData.offerData.partnerAddress.street,
    "RequestWrapper.request.offerData.partnerAddressType": requestData.offerData.partnerAddressType,
    "RequestWrapper.request.offerData.partnerData.name": requestData.offerData.partnerData.name,
    "RequestWrapper.request.offerData.partnerData.document.documentType":
      requestData.offerData.partnerData.document.documentType,
    "RequestWrapper.request.offerData.partnerData.document.documentNumber":
      requestData.offerData.partnerData.document.documentNumber,
    "RequestWrapper.request.offerData.statementType": requestData.offerData.statementType,
    "RequestWrapper.request.offerData.plugin": requestData.offerData.plugin,
    "RequestWrapper.request.offerData.wallBox": requestData.offerData.wallBox,
  }

  const mappedValues = {
    ...wrapper,
    "RequestWrapper.recaptchaToken": captcha,
  }

  const formData = new FormData()
  Object.keys(mappedValues).forEach((key) => {
    formData.append(key, mappedValues[key])
  })

  return (
    await axios.post<string>(process.env.GATSBY_API_URL + "Agreements/renewal", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  ).data
}

export const getCustomerPowerRange = async (PzoZot: string, meterNumber: string) => {
  const params = ["DocumentNumber=" + PzoZot, "MeterNumber=" + meterNumber]
  const url: string = process.env.GATSBY_API_URL + "Meters/contractedPower?" + params.join("&")

  return (await axios.get<PowerData>(url)).data
}

export const submitData = async ({ captcha, formValue }: FormParams) => {
  const requestData: AgreementsRequest = (formValue as unknown) as AgreementsRequest

  if (requestData.offerData.addressType === EAddressType.PPE) {
    requestData.offerData.address = requestData.customer.PPE
  }

  if (requestData.offerData.correspondenceAddressType === ECorrespondenceAddressType.ADDRESS) {
    requestData.offerData.correspondenceAddress = requestData.offerData.address
  }

  const wrapper = {
    "RequestWrapper.Request.Customer.customerType": requestData.customer.customerType,
    "RequestWrapper.Request.Customer.CompanyName": requestData.customer.companyName,
    "RequestWrapper.Request.Customer.TaxNumber": requestData.customer.taxNumber,
    "RequestWrapper.Request.Customer.FirstName": requestData.customer.firstName,
    "RequestWrapper.Request.Customer.LastName": requestData.customer.lastName,
    "RequestWrapper.Request.Customer.Email": requestData.customer.email,
    "RequestWrapper.Request.Customer.Phone": requestData.customer.phone,
    "RequestWrapper.Request.Customer.PhonePrefix": requestData.customer.phonePrefix,
    "RequestWrapper.Request.Customer.PzoZot": requestData.customer.PzoZot,
    "RequestWrapper.Request.Customer.MeterNumber": requestData.customer.meterNumber,
    "RequestWrapper.Request.Customer.Regon": requestData.customer.regon,
    "RequestWrapper.Request.Customer.Krs": requestData.customer.krs,
    "RequestWrapper.Request.Customer.PhoneAgree": requestData.customer.phoneAgree,
    "RequestWrapper.Request.Customer.EmailAgree": requestData.customer.emailAgree,
    "RequestWrapper.Request.Customer.PPE.City": requestData.customer.PPE.city,
    "RequestWrapper.Request.Customer.PPE.PostalCode": requestData.customer.PPE.post,
    "RequestWrapper.Request.Customer.PPE.Street": requestData.customer.PPE.street,
    "RequestWrapper.Request.Customer.PPE.BuildingNumber": requestData.customer.PPE.nr1,
    "RequestWrapper.Request.Customer.PPE.ApartmentNumber": requestData.customer.PPE.nr2,
    "RequestWrapper.Request.Offer.OfferType": requestData.offer.offerType,
    "RequestWrapper.Request.Offer.OfferDuration": requestData.offer.isLong ? EOfferDuration.THREE : EOfferDuration.TWO,
    "RequestWrapper.request.service.serviceType": EServiceType.NONE,
    "RequestWrapper.request.service.serviceOptionType": EServiceOptionType.NONE,
    "RequestWrapper.Request.OfferData.Document.DocumentType": requestData.offerData.document.documentType,
    "RequestWrapper.Request.OfferData.Document.DocumentNumber": requestData.offerData.document.documentNumber,
    "RequestWrapper.Request.OfferData.AddressType": requestData.offerData.addressType,
    "RequestWrapper.Request.OfferData.Address.City": requestData.offerData.address.city,
    "RequestWrapper.Request.OfferData.Address.PostalCode": requestData.offerData.address.post,
    "RequestWrapper.Request.OfferData.Address.Street": requestData.offerData.address.street,
    "RequestWrapper.Request.OfferData.Address.BuildingNumber": requestData.offerData.address.nr1,
    "RequestWrapper.Request.OfferData.Address.ApartmentNumber": requestData.offerData.address.nr2,
    "RequestWrapper.Request.OfferData.CorrespondenceAddressType": requestData.offerData.correspondenceAddressType,
    "RequestWrapper.Request.OfferData.CorrespondenceAddress.City": requestData.offerData.correspondenceAddress.city,
    "RequestWrapper.Request.OfferData.CorrespondenceAddress.PostalCode":
      requestData.offerData.correspondenceAddress.post,
    "RequestWrapper.Request.OfferData.CorrespondenceAddress.Street": requestData.offerData.correspondenceAddress.street,
    "RequestWrapper.Request.OfferData.CorrespondenceAddress.BuildingNumber":
      requestData.offerData.correspondenceAddress.nr1,
    "RequestWrapper.Request.OfferData.CorrespondenceAddress.ApartmentNumber":
      requestData.offerData.correspondenceAddress.nr2,
    "RequestWrapper.Request.OfferData.StatementType": requestData.offerData.statementType,
    "RequestWrapper.Request.OfferData.PartnerData.Name": requestData.offerData.partnerData.name,
    "RequestWrapper.Request.OfferData.PartnerData.Document.DocumentType":
      requestData.offerData.partnerData.document.documentType,
    "RequestWrapper.Request.OfferData.PartnerData.Document.DocumentNumber":
      requestData.offerData.partnerData.document.documentNumber,
    "RequestWrapper.Request.OfferData.OwnershipType": requestData.offerData.ownershipType,
    "RequestWrapper.Request.OfferData.OwnershipSourceType": requestData.offerData.ownershipSourceType,
    "RequestWrapper.Request.OfferData.OwnershipSource": requestData.offerData.ownershipSource,
    "RequestWrapper.Request.OfferData.EnergyDestinationType":
      requestData.customer.customerType === ContractType.INDIVIDUAL
        ? requestData.offerData.energyDestinationType
        : EEnergyDestinationType.NONE,
    "RequestWrapper.Request.OfferData.EnergyDestination": requestData.offerData.energyDestination,
    "RequestWrapper.Request.OfferData.JdgEnergyDestinationType":
      requestData.customer.customerType === ContractType.COMMERCIAL_JDG
        ? requestData.offerData.jdgEnergyDestinationType
        : EJdgEnergyDestinationType.NONE,
    "RequestWrapper.Request.OfferData.JdgEnergyDestination": requestData.offerData.jdgEnergyDestination,
    "RequestWrapper.Request.OfferData.CommercialEnergyDestinationType":
      requestData.customer.customerType === ContractType.COMMERCIAL_WITH_PREMISES
        ? requestData.offerData.commercialEnergyDestinationType
        : ECommercialEnergyDestinationType.NONE,
    "RequestWrapper.Request.OfferData.CommercialEnergyDestination": requestData.offerData.commercialEnergyDestination,
    "RequestWrapper.Request.OfferData.EInvoiceType": requestData.offerData.eInvoiceType,
    "RequestWrapper.Request.OfferData.EInvoiceAddress":
      requestData.offerData.eInvoiceType === EEInvoiceType.YES ? requestData.customer.email : "",
    "RequestWrapper.Request.OfferData.EInvoiceAddressType":
      requestData.offerData.eInvoiceType === EEInvoiceType.YES
        ? EEInvoiceAddressType.CLIENT
        : EEInvoiceAddressType.NONE,
    "RequestWrapper.Request.OfferData.AggreeType": requestData.offerData.aggreeType,
    "RequestWrapper.Request.OfferData.ContractedPowerType": requestData.offerData.contractedPowerType,
    "RequestWrapper.Request.OfferData.ContractedPower": requestData.offerData.contractedPower,
    "FileWrapper.file": formValue.file,
    "FilePowerOfAttorneyWrapper.file": formValue.fileProxy,
  }

  const mappedValues = {
    ...wrapper,
    "RequestWrapper.recaptchaToken": captcha,
  }

  const formData = new FormData()
  Object.keys(mappedValues).forEach((key) => {
    formData.append(key, mappedValues[key])
  })

  return (
    await axios.post<string>(process.env.GATSBY_API_URL + "Agreements", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  ).data
}

export const getCustomerDataByToken = async (userToken: string) => {
  const url: string = process.env.GATSBY_API_URL_MBOK + "Accounts/autocompleteNewOfferForm/" + userToken

  const instance = axios.create({
    withCredentials: false,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    },
  })

  const testResponse = {
    customerNumber: "30006561",
    phoneNumber: "+48 32 279 49 42",
    email: "magdalena.kiebala@euvic.pl",
    accountType: "Individual",
    addressLine1: "ul. Marcina z Wrocimowic 12 C m. 204",
    addressLine2: "03-145 Warszawa",
    firstName: "antek",
    lastName: "kieł",
    companyName: "Test",
  }

  return (await axios.get<string>(url)).data

  //return testResponse
}
