import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import { ClientType } from "../api/form"

const useStyles = makeStyles((theme: Theme) => ({
  selectContainer: {
    margin: "32px 0",
    [theme.breakpoints.down("md")]: {
      margin: "20px 0",
    },
    width: "100%",
    borderRadius: "7px",
  },
  offerSelectContainer: {
    margin: "0px 0",
    [theme.breakpoints.down("md")]: {
      margin: "20px 0",
    },
    width: "100%",
    borderRadius: "7px",
  },
  selectButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "52px",
    width: "100%",
    margin: 0,
    padding: "0 8px",
    border: 0,
    borderRadius: "7px",
    boxShadow: "inset -2px -2px 7px rgba(255, 255, 255, 0.35), inset 1px 1px 5px rgba(61, 61, 61, 0.05)",
    color: "#3A3A3A",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "15px",
    letterSpacing: "0.27px",
    textTransform: "none",
    lineHeight: "19px",
    opacity: 0.75,
    [theme.breakpoints.down("sm")]: {
      height: "54px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "60px",
      fontSize: "14px",
      padding: "0 4px",
    },
    "&.Mui-disabled": {
      opacity: 0.5,
    },
  },
  moreColors: {
    backgroundColor: "#d9f1ee",
  },
  selected: {
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  bigButton: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  ToogleDot: {
    backgroundColor: "#ccc",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
  },
  warningColor: {
    color: "#CC1450",
  },
}))

export enum ContractType {
  INDIVIDUAL = "Individual",
  COMMERCIAL_JDG = "CommercialJdg",
  COMMERCIAL_WITH_PREMISES = "CommercialWithPremises",
}

interface Props {
  contractType: ContractType
  handleChangeContractType: (contractType: ContractType) => void
}

export const ToggleButtons: React.FC<Props> = ({ contractType, handleChangeContractType }) => {
  const classes = useStyles()
  return (
    <ToggleButtonGroup
      value={contractType}
      exclusive
      onChange={(_, value) => handleChangeContractType(value)}
      aria-label="ChooseRecipient"
      className={classes.selectContainer}
    >
      <ToggleButton
        value={ContractType.INDIVIDUAL}
        aria-label={ClientType.Individual}
        className={`${classes.selectButton} ${contractType === ContractType.INDIVIDUAL && classes.selected}`}
      >
        Klient indywidualny
      </ToggleButton>
      <ToggleButton
        value={ContractType.COMMERCIAL_JDG}
        aria-label="Commercial"
        className={`${classes.selectButton} ${contractType === ContractType.COMMERCIAL_JDG && classes.selected} ${
          classes.bigButton
        }`}
      >
        Jednoosobowa działalność gospodarcza
      </ToggleButton>
      <ToggleButton
        value={ContractType.COMMERCIAL_WITH_PREMISES}
        aria-label="CommercialWithPremises"
        className={`${classes.selectButton} ${
          contractType === ContractType.COMMERCIAL_WITH_PREMISES && classes.selected
        } ${classes.bigButton}`}
      >
        <p>
          Klient komercyjny podpisujący umowę <br /> na lokal mieszkalny lub
          <span className={classes.warningColor}> usługowy</span>
        </p>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

interface OfferDurationProps {
  isLong: boolean
  handleIsLong: (isLong: boolean) => void
  disabled: boolean
}

export const OfferDurationToggleButtons: React.FC<OfferDurationProps> = ({ isLong, handleIsLong, disabled }) => {
  const classes = useStyles()
  return (
    <ToggleButtonGroup
      value={isLong}
      exclusive
      onChange={(_, value) => handleIsLong(value)}
      aria-label="chooseRecipient"
      className={classes.offerSelectContainer}
    >
      <ToggleButton
        disabled={disabled}
        value={false}
        aria-label={ClientType.Individual}
        className={`${classes.selectButton} ${classes.moreColors} ${!isLong && classes.selected}`}
      >
        <div>2 lata</div>
      </ToggleButton>
      <ToggleButton
        disabled={disabled}
        value={true}
        aria-label="Commercial"
        className={`${classes.selectButton} ${classes.moreColors} ${isLong && classes.selected} ${classes.bigButton}`}
      >
        3 lata
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

interface ForeignClientProps {
  isForeignClient: boolean
  handleIsForeignClient: (isForeignClient: boolean) => void
}

export const ForeignClientToggleButtons: React.FC<ForeignClientProps> = ({
  isForeignClient,
  handleIsForeignClient,
}) => {
  const classes = useStyles()
  return (
    <ToggleButtonGroup
      value={isForeignClient}
      exclusive
      onChange={(_, value) => handleIsForeignClient(value)}
      aria-label="chooseRecipient"
      className={classes.offerSelectContainer}
    >
      <ToggleButton
        value={false}
        aria-label="Domestic"
        className={`${classes.selectButton} ${classes.moreColors} ${!isForeignClient && classes.selected}`}
      >
        <div>Numer paszportu</div>
      </ToggleButton>
      <ToggleButton
        value={true}
        aria-label="Foreignt"
        className={`${classes.selectButton} ${classes.moreColors} ${isForeignClient && classes.selected} ${
          classes.bigButton
        }`}
      >
        Numer karty pobytu
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
