import {
  CustomerDataResponse,
  getCurrentComercialCustomerData,
} from "../../../../api/wlaczPradForm"

export default class GetComercialTabDataRequest {
  onRequestSuccess: (data: CustomerDataResponse) => void
  onMessage: (message: string) => void

  constructor(onRequestSuccess, onMessage) {
    this.onRequestSuccess = onRequestSuccess
    this.onMessage = onMessage
  }

  async execute(taxNumber: string, customerNumber: string) {
    if (
      this.isTaxNumberValid(taxNumber) &&
      this.isCustomerNumberValid(customerNumber)
    ) {
      await this.fillCustomerForm(taxNumber, customerNumber)
    } else {
      this.onMessage(
        "Dane możemy pobrać tylko na podstawie NIP i numeru płatnika - proszę o wprowadznie odpowiednich wartości."
      )
    }
  }

  private isTaxNumberValid(taxNumber: string): boolean {
    return !!taxNumber
  }

  private isCustomerNumberValid(customerNumber: string): boolean {
    return !!customerNumber
  }

  private async fillCustomerForm(taxNumber: string, customerNumber: string) {
    const customerData: CustomerDataResponse = await getCurrentComercialCustomerData(
      taxNumber,
      customerNumber
    )
    this.onRequestSuccess(customerData)
  }
}
