// If you don't want to use TypeScript you can delete this file!
import React, { useState } from "react"

import SEO from "../components/seo"
import Footer from "../components/Footer"
import { ContainerWithFixedBg } from "../components/containerWithFixedBg"
import LandingImage from "../components/images/landing-bg"
import FlyingPhone from "../components/FlyingPhone"
import { LightsOnHeader } from "../components/sections/lightsOnHeader"
import { LightsOnContent } from "../components/sections/lightsOnContent"
import { ELandingPage } from "../models/OffersEnums"
import { ContractTypeProvider } from "../context/ContractTypeContext"

const UsingTypescript: React.FC = () => {
  const [showSummary, setShowSummary] = useState(false)
  return (
    <>
      <SEO title="" />
      <ContractTypeProvider>
        <LightsOnHeader />
        <ContainerWithFixedBg
          className={"noPadding"}
          ImageComponent={LandingImage}
          fixed={true}
        >
          <LightsOnContent
            showSummary={showSummary}
            setShowSummary={setShowSummary}
            landingPage={ELandingPage.NEW_CUSTOMER}
          />

          <FlyingPhone />
        </ContainerWithFixedBg>
        <Footer
          landingPage={ELandingPage.NEW_CUSTOMER}
          hideDocuments={showSummary}
        />
      </ContractTypeProvider>
    </>
  )
}

export default UsingTypescript
