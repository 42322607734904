import React from "react"
import {
  Grid,
  Card,
  CardMedia,
  Radio,
  Theme,
  makeStyles,
} from "@material-ui/core"
import { CardStyledButton } from "../Button"
import scrollTo from "gatsby-plugin-smoothscroll"
import { graphql, useStaticQuery } from "gatsby"
import { DocumentIcon } from "./DocumentIcon"
import { ContractType } from "../ToggleButtons"

const OfferCard = ({ className, value, offer, sm, contractType }) => {
  const useStyles = makeStyles((theme: Theme) => ({
    cardTop: {
      backgroundColor: "#fff",
      paddingTop: "30px",
    },
    offerHeader: {
      fontSize: "32px",
      marginTop: "11px",
      marginBottom: "0px",
      paddingRight: "16px",
      paddingLeft: "16px",
      minHeight: "100px",
    },
    offerTitleAccent: {
      color: "#12beab",
    },
    offerPaperCardSmall: {
      textAlign: "center",
      paddingTop: "14px",
      paddingBottom: "14px",
      backgroundColor: "#fff",
      "& p": {
        fontSize: "14px",
        margin: "0px",
        paddingTop: "8px",
        fontWeight: 700,
        paddingRight: "16px",
        paddingLeft: "16px",
        minHeight: "50px",
      },
    },
    offerDescriptionContainer: {
      backgroundColor: "#f8f8f8",
      paddingLeft: "30px",
      paddingRight: "30px",
      marginBottom: "11px",
      "& p": {
        paddingTop: "24px",
        paddingBottom: "0",
        margin: "0px",
        textAlign: "center",
        lineHeight: "1.7",
      },
    },
    cardButtonPadding: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  }))

  const LpImages = useStaticQuery(graphql`
    {
      gwarancjaRabatu: file(relativePath: { eq: "lp02_gwarancja_rabatu.png" }) {
        publicURL
      }
      gwarancjaCeny: file(relativePath: { eq: "lp02_gwarancja_ceny.png" }) {
        publicURL
      }
      standard: file(relativePath: { eq: "lp02_standard.png" }) {
        publicURL
      }
      document: file(relativePath: { eq: "Group 4.svg" }) {
        publicURL
      }
      intro: file(relativePath: { eq: "intro.png" }) {
        publicURL
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        publicURL
      }
    }
  `)

  const classes = useStyles()
  const titleWords = offer.title.split(" ")
  const titleFirstWord = titleWords.shift() || ""
  const restOfTitle = titleWords.join(" ")

  return (
    <Grid item xs={12} lg={sm}>
      <Card className={className}>
        <div className={classes.cardTop}>
          <p className={classes.offerHeader}>
            <span className={classes.offerTitleAccent}>{titleFirstWord}</span>
            {` ${restOfTitle}`}
          </p>
        </div>
        <CardMedia
          component="img"
          alt={offer.title}
          height="140"
          image={LpImages[`${offer.img}`].publicURL}
          title={offer.title}
        />
        <div className={classes.offerPaperCardSmall}>
          <a
            target="_blank"
            key={offer.document.path}
            href={offer.document.path}
          >
            <DocumentIcon />
          </a>
          <p>{offer.document.name}</p>
        </div>
        {contractType !== ContractType.COMMERCIAL_WITH_PREMISES && (
          <div className={classes.offerDescriptionContainer}>
            <p>{offer.description}</p>
          </div>
        )}
      </Card>
    </Grid>
  )
}

export default OfferCard
