import React from "react"
import { makeStyles, Theme } from "@material-ui/core"

export interface SectionNumberProps {
  id: number
}

export const SectionNumber: React.FC<SectionNumberProps> = (
  props: SectionNumberProps
) => {
  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      position: "relative",
    },
    labelContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      "& label": {
        lineHeight: "56px",
        fontSize: "14px",
        fontWeight: 900,
        color: "#fff",
      },
    },
  }))

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.1" cx="24" cy="24" r="24" fill="#12BEAB" />
          <circle opacity="0.5" cx="24" cy="24" r="16" fill="#12BEAB" />
          <circle cx="24" cy="24" r="10" fill="#12BEAB" />
        </svg>
      </div>
      <div className={classes.labelContainer}>
        <label>{props.id}</label>
      </div>
    </div>
  )
}
