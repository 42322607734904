import React, { useState } from "react"
import { makeStyles, Theme, Typography, Button, Grid } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import { createStyles, useTheme } from "@material-ui/styles"
import { DateRange } from "../meetingModal/datePicker/hourPicker/hourPicker"
import { SuccessModal } from "../successModal/successModal"
import { maxWidth, relativeContentPadding } from "../../styles/constants"
import scrollTo from "gatsby-plugin-smoothscroll"
import { gwarancje } from "./static-data/gwarancje"
import { ErrorModal } from "../errorModal/errorModal"
import Consumer from "../../context/Context"
import { graphql, StaticQuery, useStaticQuery } from "gatsby"
import LandingImage from "../../components/images/landing-bg"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { EDocumentVersion, ELandingPage } from "../../models/OffersEnums"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overlay: {
      zIndex: 0,
    },
    topContainer: {
      ...relativeContentPadding,
    },
    cardsContainer: {
      ...maxWidth,
      display: "flex",
      alignItems: "flex-start",
      overflow: "hidden",
      flexDirection: "row",
      paddingBottom: "80px",
      "& .MuiCard-root": {
        margin: "20px 30px",
        flex: 1,
        [theme.breakpoints.down("lg")]: {
          margin: "20px 15px",
        },
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    formSection: {
      minWidth: "600px",
      maxWidth: "610px",
      margin: 0,
      marginRight: "-30px",
      [theme.breakpoints.down("lg")]: {
        marginRight: "-15px",
      },
      [theme.breakpoints.down("md")]: {
        width: "auto",
        margin: 0,
        maxWidth: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    formButton: {
      position: "fixed",
      bottom: "12px",
      right: "12px",
      width: "65px",
      height: "65px",
      borderRadius: "32.5px",
      zIndex: 999,
    },
    mainCard: {
      flex: "1 1 auto !important",
      width: "100%",
    },
    clickableCard: {
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
    },
    infoSection: {
      display: "flex",
      flexWrap: "wrap",
      flex: "1 1 auto",
      margin: 0,
      marginLeft: "-30px",
      "& h2": {
        textAlign: "center",
        marginBottom: "20px",
        marginTop: 0,
      },
      "& b": {
        marginBottom: "15px",
        display: "block",
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: "-15px",
      },
      [theme.breakpoints.down("md")]: {
        margin: 0,
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        margin: 0,
        "& .MuiCard-root": {
          margin: "20px 0",
          flex: 1,
          width: "100%",
        },
      },
    },
    infoImg: {
      height: "145px",
      marginBottom: "20px",
      borderRadius: "8px",
    },
    root: {
      display: "flex",
      padding: "0px",
    },
    details: {
      marginTop: "100px",
      marginBottom: "100px",
      marginRight: "15px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginRight: "10px",
        marginBottom: "20px",
        marginTop: "20px",
      },
    },
    content: {
      flex: "1 0 auto",
      paddingLeft: "50px",
      paddingRight: "50px",
      "& img": {
        paddingTop: "30px",
        paddingBottom: "30px",
      },
      "& button": {
        paddingTop: "30px",
        paddingBottom: "30px",
      },
    },
    cover: {
      width: 500,
      [theme.breakpoints.down("md")]: {
        width: 0,
      },
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    justRight: {
      textAlign: "right",
    },
    buttonIntro: {
      marginTop: "30px",
      marginBottom: "30px",
      paddingLeft: "20px",
      paddingRight: "20px",
      minWidth: "126px",
      height: "37px",
      borderRadius: "7px",
      borderSize: "2.5px",
      padding: 0,
      fontSize: "12px",
      letterSpacing: "0.22px",
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        minWidth: "206px",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "170px",
      },
    },
    container: {
      ...maxWidth,
      position: "relative",
      ...relativeContentPadding(),
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "95px",
        paddingRight: "95px",
      },
    },
    imageHeaderContainer: {
      textAlign: "center",
      paddingBottom: "40px",
    },
    headerImage: {
      width: "100%",
      backgroundPosition: "bottom center",
      backgroundRepeat: "repeat-y",
      backgroundSize: "cover",
    },
    detailsContent: {
      textAlign: "left",
      "& img": {
        paddingTop: "30px",
        paddingBottom: "30px",
      },
    },
  })
)

interface ILightsOnBannerProps {
  landingPage: ELandingPage
}

export const LightsOnBanner: React.FC<ILightsOnBannerProps> = ({
  landingPage,
}) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()

  const LpImages = useStaticQuery(graphql`
    {
      gwarancjaRabatu: file(relativePath: { eq: "lp02_gwarancja_rabatu.png" }) {
        publicURL
      }
      gwarancjaCeny: file(relativePath: { eq: "lp02_gwarancja_ceny.png" }) {
        publicURL
      }
      standard: file(relativePath: { eq: "lp02_standard.png" }) {
        publicURL
      }
      document: file(relativePath: { eq: "Group 4.svg" }) {
        publicURL
      }
      intro: file(relativePath: { eq: "intro.png" }) {
        publicURL
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        publicURL
      }
      headerBackground: file(relativePath: { eq: "bg_banner_2.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1367) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktop: file(relativePath: { eq: "bg_banner_2.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1367) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imageData = LpImages.headerBackground.childImageSharp.fluid

  let banerContent;

  if (landingPage === ELandingPage.NEW_CUSTOMER) {
    banerContent = <Typography variant="subtitle1" color="textSecondary" display="block">
      Jeżeli chcesz korzystać z energii od Polenergii, podpisz z nami umowę!
      <br />Poniższy formularz pozwoli Ci nie tylko podpisać umowę w 100% on-line, ale także umożliwi zapoznanie się z naszą ofertą.
      <br /><b>UWAGA! Wypełniając formularz uważnie sprawdzaj dane. Po ich zatwierdzeniu nie będzie możliwości ponownego wypełnienia formularza on-line i konieczny będzie kontakt z naszą infolinią!</b>
    </Typography>;
  } else {
    banerContent = <Typography variant="subtitle1" color="textSecondary" display="block">
      Wybierz nową atrakcyjną ofertę energii elektrycznej od Polenergii!
    </Typography>;
  }

  return (
    <div className={styles.imageHeaderContainer}>
      <BackgroundImage
        Tag="section"
        className={styles.headerImage}
        fluid={imageData}
        backgroundColor={`#040e18`}
      >
        <div className={styles.container}>
          <Grid container>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <Card className={styles.details}>
                <div className={styles.detailsContent}>
                  <img src={LpImages.logo.publicURL} />
                  <Typography component="h5" variant="h5">
                    Witaj na stronie Polenergii Dystrybucja.
                  </Typography>
                  {banerContent}
                  <div className={styles.justRight}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={styles.buttonIntro}
                      component="span"
                      onClick={() => {
                        scrollTo(`#section-1`)
                      }}
                    >
                      Rozpocznij
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </BackgroundImage>
    </div>
  )
}
