import React, { useState } from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core"
import StyledButton from "./Button"
import Megaphone from "./images/megaphone"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      padding: 0,
    },
    title: {
      maxHeight: "0px",
      padding: 0,
      textAlign: "right",
      "& button": {
        top: "-8px",
        right: "-8px",
        position: "relative",
        padding: "4px",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      padding: "10px 0",
      "& h1": {
        margin: 0,
        fontSize: "32px",
        color: "red",
        fontWeight: "bold",
      },
      "& h2": {
        margin: "12px 0 0",
        fontSize: "20px",
        color: "red",
      },
      "& h4": {
        margin: "12px 0",
        fontSize: "16px",
        color: "red",
        opacity: 0.7,
      },
      "& h3": {
        margin: "24px 0 0",
        fontSize: "18px",
        color: "red",
        opacity: 0.85,
      },
      [theme.breakpoints.down("sm")]: {
        "& h1": {
          fontSize: "30px",
          fontWeight: "bold",
        },
        "& h2": {
          fontSize: "18px",
        },
      },
      "& a": {
        textDecoration: "none",
        color: theme.palette.secondary.main,
        transition: "color 0.15s ease-in-out",
        "&:hover": {
          color: theme.palette.primary.dark,
          backgroundColor: "transparent",
        },
      },
    },
    button: {
      display: "block",
      margin: "24px 0 0",
      width: "305px",

      height: "48px",
      fontSize: "17px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        width: "60vw",
      },
    },
    summaryButtonsContainer: {
      display: "flex",
    },
    backButton: {
      borderColor: "#12beab",
      color: "#12beab",
      display: "block",
      margin: "24px 0 0",
      width: "305px",
      height: "48px",
      fontSize: "17px", 
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "9px",
        width: "60vw",
      },
    },
    buttonProgress: {
      color: "#00BDA8",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    loadingButtonWrapper: {
      position: "relative",
      margin: "24px 0 0",
      "& button": {
        margin: "0px",
      },
    },
    confirmationInfo: {
        color: "red",
        width: "600px",
        margin: "20px",
        fontWeight: "bold",
    },
  })
)

interface Props {
  show: boolean
  toggleModal: () => void
  confirmForm?: () => void
}

export const ConfirmationModal: React.FC<Props> = ({
  show,
  toggleModal,
  confirmForm,
}) => {
  const classes = useStyles()
  const [isFormSending, setIsFormSending] = useState(false)

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-describedby="form-modal"
      open={show}
      onClose={toggleModal}
      scroll="body"
      fullWidth
      maxWidth="md"
      className={classes.contentWrapper}
      PaperProps={{
        style: { borderRadius: 16 },
      }}
    >
      <div style={{textAlign: "center"}}>
        <Megaphone styles={{width: "200px",}}/>
      </div>
      <DialogTitle className={classes.title} />
      <DialogContent className={classes.content}>
        
        <h1>UWAGA!</h1>

        <div className={classes.confirmationInfo}>
            Upewnij się, że wypełniając formularz podałeś/podałaś 
            poprawne dane. Po ich zatwierdzeniu nie będzie możliwości 
            ponownego wypełnienia formularza on-line!
        </div>

        <div className={classes.summaryButtonsContainer}>
          <StyledButton
            color="primary"
            variant="outlined"
            onClick={() => toggleModal()}
            className={classes.backButton}
          >
            Powrót do formularza
          </StyledButton>
          &nbsp; &nbsp; &nbsp;
          <div className={classes.loadingButtonWrapper}>
            <StyledButton
              color="primary"
              variant="contained"
              onClick={() => {
                setIsFormSending(true)
                confirmForm()
              }}
              className={classes.button}
            >
              Jest OK, zatwierdzam
            </StyledButton>
            {isFormSending && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
