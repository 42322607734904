import React from "react"
import { Grid } from "@material-ui/core"
import { Field } from "formik"
import { CustomInput } from "../Input"
import { FormTypes } from "../sections/static-data/formularz"

export interface CustomInputFieldProps {
  formData: FormTypes
  validateField: (value, field: FormTypes) => string
}

export const CustomInputField: React.FC<CustomInputFieldProps> = ({
  formData,
  validateField,
  ...props
}) => {
  return (
    <Grid item md={3} xs={12}>
      <Field
        name={formData.name}
        validate={value => validateField(value, formData)}
      >
        {({ field, meta: { error, touched } }) => {
          const showError = Boolean(error && touched)
          return (
            <CustomInput
              disabled={formData.disabled}
              field={field}
              metaError={error}
              showError={showError}
              placeholder={formData.placeholder}
              onClick={() => {}}
            />
          )
        }}
      </Field>
    </Grid>
  )
}
