import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import {
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  useTheme,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import StyledButton from "./Button"
import { useStaticQuery, graphql } from "gatsby"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      padding: 0,
    },
    title: {
      maxHeight: "0px",
      padding: 0,
      textAlign: "right",
      "& button": {
        top: "-8px",
        right: "-8px",
        position: "relative",
        padding: "4px",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      padding: "24px",
      "& h1": {
        margin: 0,
        fontSize: "38px",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "& h2": {
        margin: "12px 0 0",
        fontSize: "20px",
        color: "#656D76",
        opacity: 0.55,
      },
      "& h4": {
        margin: "12px 0",
        fontSize: "16px",
        color: "#656D76",
        opacity: 0.7,
      },
      "& h3": {
        margin: "24px 0 0",
        fontSize: "18px",
        color: "#656D76",
        opacity: 0.85,
      },
      [theme.breakpoints.down("sm")]: {
        "& h1": {
          fontSize: "30px",
        },
        "& h2": {
          fontSize: "18px",
        },
      },
      "& a": {
        textDecoration: "none",
        color: theme.palette.secondary.main,
        transition: "color 0.15s ease-in-out",
        "&:hover": {
          color: theme.palette.primary.dark,
          backgroundColor: "transparent",
        },
      },
    },
    button: {
      display: "block",
      margin: "24px 0 0",
      width: "225px",
      height: "48px",
      fontSize: "17px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        width: "60vw",
      },
    },
    document: {
      width: "100%",
    },
  })
)

interface Props {
  show: boolean
  toggleModal: () => void
  source?: string
}

export const DocumentModal: React.FC<Props> = ({
  show,
  toggleModal,
  source,
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  const data = useStaticQuery(graphql`
    {
      pzo: file(relativePath: { eq: "PZO.JPG" }) {
        publicURL
      }
      zot: file(relativePath: { eq: "ZOT.JPG" }) {
        publicURL
      }
      invoicePix: file(relativePath: { eq: "Invoice.svg" }) {
        publicURL
      }
    }
  `)

  const getSource = () => {
    switch (source) {
      case "pzo":
        return data.pzo.publicURL
      case "zot":
        return data.zot.publicURL
      case "invoice":
        return data.invoicePix.publicURL
    }

    return data.pzo.publicURL
  }

  return (
    <Dialog
      aria-describedby="form-modal"
      open={show}
      onClose={toggleModal}
      scroll="body"
      fullWidth
      maxWidth="sm"
      className={classes.contentWrapper}
    >
      <DialogTitle className={classes.title}>
        <IconButton aria-label="close" onClick={toggleModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <img src={getSource()} className={classes.document} />
        <StyledButton
          color="primary"
          variant="contained"
          onClick={() => toggleModal()}
          className={classes.button}
        >
          Zamknij
        </StyledButton>
      </DialogContent>
    </Dialog>
  )
}
