import React, { useRef, useEffect, useState, useContext } from "react"
import { Formik, Form, Field } from "formik"
import { useTheme } from "@material-ui/styles"
import {
  makeStyles,
  Grid,
  Theme,
  Button,
  Portal,
  FormControlLabel,
  Radio,
  Divider,
  Card,
  Collapse,
  IconButton,
  CircularProgress,
} from "@material-ui/core"
import { CustomInput } from "../Input"
import { CardStyledButton, StyledButton } from "../Button"
import { ContractType, ToggleButtons } from "../ToggleButtons"
import { FormTypes, placeholdersDict, FormID } from "./static-data/formularz"
import { newCustomerOfferData } from "./static-data/newCustomerOffer"
import { getPPE, PPEaddressResponse, submitData, getCityRequest, getCustomerPowerRange } from "../../api/wlaczPradForm"
import { greyColor, maxWidth } from "../../styles/constants"
import { Transition, animated } from "react-spring"
import scrollTo from "gatsby-plugin-smoothscroll"
import ReCAPTCHA from "react-google-recaptcha"
import { trackCustomEvent } from "../../utils/analytics"
import { AttachFile, Info, Input } from "@material-ui/icons"
import { SummaryModal } from "../summaryModal"
import { DocumentModal } from "../documentModal"
import OfferCard from "../controls/OfferCard"
import RadioButtonsGroup from "../controls/RadioButtonsGroup"
import { getBasicInfoFields, getOfferDataFields } from "./static-data/lightsOnFields"
import { checkFile, getDocumentVersion, isValidPesel } from "../../utils/lightsOnFormUtils"
import { AddressCheckboxCopy } from "../controls/AddressCheckboxCopy"
import { CorrespondenceAddressCheckboxCopy } from "../controls/CorrespondenceAddressCheckboxCopy"
import {
  EDocumentVersion,
  EDocumentType,
  EOfferType,
  EServiceType,
  EServiceOptionType,
  EAddressType,
  ECorrespondenceAddressType,
  EStatementType,
  EOwnershipType,
  EOwnershipSourceType,
  EEnergyDestinationType,
  EJdgEnergyDestinationType,
  EEInvoiceType,
  EEInvoiceAddressType,
  EAggreeType,
  EConsentAggreeType,
  EFormTypes,
  EOfferDocumentsAnhor,
  ECommercialEnergyDestinationType,
  EPowerOption,
} from "../../models/OffersEnums"
import { AgreementsRequest } from "../../models/OffersInterfaces"
import { DateRange } from "../meetingModal/datePicker/hourPicker/hourPicker"
import { ErrorModal } from "../errorModal/errorModal"
import { SuccessModal } from "../successModal/successModal"
import { MeetingModal } from "../meetingModal/meetingModal"
import { FormModal } from "../formModal/formModal"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import Consumer from "../../context/Context"
import { isPostalCodeValid } from "./static-data/formUtils/IsPostalCodeValid"
import { CustomAutocompleteField } from "./../forms/CustomAutocompleteField"
import Tooltip from "@material-ui/core/Tooltip"
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"
import { ConfirmationModal } from "../ConfirmationModal"
import { AllMarketingConsentsCheckboxCopy } from "../controls/MarketingConsentsCheckboxCopy"
import PhoneTextField from "../PhoneTextField"
import { isValidPhoneNumber } from "react-phone-number-input"
import WattageSelector, { PowerData } from "../WattageSelector"
import { ContractTypeContext } from "../../context/ContractTypeContext"
import { SendUsEmailModal } from "../sendUsEmailModal/SendUsEmailModal"

let dateResolver = null
let dateReject = null

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    "& h1": {
      alignSelf: "flex-start",
      margin: 0,
      fontSize: "31px",
      letterSpacing: "1.05px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& h2": {
      alignSelf: "flex-start",
      color: greyColor,
      margin: 0,
      fontSize: "14px",
      letterSpacing: "0.10px",
      fontWeight: theme.typography.fontWeightRegular,
    },
    width: "100%",
  },
  recaptcha: {
    position: "fixed",
    top: "25%",
    left: "50%",
    zIndex: 1600,
    [theme.breakpoints.down("sm")]: {
      "& .grecaptcha-badge": {
        bottom: "5px !important",
      },
    },
  },
  formComponent: {
    width: "100%",
  },
  gridPaddingLeft: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px",
    },
    paddingLeft: "12px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      marginBottom: "4px",
    },
  },
  gridPaddingRight: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px",
    },
    paddingRight: "12px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      marginBottom: "4px",
    },
  },
  checkboxesSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#465B71",
    marginTop: "0px",
    marginBottom: "26px",
    "& p": {
      fontSize: "11px",
      letterSpacing: "0.3px",
      margin: 0,
    },
    "& > *": {
      display: "flex",
      alignItems: "center",
      margin: "16px 0 0",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#465B71",
    marginTop: "12px",
    "& p": {
      fontSize: "11px",
      letterSpacing: "0.3px",
      margin: 0,
    },
    "& > *": {
      display: "flex",
      alignItems: "center",
      margin: "16px 0 0",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
    marginBottom: "26px",
  },
  label: {
    alignItems: "flex-start",
    "& p": {
      marginLeft: "12px",
    },
  },
  fileAdding: {
    margin: "0px 0 0",
    "& p": {
      marginLeft: "16px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "16px 0 0",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "16px",
      "& p": {
        margin: "8px 0 0",
        textAlign: "center",
      },
    },
  },
  fileButton: {
    minWidth: "126px",
    height: "37px",
    borderRadius: "7px",
    padding: "0 25px",
    fontSize: "12px",
    letterSpacing: "0.22px",
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      minWidth: "206px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "170px",
    },
  },
  buttonIntro: {
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "20px",
    paddingRight: "20px",
    minWidth: "126px",
    height: "37px",
    borderRadius: "7px",
    borderSize: "2.5px",
    padding: 0,
    fontSize: "12px",
    letterSpacing: "0.22px",
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("sm")]: {
      minWidth: "206px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "170px",
    },
  },
  fillPpeButton: {
    paddingLeft: "20px",
    paddingRight: "20px",
    minWidth: "126px",
    height: "37px",
    borderRadius: "7px",
    borderSize: "2.5px",
    padding: 0,
    fontSize: "12px",
    letterSpacing: "0.22px",
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      minWidth: "206px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "170px",
    },
    width: "100%",
  },
  buttonsContainer: {
    ...maxWidth,
    paddingBottom: "60px",
    display: "block",
    margin: "0px auto",
    justifyContent: "right",
    textAlign: "right",
    [theme.breakpoints.down("lg")]: {
      paddingRight: "10px",
      paddingLeft: "10px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "20px auto",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "20px auto",
      flexDirection: "column",
    },
    "& button": {
      "&:first-child": {
        minWidth: "201px",
        [theme.breakpoints.down("xs")]: {
          minWidth: "85%",
          margin: "10px 0",
        },
      },
      "&:last-child": {
        minWidth: "268px",
        [theme.breakpoints.down("xs")]: {
          minWidth: "85%",
          margin: "10px 0",
        },
      },
      height: "46px",
      lineHeight: "normal",
    },
  },
  consentRequired: {
    color: theme.palette.error.main,
  },
  fileSizeError: {
    color: theme.palette.error.main,
    display: "block",
  },
  formValidationError: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    padding: "0 12px",
    borderRadius: "24px",
    backgroundColor: theme.palette.error.main,
    color: "white",
    width: "78%",
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      fontSize: "10.5px",
    },
    "& p": {
      margin: 0,
      marginLeft: "4px",
    },
  },
  footer: {
    margin: 0,
    color: "#465B71",
    textAlign: "center",
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.main,
      transition: "color 0.15s ease-in-out",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  },
  privacy: {
    margin: "12px 0 0",
    color: "black",
    textAlign: "center",
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      margin: "16px 0 4px",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
      transition: "color 0.15s ease-in-out",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  },
  dividerPadding: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  autocompletePadding: {
    marginTop: "8px",
    width: "100%",
  },
  maxRadioGroupWidth: {
    width: "100%",
  },
  defaultRadioGroup: {},
  cardsContainer: {
    ...maxWidth,
    display: "flex",
    alignItems: "flex-start",
    overflow: "hidden",
    flexDirection: "row",
    paddingBottom: "40px",
    "& .MuiCard-root": {
      flex: 1,
      [theme.breakpoints.down("lg")]: {
        margin: "20px 15px",
      },
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  offerCard: {
    textAlign: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    height: "100%",
    backgroundColor: "#f8f8f8",
    paddingTop: "0px",
    offerLinkButton: {},
  },
  serviceCard: {
    textAlign: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    height: "100%",
    backgroundColor: "#fff",
  },
  serviceCardDisabled: {
    textAlign: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    opacity: "0.7",
    height: "100%",
    backgroundColor: "#fff",
  },
  offerTitleAccent: {
    color: "#12beab",
  },
  offerHeader: {
    fontSize: "34px;",
    marginTop: "11px",
    marginBottom: "0px",
    paddingRight: "16px",
    paddingLeft: "16px",
    minHeight: "100px",
  },
  offerSubHeader: {
    color: "#656d76",
    paddingBottom: "35px",
    margin: "0px",
    fontWeight: "bold",
    paddingRight: "16px",
    paddingLeft: "16px",
  },
  offerCardDisabled: {
    textAlign: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    opacity: "0.7",
    height: "100%",
    backgroundColor: "#f8f8f8",
    paddingTop: "0px",
  },
  sectionHeader: {
    textAlign: "center",
    "& p": {
      display: "inline-block",
      fontSize: "30px",
      margin: "0px",
    },
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  documentSectionHeader: {
    marginTop: "24px",
    marginBottom: "24px",
    textAlign: "center",
    "& p": {
      fontSize: "30px",
      "& span": {},
    },
  },
  serviceBoxesContainer: {
    paddingLeft: "50px",
    paddingBottom: "44px",
    marginBottom: "51px",
    paddingTop: "11px",
  },
  servicePlaceholderContainer: {
    paddingTop: "44px",
    paddingBottom: "44px",
    marginBottom: "51px",
  },
  offerLinkButton: {
    position: "absolute",
    width: "100%",
    bottom: "0px",
    left: "0px",
  },
  cardButtonPadding: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  offerPaperCard: {
    textAlign: "center",
    height: "100%",
    "& p": {
      fontSize: "22px",
      margin: "0px",
      paddingTop: "14px",
    },
  },
  offerPaperCardSmall: {
    textAlign: "center",
    paddingTop: "14px",
    paddingBottom: "14px",
    backgroundColor: "#fff",
    "& p": {
      fontSize: "14px",
      margin: "0px",
      paddingTop: "8px",
      fontWeight: 700,
      paddingRight: "16px",
      paddingLeft: "16px",
      minHeight: "50px",
    },
  },
  offerDurationLabel: {
    textAlign: "center",
    margin: "0px",
    fontSize: "20px",
  },
  sectionHeaderWrapper: {
    display: "inline-flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      "& p": {
        display: "block",
      },
    },
    "& svg": {
      height: "44px",
      width: "44px",
    },
  },
  cardTop: {
    backgroundColor: "#fff",
    paddingTop: "30px",
  },
  wpInforAlertContainer: {
    backgroundColor: "#12BEAB",
    marginBottom: "32px",
    "& p": {
      paddingTop: "11px",
      paddingBottom: "11px",
      paddingLeft: "25px",
      paddingRight: "25px",
      margin: "0px",
      color: "#fff",
      fontWeight: "600",
      fontSize: "17px",
      lineHeight: "18px",
    },
  },
  wpOfferDurationContainer: {
    paddingBottom: "0px",
    paddingTop: "40px",
  },
  wpFirstFormCollapsePadding: {
    paddingBottom: "16px",
  },
  policyContainer: {
    textAlign: "right",
    fontSize: "11px",
    margin: "0px",
    paddingTop: "0px",
    "& a": {
      textDecoration: "none",
      color: "#000",
      fontWeight: 700,
    },
  },
  tooltipHeader: {
    display: "flex",
  },
  documentsFooter: {
    backgroundColor: "#F2F2F2",
  },
  expandMoreContainer: {
    textAlign: "center",
    paddingTop: "40px",
  },
  innerLink: {
    color: "#fff",
  },
  emailLabel: {
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 600,
    color: "#1E1D1D",
    margin: "0px",
  },
  formSubeHeader: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 600,
    color: "#1E1D1D",
  },
  labelPlacement: {
    textAlign: "left",
  },
  formSubHeader: {
    fontSize: "11px",
    color: "#465B71",
    fontWeight: 400,
  },
  afterSectionHeader: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
    width: "60%",
    margin: "16px auto",
  },
  root: {
    display: "flex",
    padding: "0px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    paddingLeft: "50px",
    paddingRight: "50px",
    "& img": {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
    "& button": {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
  },
  cover: {
    width: 500,
    [theme.breakpoints.down("md")]: {
      width: 0,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  justRight: {
    textAlign: "right",
  },
  scrollButtonContainer: {
    textAlign: "center",
  },
  scrollButtonContainerMoreSpace: {
    textAlign: "center",
    paddingTop: "20px",
  },
  buttonProgress: {
    color: "#00BDA8",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  inputProgress: {
    color: "#00BDA8",
  },
  loadingButtonWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  formSection: {
    minWidth: "600px",
    maxWidth: "610px",
    margin: 0,
    marginRight: "-30px",
    [theme.breakpoints.down("lg")]: {
      marginRight: "-15px",
    },
    [theme.breakpoints.down("md")]: {
      width: "auto",
      margin: 0,
      maxWidth: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  formButton: {
    position: "fixed",
    bottom: "12px",
    right: "12px",
    width: "65px",
    height: "65px",
    borderRadius: "32.5px",
    zIndex: 999,
  },
  textNoPesel: {
    textAlign: "left",
    fontWeight: theme.typography.fontWeightBold,
    width: "100%",
    marginTop: "10px",
  },
  textNoPeselLink: {
    color: "#00BDA8",
    "&:hover": {
      cursor: "pointer",
    },
  },
  discountInformation: {
    backgroundColor: "#F8F8F8",
    padding: "8px 16px",
    lineHeight: "1.7",
    margin: "auto",
    maxWidth: "900px",
  },
  infoIcon: {
    marginTop: "5px",
  },
  discountText: {
    marginLeft: "35px",
    marginTop: "-30px",
  },
  meterInputReminder: {
    fontSize: "11px",
    textAlign: "center",
    padding: " 10px 4px 0px 4px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "100%",
      textAlign: "left",
      paddingBottom: "6px",
    },
  },
  popper: {
    "& .MuiAutocomplete-paper": {
      padding: 0,
      marginTop: -1.8,
    },
    "& .MuiAutocomplete-listbox": {
      maxHeight: 136,
      border: `1.7px solid #E2E4E3`,
      borderRadius: 4,
    },
    "& .MuiPaper-rounded": {
      borderRadius: 4,
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      border: "none",
    },
  },
  inputRoot: {
    '&[class*="MuiInput-root"]': {
      border: "1.8px solid",
      backgroundColor: "#F9F9F9",
      borderColor: "#D3CFCF",
      borderRadius: 4,
      height: 40,
      padding: theme.spacing(0, 3),
      "&  .MuiAutocomplete-input": {
        padding: theme.spacing(0, 3),
        fontSize: 15,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "inherit",
      marginRight: theme.spacing(3),
    },
    "&.MuiInput-adornedEnd": {
      paddingRight: theme.spacing(3) + "px !important",
    },
    letterSpacing: "0.2px",
    fontWeight: theme.typography.fontWeightBold,
    // color: autocompleteInputColor,
  },
  rootAutocomplete: {
    display: "flex",
    "& .MuiInput-notchedOutline": {
      border: "none",
    },
    "& .MuiFormControl-root": {
      alignSelf: "center",
    },
    "& .MuiSvgIcon-root": {
      // color: secondaryColor,
      fontSize: "1.2rem",
    },
  },
  option: {
    display: "flex",
    minHeight: 40,
    padding: theme.spacing(2, 3),
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    "&[aria-selected=true]": {
      // backgroundColor: autocompleteBackgroundColor,
    },
    "&[aria-selected=false][data-focus=true]": {
      backgroundColor: "white",
    },
    "& .MuiIconButton-root": {
      height: 20,
      width: 20,
      backgroundColor: "transparent",
    },
    "&:last-of-type": {
      marginBottom: theme.spacing(3),
    },
  },
  noOptions: {
    borderRadius: 4,
  },
  tooltipHeaderContainer: {
    display: "flex",
    flexDirection: "row",
  },
  customerFormAlign: {
    alignItems: "flex-start",
  },
  optionalConsent: {
    opacity: 0.35,
  },
  spanHover: {
    opacity: 1,
    cursor: "pointer",
    fontWeight: 700,
    textDecoration: "underline",
    color: theme.palette.primary.main,
    transition: "color 0.2s ease-in-out",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  offersButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  offersButton: {
    maxWidth: "360px",
    margin: "16px auto",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
}))

export interface LightsOnFormProps {
  onSucces: (documentVersion: EDocumentVersion) => void
  onError: (err?: string, header?: string) => void
  onValidationError: (missingData: boolean, messages: string[]) => void
}

export const LightsOnForm: React.FC<LightsOnFormProps> = ({ onSucces, onError, onValidationError }) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const contractTypeContext = useContext(ContractTypeContext)

  const recaptchaRef = useRef<any>()
  const noPeselHelperText = "Nie masz numeru PESEL? "
  const noPeselHelperLink = "Uzupełnij formularz kontaktowy"

  var documentType: EDocumentType = EDocumentType.PESEL
  const documentNumberValidation = (el) => {
    switch (documentType) {
      case EDocumentType.PESEL:
        return !isValidPesel(el)
      case EDocumentType.PASSPORT:
        return !/^(?!^0+$)[a-zA-Z0-9]{6,9}$/i.test(el)
    }
    return el.length < 2
  }

  const getFile = (e, setFieldValue, id?) => {
    e.preventDefault()
    const file = e.target.files[0]
    if (file) {
      if (id === EStatementType.PROXY) {
        const fileSizeMB = file.size / 1000
        if (fileSizeMB < 10000) {
          const fileMessage: string = checkFile(file)
          if (fileMessage) {
            setWrongProxyFileMessage(fileMessage)
            setWrongFileSizeProxy(true)
            setFieldValue("fileProxy", null)
          } else {
            setWrongFileSizeProxy(false)
            setFieldValue("fileProxy", file)
          }
        } else {
          setWrongProxyFileMessage("Maksymalny rozmiar pliku 10MB")
          setWrongFileSizeProxy(true)
          setFieldValue("fileProxy", null)
        }
      } else {
        const fileSizeMB = file.size / 1000
        if (fileSizeMB < 10000) {
          const fileMessage: string = checkFile(file)
          if (fileMessage) {
            setWrongFileMessage(fileMessage)
            setWrongFileSize(true)
            setFieldValue("file", null)
          } else {
            setWrongFileSize(false)
            setFieldValue("file", file)
          }
        } else {
          setWrongFileMessage("Maksymalny rozmiar pliku 10MB")
          setWrongFileSize(true)
          setFieldValue("file", null)
        }
      }
    }
  }

  const [wrongFileSize, setWrongFileSize] = useState(false)
  const [wrongFileSizeProxy, setWrongFileSizeProxy] = useState(false)
  const [wrongFileMessage, setWrongFileMessage] = useState("")
  const [wrongProxyFileMessage, setWrongProxyFileMessage] = useState("")
  const [showDocumentModal, setShowDocumentModal] = useState(false)
  const [documentModalSource, setDocumentModalSource] = useState("pzo")
  const [showSummaryModal, setShowSummaryModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showSendUsEmailModal, setShowSendUsEmailModal] = useState(false)
  const [offerData, setOfferData] = useState(null)
  const [dataLoading, setDataLoading] = useState(false)
  const [isPzoNumber, setIsPzoNumber] = useState(false)
  const [showContactComponent, setShowContactComponent] = useState(false)
  const [showPhoneContactConsent, setShowPhoneContactConsent] = useState(false)
  const [showEmailContactConsent, setShowEmailContactConsent] = useState(false)
  const [powerData, setPowerData] = useState<PowerData | null>(null)
  const [contractType, setContractType] = useState<ContractType>(ContractType.INDIVIDUAL)

  const [enableCityInput, setEnableCityInput] = useState(false)
  const [enableStreetInput, setEnableStreetInput] = useState(false)
  const [enableApartmentsInput, setEnableApartmentsInput] = useState(false)
  const [isLoadingCities, setIsLoadingCities] = useState(false)
  const [isLoadingStreets, setIsLoadingStreets] = useState(false)

  const [enableCorrespondenceCityInput, setEnableCorrespondenceCityInput] = useState(false)
  const [enableCorrespondenceStreetInput, setEnableCorrespondenceStreetInput] = useState(false)
  const [enableCorrespondenceApartmentsInput, setEnableCorrespondenceApartmentsInput] = useState(false)
  const [isLoadingCorrespondenceCities, setIsLoadingCorrespondenceCities] = useState(false)
  const [isLoadingCorrespondenceStreets, setIsLoadingCorrespondenceStreets] = useState(false)

  const [responseAddress, setResponseAddress] = React.useState([])
  const [responseCorrespondenceAddress, setResponseCorrespondenceAddress] = React.useState([])
  const [cities, setCities] = React.useState<string[] | undefined>([])
  const [streets, setStreets] = React.useState<string[] | undefined>([])
  const [citiesCorrespondence, setCitiesCorrespondence] = React.useState<string[] | undefined>([])
  const [streetsCorrespondence, setStreetsCorrespondence] = React.useState<string[] | undefined>([])

  const [showCalendarModal, setShowCalendarModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [now, setNow] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [unableToDownloadData, setUnableToDownloadData] = useState(false)

  const [postCodePPE, setPostCodePPE] = useState("")
  const [postCodeCorespondence, setPostCodeCorespondence] = useState("")

  const inputFormFields: FormTypes[] = getBasicInfoFields()
  const inputFields: FormTypes[] = getOfferDataFields({
    documentNumberValidation: documentNumberValidation,
    isValidPesel: isValidPesel,
  })

  const postalCodeChangedHandler = (newValue, type) => {
    if (type == EAddressType.PPE) {
      if (newValue !== postCodePPE) {
        setPostCodePPE(newValue)
        return true
      } else return false
    }
    if (type == EAddressType.CORRESPONDENCE) {
      if (newValue !== postCodeCorespondence) {
        setPostCodeCorespondence(newValue)
        return true
      } else return false
    }
  }

  const validateField = (value, field: FormTypes) => {
    if (field.required && !value) {
      return field.errorEmpty
    }

    if (value && field.validation && field.validation(value)) {
      return field.errorMsg
    }
  }

  useEffect(() => {
    trackCustomEvent({
      category: "Wysłanie formularza",
      action: `Użytkownik wyświetlił formularz`,
    })
  }, [])

  const submitCore = async (values: AgreementsRequest) => {
    const captcha = await recaptchaRef.current.executeAsync()
    if (!captcha) {
      onError("Nie została wykonana autoryzacja z użyciem reCaptcha")
      return
    }

    try {
      await submitData({
        captcha,
        formValue: { ...values },
      })

      trackCustomEvent({
        category: "Wysłanie formularza",
        action: "Zgłoszenie zostało przyjęte poprawnie",
      })
      recaptchaRef.current.reset()
      onSucces(getDocumentVersion(values.customer.PzoZot))
    } catch (err) {
      trackError(err)
      recaptchaRef.current.reset()

      onError(
        err.status === 400
          ? "Nie udało się pobrać adresu PPE dla wprowadzonych danych. Upewnij się, że podany numer PZO/ZOT i numer licznika są zgodne z posiadanym dokumentem."
          : ""
      )

      setShowSummaryModal(false)
      setShowConfirmationModal(false)
    }
  }

  const consents = [
    {
      name: "customer.phoneAgree",
      placeholder: "Zgoda na kontakt telefoniczny",
      description: (
        <span>
          Wyrażam zgodę na kontakt ze mną przez Polenergia Dystrybucja sp.&nbsp;z&nbsp;o&nbsp;o. za pośrednictwem
          telefonu (zarówno w&nbsp;formie połączeń głosowych, jak i&nbsp;wiadomości SMS oraz MMS), w&nbsp;tym
          z&nbsp;pomocą automatycznych systemów wywołujących, w&nbsp;celu informowania mnie o&nbsp;ofertach dotyczących
          obecnych i&nbsp;przyszłych produktów i&nbsp;usług spółek z&nbsp;Grupy POLENERGIA.
        </span>
      ),
      required: false,
    },
    {
      name: "customer.emailAgree",
      placeholder: "Zgoda na kontakt drogą elektroniczną",
      description: (
        <span>
          Wyrażam zgodę na przesyłanie przez Polenergia Dystrybucja sp.&nbsp;z&nbsp;o.&nbsp;o. informacji handlowej
          związanej z&nbsp;obsługą Klienta, saldem należności oraz ofertami dotyczącymi obecnych i&nbsp;przyszłych
          produktów i&nbsp;usług spółek z&nbsp;Grupy POLENERGIA, za pomocą środków komunikacji elektronicznej
          w&nbsp;postaci poczty elektronicznej lub komunikatorów internetowych.
        </span>
      ),
      required: false,
    },
  ]
  const submit = async (values, errors, setSubmitting, resetForm, onValidationError, now: boolean = true) => {
    trackCustomEvent({
      category: "Wysłanie formularza",
      action: "Wysłanie formularza",
    })
    const anyMissingData: boolean = Object.keys(errors).length > 0
    const additionalMessages: string[] = []

    if (getDocumentVersion(values.customer.PzoZot) !== EDocumentVersion.PZO) {
      delete values.customer.meterQuantity
    }

    const fileMessage = checkFile(values.file)
    if (fileMessage) {
      additionalMessages.push(fileMessage)
      errors["ATT"] = fileMessage
    }

    if (values.offerData.statementType === EStatementType.PROXY) {
      const proxyFileMessage = checkFile(values.fileProxy, EStatementType.PROXY)
      if (proxyFileMessage) {
        additionalMessages.push(proxyFileMessage)
        errors["ATT"] = proxyFileMessage
      }
    }

    if (Object.keys(errors).length) {
      Object.keys(errors).forEach((key) => {
        trackCustomEvent({
          category: "Błąd walidacji formularza",
          action: "Błędnie wypełnione pole",
          label: `${placeholdersDict[key]} - ${errors[key]}`,
        })
      })
      onValidationError(anyMissingData, additionalMessages)
    } else {
      values.offerData.contractedPower = Number(values.offerData.contractedPower)
      setSubmitting(false)
      setOfferData(values)
      setShowSummaryModal(true)
    }
  }

  const getCity = async (postalCode, id) => {
    if (postalCode) {
      if (id === EAddressType.PPE) {
        try {
          setIsLoadingCities(true)
          const response: Array<{
            cityName: string
            streets: string[]
          }> = await getCityRequest(postalCode)
          let responseCities = []
          for (let i = 0; i < response.length; i++) {
            responseCities.push(response[i].cityName)
          }
          setCities(responseCities)
          setResponseAddress(response)
        } catch (e) {
          trackError(e)
          //setUnableToDownloadData(true)
        } finally {
          setIsLoadingCities(false)
        }
      }

      if (id === EAddressType.CORRESPONDENCE) {
        try {
          setIsLoadingCorrespondenceCities(true)
          const response: Array<{
            cityName: string
            streets: string[]
          }> = await getCityRequest(postalCode)
          let responseCities = []
          for (let i = 0; i < response.length; i++) {
            responseCities.push(response[i].cityName)
          }
          setCitiesCorrespondence(responseCities)
          setResponseCorrespondenceAddress(response)
        } catch (e) {
          trackError(e)
          //setUnableToDownloadData(true)
        } finally {
          setIsLoadingCorrespondenceCities(false)
        }
      }
    }
  }

  const getStreet = async (city, id) => {
    if (city) {
      if (id === EAddressType.PPE) {
        try {
          setIsLoadingStreets(true)
          const streets = responseAddress.find((resp) => resp.cityName === city).streets
          setStreets(streets)
        } catch (e) {
          trackError(e)
          //setUnableToDownloadData(true)
        } finally {
          setIsLoadingStreets(false)
        }
      }

      if (id === EAddressType.CORRESPONDENCE) {
        try {
          setIsLoadingCorrespondenceStreets(true)
          const streets = responseCorrespondenceAddress.find((resp) => resp.cityName === city).streets
          setStreetsCorrespondence(streets)
        } catch (e) {
          trackError(e)
          //setUnableToDownloadData(true)
        } finally {
          setIsLoadingCorrespondenceStreets(false)
        }
      }
    }
  }

  const onGetDataClick = async (setData, pzoZot, meterNumber) => {
    if (pzoZot && meterNumber) {
      try {
        setDataLoading(true)

        const response: PPEaddressResponse = await getPPE(pzoZot, meterNumber)

        const city: string = `${response.postalCode || ""} ${response.city || ""}`.trim()
        const street: string = `${response.street || ""} ${response.buildingNumber || ""}${
          (response.apartmentNumber ? "/" + response.apartmentNumber : "") || ""
        }`.trim()

        // setData("customer.PPE.city", city)
        // setData("customer.PPE.street", street)
        setData("customer.PPE.post", response.postalCode)
        setData("customer.PPE.city", response.city)
        setData("customer.PPE.street", response.street)
        setData("customer.PPE.nr1", response.buildingNumber)
        setData("customer.PPE.nr2", response.apartmentNumber)

        if (
          contractType === ContractType.COMMERCIAL_WITH_PREMISES &&
          pzoZot.length > 0 &&
          meterNumber.length === 8 &&
          /^(OT|PZO)\/\w+\/\w+/i.test(pzoZot)
        ) {
          setDataLoading(true)

          const res: PowerData = await getCustomerPowerRange(pzoZot, meterNumber)

          setPowerData(res)
        }
      } catch (e) {
        if (e.status === 400) {
          onError(
            "Nie znaleziono użytkownika na podstawie wprowadzonych danych, lub formularz został już raz uzupełniony. Sprawdź dane i spróbuj jeszcze raz, bądź skontaktuj się z infolinią.",
            "Uwaga"
          )
        } else {
          trackError(e)
          setUnableToDownloadData(true)
        }
      } finally {
        setDataLoading(false)
      }
    } else {
      onError(
        "Dane możemy pobrać tylko na podstawie numeru PZO/ZOT i numeru licznika - proszę o wprowadznie odpowiednich wartości.",
        "Uwaga"
      )
    }
  }

  const onClick = () => {}

  const trackError = (e) => {
    if (!e) {
      return
    }

    switch (e.status) {
      case 400:
        if (e.data) {
          if (typeof e.data === "string" || e.data instanceof String) {
            trackCustomEvent({
              category: "Błąd serwera",
              action: "Niepoprawna walidacja danych",
              label: e.data,
            })
          } else {
            Object.keys(e.data).forEach((key) => {
              trackCustomEvent({
                category: "Błąd serwera",
                action: "Niepoprawna walidacja danych",
                label: `${key} - ${e.data[key].join(", ")}`,
              })
            })
          }
        } else {
          trackCustomEvent({
            category: "Błąd serwera",
            action: `Kod błędu serwera - ${e.status}`,
          })
        }

        return
      default:
        trackCustomEvent({
          category: "Błąd serwera",
          action: `Kod błędu serwera - ${e.status}`,
        })
    }
  }

  const getCalendarDataPromise = () => {
    setShowCalendarModal(true)
    return new Promise<DateRange>((resolve, reject) => {
      dateResolver = resolve
      dateReject = reject
    })
  }

  const submitDate = (value: DateRange) => {
    dateResolver(value)
  }

  const tooltipTheme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
          color: "white",
          backgroundColor: "#00BDA8",
        },
      },
    },
  })

  return (
    <div className={classes.formContainer} id={FormID}>
      <Portal>
        <ReCAPTCHA
          className={classes.recaptcha}
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.GATSBY_RECAPTCHA_KEY}
        />
      </Portal>

      <SummaryModal
        show={showSummaryModal}
        toggleModal={() => {
          setShowSummaryModal(false)
        }}
        togglePrompt={() => {
          setShowConfirmationModal(true)
          setShowSummaryModal(false)
        }}
        data={offerData}
        confirmForm={async () => {
          await submitCore(offerData)
        }}
      ></SummaryModal>

      <ConfirmationModal
        show={showConfirmationModal}
        toggleModal={() => {
          setShowConfirmationModal(false)
        }}
        confirmForm={async () => {
          await submitCore(offerData)
        }}
      ></ConfirmationModal>

      <DocumentModal
        show={showDocumentModal}
        toggleModal={() => {
          setShowDocumentModal(false)
        }}
        source={documentModalSource}
      ></DocumentModal>

      <Formik initialValues={newCustomerOfferData[0]} onSubmit={() => {}}>
        {({
          isSubmitting,
          values,
          isValid,
          setFieldValue,
          validateForm,
          submitForm,
          setSubmitting,
          resetForm,
          setTouched,
        }) => (
          <>
            <Form className={classes.formComponent}>
              <div className={classes.sectionHeader} id="section-1">
                <div className={classes.sectionHeaderWrapper}>
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.1" cx="24" cy="24" r="24" fill="#12BEAB" />
                    <circle opacity="0.5" cx="24" cy="24" r="16" fill="#12BEAB" />
                    <circle cx="24" cy="24" r="10" fill="#12BEAB" />
                    <path
                      d="M27.5067 25.914V28H20.5907V25.914H22.7327V20.888L20.7307 22.036V19.782L23.8107 18.032H25.3507V25.914H27.5067Z"
                      fill="white"
                    />
                  </svg>
                  <p>Uzupełnij dane w formularzu</p>
                </div>
              </div>

              <div className={classes.cardsContainer}>
                <Card>
                  <Field name="customer.customerType">
                    {({ field }) => (
                      <ToggleButtons
                        contractType={contractType}
                        handleChangeContractType={(contractType: ContractType) => {
                          resetForm()
                          setContractType(contractType)
                          contractTypeContext?.updateContractType(contractType)
                          if (contractType === ContractType.COMMERCIAL_JDG) {
                            setFieldValue("customer.customerType", ContractType.COMMERCIAL_JDG)
                            setFieldValue("offerData.customer.taxNumber", "")
                            setFieldValue("offerData.jdgEnergyDestinationType", EJdgEnergyDestinationType.NONE)
                          } else if (contractType === ContractType.COMMERCIAL_WITH_PREMISES) {
                            setFieldValue("customer.customerType", ContractType.COMMERCIAL_WITH_PREMISES)
                          } else {
                            setFieldValue("customer.customerType", ContractType.INDIVIDUAL)
                            setFieldValue("offerData.document.documentNumber", "")
                            setFieldValue("offerData.energyDestinationType", EEnergyDestinationType.FLAT)
                          }
                        }}
                      />
                    )}
                  </Field>

                  <div className={classes.wpInforAlertContainer}>
                    <p>
                      WAŻNE - Przygotuj protokół zdawczo-odbiorczy (PZO)
                      <span>
                        <IconButton
                          onClick={() => {
                            setDocumentModalSource("pzo")
                            setShowDocumentModal(true)
                          }}
                        >
                          <Info htmlColor="#fff" />
                        </IconButton>
                      </span>
                      lub zlecenie obsługi technicznej (ZOT)
                      <span>
                        <IconButton
                          onClick={() => {
                            setDocumentModalSource("zot")
                            setShowDocumentModal(true)
                          }}
                        >
                          <Info htmlColor="#fff" />
                        </IconButton>
                      </span>
                    </p>
                  </div>

                  <div>
                    <Collapse in={values.customer.customerType !== ContractType.INDIVIDUAL}>
                      <Grid container alignItems="center" spacing={2} className={classes.wpFirstFormCollapsePadding}>
                        <Grid item md={3} xs={12}>
                          <Field
                            name={inputFormFields[0].name}
                            validate={(value) => {
                              if (values.customer.customerType !== ContractType.INDIVIDUAL) {
                                return validateField(value, inputFormFields[0])
                              }
                            }}
                          >
                            {({ field, meta: { error, touched } }) => {
                              const showError = Boolean(error && touched)
                              return (
                                <CustomInput
                                  field={field}
                                  metaError={error}
                                  showError={showError}
                                  placeholder={String(inputFormFields[0].placeholder)}
                                  onClick={onClick}
                                />
                              )
                            }}
                          </Field>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <Field
                            name={inputFormFields[1].name}
                            validate={(value) => {
                              if (values.customer.customerType !== ContractType.INDIVIDUAL) {
                                return validateField(value, inputFormFields[1])
                              }
                            }}
                          >
                            {({ field, meta: { error, touched } }) => {
                              const showError = Boolean(error && touched)
                              return (
                                <CustomInput
                                  field={field}
                                  metaError={error}
                                  showError={showError}
                                  placeholder={String(inputFormFields[1].placeholder)}
                                  onClick={onClick}
                                />
                              )
                            }}
                          </Field>
                        </Grid>
                        {contractType === ContractType.COMMERCIAL_WITH_PREMISES && (
                          <>
                            <Grid item md={3} xs={12}>
                              <Field
                                name={inputFormFields[11].name}
                                validate={(value) => {
                                  if (values.customer.customerType === ContractType.COMMERCIAL_WITH_PREMISES) {
                                    return validateField(value, inputFormFields[11])
                                  }
                                }}
                              >
                                {({ field, meta: { error, touched } }) => {
                                  const showError = Boolean(error && touched)
                                  return (
                                    <CustomInput
                                      field={field}
                                      metaError={error}
                                      showError={showError}
                                      placeholder={String(inputFormFields[11].placeholder)}
                                      onClick={onClick}
                                    />
                                  )
                                }}
                              </Field>
                            </Grid>
                            <Grid item md={3} xs={12}>
                              <Field
                                name={inputFormFields[12].name}
                                validate={(value) => {
                                  if (values.customer.customerType === ContractType.COMMERCIAL_WITH_PREMISES) {
                                    return validateField(value, inputFormFields[12])
                                  }
                                }}
                              >
                                {({ field, meta: { error, touched } }) => {
                                  const showError = Boolean(error && touched)
                                  return (
                                    <CustomInput
                                      field={field}
                                      metaError={error}
                                      showError={showError}
                                      placeholder={String(inputFormFields[12].placeholder)}
                                      onClick={onClick}
                                    />
                                  )
                                }}
                              </Field>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Collapse>
                  </div>

                  <Grid container alignItems="center" spacing={2} className={classes.customerFormAlign}>
                    {(contractType === ContractType.COMMERCIAL_WITH_PREMISES
                      ? inputFormFields.slice(6, 9)
                      : inputFormFields.slice(2, 9)
                    ).map((fieldConfig, i) => {
                      const validateMeterNumber =
                        contractType === ContractType.COMMERCIAL_WITH_PREMISES ? i == 2 : i == 6
                      return (
                        <Grid item md={3} xs={12}>
                          <Field
                            name={fieldConfig.name}
                            validate={(value) => {
                              if (contractType === ContractType.COMMERCIAL_WITH_PREMISES ? i == 0 : i == 4) {
                                setIsPzoNumber(getDocumentVersion(value) === EDocumentVersion.PZO)
                              }
                              if (i === 3 && contractType !== ContractType.COMMERCIAL_WITH_PREMISES) {
                                return (
                                  !isValidPhoneNumber(`${values.customer.phonePrefix}${value}`) &&
                                  "Wpisz poprawny numer telefonu"
                                )
                              }
                              if (validateMeterNumber && isPzoNumber) {
                                return validateField(value, fieldConfig)
                              }
                              if (contractType === ContractType.COMMERCIAL_WITH_PREMISES ? i !== 2 : i !== 6) {
                                return validateField(value, fieldConfig)
                              }
                            }}
                          >
                            {({ field, meta: { error, touched } }) => {
                              const showError = Boolean(error && touched)
                              return (
                                <div>
                                  {i === 3 && contractType !== ContractType.COMMERCIAL_WITH_PREMISES ? (
                                    <PhoneTextField
                                      phoneFieldName="customer.phone"
                                      prefixFieldName="customer.phonePrefix"
                                      onClick={onClick}
                                    />
                                  ) : (
                                    <CustomInput
                                      field={field}
                                      metaError={error}
                                      showError={showError}
                                      placeholder={String(fieldConfig.placeholder)}
                                      onClick={onClick}
                                      disabled={validateMeterNumber ? !isPzoNumber : false}
                                    />
                                  )}
                                  {isPzoNumber && validateMeterNumber && (
                                    <p className={classes.meterInputReminder}>
                                      Wprowadź stan licznika na dzień przejęcia lokalu podany na Protokole
                                      Zdawczo-Odbiorczym
                                    </p>
                                  )}
                                </div>
                              )
                            }}
                          </Field>
                        </Grid>
                      )
                    })}
                  </Grid>

                  <p className={classes.policyContainer}>
                    Zapoznaj się z naszą
                    <a target="_blank" href="/Polityka_prywatnosci_Polenergia_Dystrybucja.pdf">
                      {" "}
                      polityką prywatności
                    </a>
                  </p>

                  <Divider className={classes.dividerPadding} id="get-meters-data" />

                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      <h3>Dokumenty</h3>
                    </Grid>
                  </Grid>
                  <p className={classes.formSubHeader}>
                    Załącz skan lub zdjęcie posiadanego Protokołu Zdawczo – Odbiorczego lub Zlecenia Obsługi
                    Technicznej.
                  </p>
                  <div className={classes.sectionContainer}>
                    <div className={classes.fileAdding}>
                      <div>
                        <input
                          accept="image/*,.pdf"
                          id="button-file"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(event) => getFile(event, setFieldValue)}
                        />
                        <label htmlFor="button-file">
                          <Button
                            startIcon={<AttachFile />}
                            variant="outlined"
                            color="primary"
                            className={classes.fileButton}
                            component="span"
                          >
                            {values["file"] ? "Zmień plik" : "Załącz plik"}
                          </Button>
                        </label>
                      </div>
                      <div>
                        {values["file"] ? (
                          <p>
                            Plik{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {values["file"].name}{" "}
                            </span>
                            został załączony
                          </p>
                        ) : (
                          <p>Skan lub zdjęcie PZO lub Zlecenia OT.</p>
                        )}
                        <Transition
                          items={wrongFileSize}
                          from={{
                            opacity: 0,
                            height: "0px",
                            marginTop: "0px",
                          }}
                          enter={{
                            opacity: 1,
                            height: "15px",
                            marginTop: "2px",
                          }}
                          leave={{
                            opacity: 0,
                            height: "0px",
                            marginTop: "0px",
                          }}
                        >
                          {(values, visible) => {
                            return (
                              visible && (
                                <animated.p style={values} className={classes.fileSizeError}>
                                  {wrongFileMessage}
                                </animated.p>
                              )
                            )
                          }}
                        </Transition>
                      </div>
                    </div>
                  </div>

                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} className={classes.tooltipHeader}>
                      <h3>Adres punktu poboru energii (PPE)</h3>
                    </Grid>
                  </Grid>
                  <p className={classes.formSubHeader}>
                    Na podstawie numeru PZO/ZOT i numeru licznika możliwe jest automatyczne pobranie danych z bazy.
                  </p>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={3}>
                      <div className={classes.loadingButtonWrapper}>
                        <Button
                          disabled={dataLoading}
                          startIcon={<Input />}
                          variant="outlined"
                          color="primary"
                          className={classes.fillPpeButton}
                          component="span"
                          onClick={() => {
                            onGetDataClick(setFieldValue, values.customer.PzoZot, values.customer.meterNumber)
                          }}
                        >
                          Pobierz dane
                        </Button>
                        {dataLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field name={inputFields[13].name} validate={(value) => validateField(value, inputFields[13])}>
                        {({ field, meta: { error, touched } }) => {
                          const showError = Boolean(error && touched)
                          return (
                            <CustomInput
                              disabled={true}
                              field={field}
                              metaError={error}
                              showError={showError}
                              placeholder={String(inputFields[13].placeholder)}
                              onClick={onClick}
                            />
                          )
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field name={inputFields[10].name} validate={(value) => validateField(value, inputFields[10])}>
                        {({ field, meta: { error, touched } }) => {
                          const showError = Boolean(error && touched)
                          return (
                            <CustomInput
                              disabled={true}
                              field={field}
                              metaError={error}
                              showError={showError}
                              placeholder={String(inputFields[10].placeholder)}
                              onClick={onClick}
                            />
                          )
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Field name={inputFields[14].name} validate={(value) => validateField(value, inputFields[14])}>
                        {({ field, meta: { error, touched } }) => {
                          const showError = Boolean(error && touched)
                          return (
                            <CustomInput
                              disabled={true}
                              field={field}
                              metaError={error}
                              showError={showError}
                              placeholder={String(inputFields[14].placeholder)}
                              onClick={onClick}
                            />
                          )
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Field name={inputFields[11].name} validate={(value) => validateField(value, inputFields[11])}>
                        {({ field, meta: { error, touched } }) => {
                          const showError = Boolean(error && touched)
                          return (
                            <CustomInput
                              disabled={true}
                              field={field}
                              metaError={error}
                              showError={showError}
                              placeholder={inputFields[11].placeholder}
                              onClick={onClick}
                            />
                          )
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Field name={inputFields[12].name} validate={(value) => validateField(value, inputFields[12])}>
                        {({ field, meta: { error, touched } }) => {
                          const showError = Boolean(error && touched)
                          return (
                            <CustomInput
                              disabled={true}
                              field={field}
                              metaError={error}
                              showError={showError}
                              placeholder={inputFields[12].placeholder}
                              onClick={onClick}
                            />
                          )
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                </Card>
              </div>
              <div className={classes.scrollButtonContainer}>
                <IconButton
                  onClick={() => {
                    scrollTo(`#section-2`)
                  }}
                >
                  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M31.05 34.3996L26 38.6496L21.1 34.3996C20.7 34.0496 20.65 33.3996 21 32.9496C21.2 32.7496 21.45 32.6496 21.7 32.5996C21.95 32.5996 22.25 32.6496 22.45 32.8496L26.05 35.9496L29.75 32.8496C30.2 32.4996 30.8 32.5496 31.2 32.9496C31.5 33.3996 31.45 34.0496 31.05 34.3996ZM32.9 28.6996L26 34.6496L19.1 28.6996C18.7 28.3496 18.65 27.6996 19 27.2496C19.35 26.8496 20 26.7996 20.45 27.1496L26 31.9996L31.55 27.1996C31.75 27.0496 32 26.9496 32.3 26.9496C32.55 26.9496 32.8 27.0996 33 27.2996C33.15 27.4996 33.25 27.7496 33.25 28.0496C33.2 28.2996 33.1 28.5496 32.9 28.6996ZM33.5 21.6496C33.5 24.3496 31.3 26.5496 28.6 26.5496C27.85 26.5496 27.25 25.9496 27.25 25.1496C27.25 24.3996 27.85 23.7996 28.6 23.7996C29.8 23.7996 30.75 22.8496 30.75 21.6496V10.2496C30.75 9.04961 29.8 8.09961 28.6 8.09961H23.4C22.2 8.09961 21.25 9.04961 21.25 10.2496V21.6496C21.25 22.8496 22.2 23.7996 23.4 23.7996C24.15 23.7996 24.75 24.3996 24.75 25.1496C24.75 25.8996 24.15 26.5496 23.4 26.5496C20.7 26.5496 18.5 24.3496 18.5 21.6496V10.2496C18.5 7.54961 20.7 5.34961 23.4 5.34961H28.55C31.25 5.34961 33.45 7.54961 33.45 10.2496V21.6496H33.5Z"
                      fill="#11A090"
                    />
                  </svg>
                </IconButton>
              </div>

              <div>
                <div id="section-2" className={classes.sectionHeader}>
                  <div className={classes.sectionHeaderWrapper}>
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.1" cx="28" cy="28" r="28" fill="#12BEAB" />
                      <circle opacity="0.5" cx="28" cy="28" r="21" fill="#12BEAB" />
                      <circle cx="28" cy="28" r="13" fill="#12BEAB" />
                      <path
                        d="M31.1219 29.914V32H24.0939V30.068L27.3419 26.68C27.9486 26.0453 28.2519 25.4527 28.2519 24.902C28.2519 24.258 27.8692 23.936 27.1039 23.936C26.7119 23.936 26.2966 24.0153 25.8579 24.174C25.4286 24.3233 24.9946 24.5427 24.5559 24.832L23.7299 22.97C24.1312 22.6433 24.6492 22.3867 25.2839 22.2C25.9279 22.004 26.5812 21.906 27.2439 21.906C27.9626 21.906 28.5879 22.0227 29.1199 22.256C29.6612 22.48 30.0766 22.8067 30.3659 23.236C30.6646 23.656 30.8139 24.1507 30.8139 24.72C30.8139 25.2987 30.6972 25.826 30.4639 26.302C30.2399 26.7687 29.8526 27.296 29.3019 27.884L27.3139 29.914H31.1219Z"
                        fill="white"
                      />
                    </svg>

                    {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                      <p>Zapoznaj się z Taryfą Sprzedawcy i Cennikiem Sprzedawcy</p>
                    ) : (
                      <p>Wybierz ofertę</p>
                    )}
                  </div>
                </div>

                <p className={classes.afterSectionHeader}>
                  {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                    <>
                      Taryfa Polenergii Dystrybucja określa stawki dystrybucyjne dla wszystkich grup taryfowych oraz
                      stawki za energię elektryczną dla grupy taryfowej G, natomiast Cennik Sprzedawcy określa opłaty za
                      energię elektryczną dla grup taryfowych innych niż G.
                    </>
                  ) : (
                    <>
                      Jeżeli jesteś zainteresowany grupą taryfową inną niż G11 skontaktuj się z naszą infolinią&nbsp;
                      <span
                        className={classes.textNoPeselLink}
                        onClick={() => setShowContactComponent((value) => !value)}
                      >
                        {noPeselHelperLink}
                      </span>
                    </>
                  )}
                </p>

                <div className={classes.cardsContainer}>
                  {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                    <Grid container justify="center" spacing={2}>
                      {values.offer.offersCommercial.map((offer, index) => (
                        <OfferCard
                          key={index}
                          className={
                            values.offer.offerType === EOfferType.STANDARD
                              ? classes.offerCard
                              : classes.offerCardDisabled
                          }
                          value={EOfferType.STANDARD}
                          offer={offer}
                          sm={4}
                          contractType={contractType}
                        ></OfferCard>
                      ))}
                    </Grid>
                  ) : (
                    <Field name={"offer.offerType"}>
                      {({ field, form, meta }) => {
                        return (
                          <RadioButtonsGroup
                            documentType={field.value}
                            form={form}
                            field={field}
                            className={classes.maxRadioGroupWidth}
                            horizontal={true}
                            setValue={(value) => {
                              setFieldValue("offer.offerType", value)
                              if (value === EOfferType.STANDARD) {
                                setFieldValue("service.serviceType", EServiceType.NONE)
                                setFieldValue("service.serviceOptionType", EServiceOptionType.NONE)
                                setFieldValue("offer.offerDuration", "")
                              }
                            }}
                          >
                            <Grid container justify="center" spacing={2}>
                              <OfferCard
                                className={
                                  values.offer.offerType === EOfferType.STANDARD
                                    ? classes.offerCard
                                    : classes.offerCardDisabled
                                }
                                value={EOfferType.STANDARD}
                                offer={values.offer.offers[0]}
                                sm={4}
                                contractType={contractType}
                              ></OfferCard>
                            </Grid>
                          </RadioButtonsGroup>
                        )
                      }}
                    </Field>
                  )}
                </div>

                <div className={classes.offersButtonContainer}>
                  <CardStyledButton
                    className={classes.offersButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      scrollTo(`#${EOfferDocumentsAnhor.STANDARD}`)
                    }}
                  >
                    Zobacz Dokumenty
                  </CardStyledButton>
                </div>
              </div>

              <div className={classes.scrollButtonContainer}>
                <IconButton
                  onClick={() => {
                    scrollTo(`#section-3`)
                  }}
                >
                  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M31.05 34.3996L26 38.6496L21.1 34.3996C20.7 34.0496 20.65 33.3996 21 32.9496C21.2 32.7496 21.45 32.6496 21.7 32.5996C21.95 32.5996 22.25 32.6496 22.45 32.8496L26.05 35.9496L29.75 32.8496C30.2 32.4996 30.8 32.5496 31.2 32.9496C31.5 33.3996 31.45 34.0496 31.05 34.3996ZM32.9 28.6996L26 34.6496L19.1 28.6996C18.7 28.3496 18.65 27.6996 19 27.2496C19.35 26.8496 20 26.7996 20.45 27.1496L26 31.9996L31.55 27.1996C31.75 27.0496 32 26.9496 32.3 26.9496C32.55 26.9496 32.8 27.0996 33 27.2996C33.15 27.4996 33.25 27.7496 33.25 28.0496C33.2 28.2996 33.1 28.5496 32.9 28.6996ZM33.5 21.6496C33.5 24.3496 31.3 26.5496 28.6 26.5496C27.85 26.5496 27.25 25.9496 27.25 25.1496C27.25 24.3996 27.85 23.7996 28.6 23.7996C29.8 23.7996 30.75 22.8496 30.75 21.6496V10.2496C30.75 9.04961 29.8 8.09961 28.6 8.09961H23.4C22.2 8.09961 21.25 9.04961 21.25 10.2496V21.6496C21.25 22.8496 22.2 23.7996 23.4 23.7996C24.15 23.7996 24.75 24.3996 24.75 25.1496C24.75 25.8996 24.15 26.5496 23.4 26.5496C20.7 26.5496 18.5 24.3496 18.5 21.6496V10.2496C18.5 7.54961 20.7 5.34961 23.4 5.34961H28.55C31.25 5.34961 33.45 7.54961 33.45 10.2496V21.6496H33.5Z"
                      fill="#11A090"
                    />
                  </svg>
                </IconButton>
              </div>

              <div id="section-3" className={classes.sectionHeader}>
                <div className={classes.sectionHeaderWrapper}>
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.1" cx="28" cy="28" r="28" fill="#12BEAB" />
                    <circle opacity="0.5" cx="28" cy="28" r="21" fill="#12BEAB" />
                    <circle cx="28" cy="28" r="13" fill="#12BEAB" />
                    <path
                      d="M29.2807 26.862C29.8594 27.03 30.3027 27.324 30.6107 27.744C30.9281 28.164 31.0867 28.6773 31.0867 29.284C31.0867 29.8627 30.9281 30.3667 30.6107 30.796C30.3027 31.216 29.8547 31.5427 29.2667 31.776C28.6787 32 27.9927 32.112 27.2087 32.112C26.5087 32.112 25.8274 32.0187 25.1647 31.832C24.5114 31.636 23.9887 31.3747 23.5967 31.048L24.4227 29.186C25.2907 29.7833 26.1774 30.082 27.0827 30.082C27.6427 30.082 28.0394 30.0027 28.2727 29.844C28.5061 29.676 28.6227 29.396 28.6227 29.004C28.6227 28.6307 28.5061 28.3647 28.2727 28.206C28.0487 28.0473 27.6707 27.968 27.1387 27.968H25.5847V25.952H26.8727C27.3954 25.952 27.7687 25.882 27.9927 25.742C28.2261 25.5927 28.3427 25.3547 28.3427 25.028C28.3427 24.6733 28.2307 24.4027 28.0067 24.216C27.7921 24.0293 27.4887 23.936 27.0967 23.936C26.6954 23.936 26.2707 24.0153 25.8227 24.174C25.3841 24.3233 24.9641 24.5427 24.5627 24.832L23.7367 22.97C24.1381 22.6527 24.6607 22.396 25.3047 22.2C25.9581 22.004 26.6161 21.906 27.2787 21.906C27.9881 21.906 28.6087 22.018 29.1407 22.242C29.6727 22.466 30.0834 22.788 30.3727 23.208C30.6714 23.6187 30.8207 24.0993 30.8207 24.65C30.8301 25.1727 30.6994 25.6253 30.4287 26.008C30.1581 26.3907 29.7754 26.6753 29.2807 26.862Z"
                      fill="white"
                    />
                  </svg>

                  <p>Uzupełnij dane niezbędne do zawarcia Umowy z Polenergią Dystrybucja</p>
                </div>
              </div>

              <div className={classes.cardsContainer}>
                <Card>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                        <h3>1. Dane osoby uprawnionej do reprezentacji</h3>
                      ) : (
                        <h3>Twoje dane</h3>
                      )}
                    </Grid>
                    {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                      <>
                        {inputFormFields.slice(2, 6).map((fieldConfig, i) => {
                          return (
                            <Grid item md={3} xs={12}>
                              <Field
                                name={fieldConfig.name}
                                validate={(value) => {
                                  if (i === 3) {
                                    return (
                                      !isValidPhoneNumber(`${values.customer.phonePrefix}${value}`) &&
                                      "Wpisz poprawny numer telefonu"
                                    )
                                  }
                                  if (i !== 6) {
                                    return validateField(value, fieldConfig)
                                  }
                                }}
                              >
                                {({ field, meta: { error, touched } }) => {
                                  const showError = Boolean(error && touched)
                                  return (
                                    <div>
                                      {i === 3 ? (
                                        <PhoneTextField
                                          phoneFieldName="customer.phone"
                                          prefixFieldName="customer.phonePrefix"
                                          onClick={onClick}
                                        />
                                      ) : (
                                        <CustomInput
                                          field={field}
                                          metaError={error}
                                          showError={showError}
                                          placeholder={String(fieldConfig.placeholder)}
                                          onClick={onClick}
                                          disabled={i == 6 ? !isPzoNumber : false}
                                        />
                                      )}
                                      {isPzoNumber && i == 6 && (
                                        <p className={classes.meterInputReminder}>
                                          Wprowadź stan licznika na dzień przejęcia lokalu podany na Protokole
                                          Zdawczo-Odbiorczym
                                        </p>
                                      )}
                                    </div>
                                  )
                                }}
                              </Field>
                            </Grid>
                          )
                        })}

                        <div className={classes.sectionContainer}>
                          <div className={classes.fileAdding}>
                            <div>
                              <input
                                accept="image/*,.pdf"
                                id="button-fileProxy"
                                type="file"
                                style={{ display: "none" }}
                                onChange={(event) => getFile(event, setFieldValue, EStatementType.PROXY)}
                              />
                              <label htmlFor="button-fileProxy">
                                <Button
                                  startIcon={<AttachFile />}
                                  variant="outlined"
                                  color="primary"
                                  className={classes.fileButton}
                                  component="span"
                                  style={{ marginLeft: "11px" }}
                                >
                                  {values["fileProxy"] ? "Zmień pełnomocnictwo" : "Załącz pełnomocnictwo"}
                                </Button>
                              </label>
                            </div>
                            <div>
                              {values["fileProxy"] ? (
                                <p>
                                  Plik{" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {values["fileProxy"].name}{" "}
                                  </span>
                                  został załączony
                                </p>
                              ) : (
                                <p>W przypadku pełnomocnictwa należy dołączyć skan lub zdjęcie pełnomocnictwa.</p>
                              )}
                              <Transition
                                items={wrongFileSizeProxy}
                                from={{
                                  opacity: 0,
                                  height: "0px",
                                  marginTop: "0px",
                                }}
                                enter={{
                                  opacity: 1,
                                  height: "15px",
                                  marginTop: "2px",
                                }}
                                leave={{
                                  opacity: 0,
                                  height: "0px",
                                  marginTop: "0px",
                                }}
                              >
                                {(values, visible) => {
                                  return (
                                    visible && (
                                      <animated.p style={values} className={classes.fileSizeError}>
                                        {wrongProxyFileMessage}
                                      </animated.p>
                                    )
                                  )
                                }}
                              </Transition>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} md={3}>
                          <Field
                            name={inputFields[0].name}
                            validate={(value) => {
                              if (values.customer.customerType === ContractType.INDIVIDUAL) {
                                return validateField(value, inputFields[0])
                              }
                            }}
                          >
                            {({ field, meta: { error, touched } }) => {
                              const showError = Boolean(error && touched)
                              return (
                                <CustomInput
                                  disabled={values.customer.customerType === ContractType.COMMERCIAL_JDG}
                                  field={field}
                                  metaError={error}
                                  showError={showError}
                                  placeholder={String(inputFields[0].placeholder)}
                                  onClick={onClick}
                                />
                              )
                            }}
                          </Field>
                        </Grid>
                        <div className={classes.textNoPesel}>
                          {noPeselHelperText}
                          <span
                            className={classes.textNoPeselLink}
                            onClick={() => setShowContactComponent((value) => !value)}
                          >
                            {noPeselHelperLink}
                          </span>
                        </div>
                      </>
                    )}

                    <Divider className={classes.dividerPadding} style={{ width: "100%" }} />

                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        <div className={classes.tooltipHeaderContainer}>
                          {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                            <h3>2. Adres siedziby Przedsiębiorstwa</h3>
                          ) : (
                            <>
                              <h3>Adres zamieszkania</h3>
                              <MuiThemeProvider theme={tooltipTheme}>
                                <Tooltip
                                  title="Jeśli jesteś już naszym Klientem i w naszych systemach istnieje inny adres zamieszkania, zostanie on zaktualizowany i zastąpiony adresem, który wprowadzisz poniżej."
                                  placement="right"
                                >
                                  <IconButton>
                                    <Info htmlColor="#00BDA8" />
                                  </IconButton>
                                </Tooltip>
                              </MuiThemeProvider>
                            </>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name={"offerData.addressType"}
                          validate={(value) => validateField(value, inputFields[1])}
                        >
                          {({ field, meta: { error, touched } }) => {
                            return (
                              <AddressCheckboxCopy
                                addressType={field.value}
                                field={field}
                                setValue={(value) => {
                                  setFieldValue("offerData.addressType", value)
                                  setFieldValue(inputFields[1].name, values.customer.PPE.street)
                                  setFieldValue(inputFields[2].name, values.customer.PPE.city)
                                  setFieldValue(inputFields[16].name, values.customer.PPE.post)
                                  setFieldValue(inputFields[17].name, values.customer.PPE.nr1)
                                  setFieldValue(inputFields[18].name, values.customer.PPE.nr2)
                                  setEnableStreetInput(true)
                                }}
                              ></AddressCheckboxCopy>
                            )
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Field
                          // name={
                          //   values.offerData.addressType === EAddressType.PPE
                          //   ? inputFields[13].name
                          //   : inputFields[16].name
                          // }
                          name={inputFields[16].name}
                          validate={(value) => {
                            if (isPostalCodeValid(value)) {
                              setEnableCityInput(true)
                              if (postalCodeChangedHandler(value, EAddressType.PPE)) {
                                getCity(value, EAddressType.PPE)
                              }
                            } else {
                              // setEnableCityInput(false)
                              //zablokowanie pola city
                            }
                            return validateField(value, inputFields[16])
                          }}
                        >
                          {({ field, meta: { error, touched } }) => {
                            const showError = Boolean(error && touched)
                            return (
                              <CustomInput
                                field={field}
                                metaError={error}
                                showError={showError}
                                placeholder={inputFields[16].placeholder}
                                disabled={values.offerData.addressType === EAddressType.PPE}
                                onClick={onClick}
                              />
                            )
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <CustomAutocompleteField
                          id="city1"
                          options={cities ?? []}
                          onInputChange={(event, value, reason) => {
                            setFieldValue(inputFields[2].name, value)
                            getStreet(value, EAddressType.PPE)
                            setFieldValue(inputFields[1].name, "")
                            setFieldValue(inputFields[17].name, "")
                            setFieldValue(inputFields[18].name, "")
                            setEnableStreetInput(true)
                          }}
                          //disabled={!enableCityInput}
                          loading={isLoadingCities}
                          inputValue={
                            values.offerData.addressType === EAddressType.PPE
                              ? values.customer.PPE.city
                              : values.offerData.address.city
                          }
                          renderInput={(params) => (
                            <Field
                              ref={params.InputProps.ref}
                              name={
                                values.offerData.addressType === EAddressType.PPE
                                  ? inputFields[10].name
                                  : inputFields[2].name
                              }
                              validate={(value) => validateField(value, inputFields[2])}
                            >
                              {({ field, meta: { error, touched } }) => {
                                const showError = Boolean(error && touched)
                                return (
                                  <CustomInput
                                    field={field}
                                    isAutocomplete={true}
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {isLoadingCities ? (
                                            <CircularProgress className={classes.inputProgress} size={20} />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                    metaError={error}
                                    showError={showError}
                                    placeholder={inputFields[2].placeholder}
                                    disabled={values.offerData.addressType === EAddressType.PPE || !enableCityInput}
                                    onClick={onClick}
                                  />
                                )
                              }}
                            </Field>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <div className={classes.autocompletePadding} />
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12} md={3}>
                        <CustomAutocompleteField
                          id="street1"
                          options={streets ?? []}
                          onInputChange={(event, value, reason) => {
                            setFieldValue(inputFields[1].name, value)
                            setFieldValue(inputFields[17].name, "")
                            setFieldValue(inputFields[18].name, "")
                            setEnableApartmentsInput(true)
                          }}
                          disabled={!enableStreetInput}
                          loading={isLoadingCities}
                          inputValue={
                            values.offerData.addressType === EAddressType.PPE
                              ? values.customer.PPE.street
                              : values.offerData.address.street
                          }
                          renderInput={(params) => (
                            <Field
                              ref={params.InputProps.ref}
                              name={
                                values.offerData.addressType === EAddressType.PPE
                                  ? inputFields[13].name
                                  : inputFields[1].name
                              }
                              validate={(value) => validateField(value, inputFields[1])}
                              InputProps={{ ...params.InputProps }}
                            >
                              {({ field, meta: { error, touched } }) => {
                                const showError = Boolean(error && touched)
                                return (
                                  <CustomInput
                                    field={field}
                                    {...params}
                                    isAutocomplete={true}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {isLoadingStreets ? (
                                            <CircularProgress className={classes.inputProgress} size={20} />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                    metaError={error}
                                    showError={showError}
                                    placeholder={inputFields[1].placeholder}
                                    disabled={values.offerData.addressType === EAddressType.PPE || !enableStreetInput}
                                    onClick={onClick}
                                  />
                                )
                              }}
                            </Field>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Field
                          name={
                            values.offerData.addressType === EAddressType.PPE
                              ? inputFields[11].name
                              : inputFields[17].name
                          }
                          validate={(value) => validateField(value, inputFields[11])}
                        >
                          {({ field, meta: { error, touched } }) => {
                            const showError = Boolean(error && touched)
                            return (
                              <CustomInput
                                field={field}
                                metaError={error}
                                showError={showError}
                                placeholder={inputFields[11].placeholder}
                                disabled={values.offerData.addressType === EAddressType.PPE || !enableApartmentsInput}
                                onClick={onClick}
                              />
                            )
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Field
                          name={
                            values.offerData.addressType === EAddressType.PPE
                              ? inputFields[12].name
                              : inputFields[18].name
                          }
                          validate={(value) => validateField(value, inputFields[12])}
                        >
                          {({ field, meta: { error, touched } }) => {
                            const showError = Boolean(error && touched)
                            return (
                              <CustomInput
                                field={field}
                                metaError={error}
                                showError={showError}
                                placeholder={inputFields[12].placeholder}
                                disabled={values.offerData.addressType === EAddressType.PPE || !enableApartmentsInput}
                                onClick={onClick}
                              />
                            )
                          }}
                        </Field>
                      </Grid>
                    </Grid>

                    <Divider className={classes.dividerPadding} style={{ width: "100%" }} />

                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        <div className={classes.tooltipHeaderContainer}>
                          {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                            <h3>3. Adres korespondencyjny</h3>
                          ) : (
                            <h3>Adres korespondencyjny</h3>
                          )}
                          <MuiThemeProvider theme={tooltipTheme}>
                            <Tooltip
                              title="Jeśli jesteś już naszym Klientem i w naszych systemach istnieje inny adres korespondencyjny, zostanie on zaktualizowany i zastąpiony adresem, który wprowadzisz poniżej."
                              placement="right"
                            >
                              <IconButton>
                                <Info htmlColor="#00BDA8" />
                              </IconButton>
                            </Tooltip>
                          </MuiThemeProvider>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="offerData.correspondenceAddressType">
                          {({ field, meta: { error, touched } }) => {
                            return (
                              <CorrespondenceAddressCheckboxCopy
                                correspondenceAddressType={field.value}
                                field={field}
                                label={
                                  contractType === ContractType.COMMERCIAL_WITH_PREMISES
                                    ? "Jak adres siedziby Przedsiębiorstwa"
                                    : "Jak adres zamieszkania"
                                }
                                setValue={(value) => {
                                  setFieldValue("offerData.correspondenceAddressType", value)
                                  setFieldValue(inputFields[3].name, values.offerData.address.street)
                                  setFieldValue(inputFields[4].name, values.offerData.address.city)
                                  setFieldValue(inputFields[19].name, values.customer.PPE.post)
                                  setFieldValue(inputFields[20].name, values.customer.PPE.nr1)
                                  setFieldValue(inputFields[21].name, values.customer.PPE.nr2)
                                  setEnableCorrespondenceStreetInput(true)
                                }}
                              ></CorrespondenceAddressCheckboxCopy>
                            )
                          }}
                        </Field>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Field
                          name={
                            values.offerData.correspondenceAddressType === ECorrespondenceAddressType.ADDRESS
                              ? inputFields[16].name
                              : inputFields[19].name
                          }
                          validate={(value) => {
                            if (isPostalCodeValid(value)) {
                              if (values.offerData.correspondenceAddressType == ECorrespondenceAddressType.ADDRESS) {
                                if (postalCodeChangedHandler(value, EAddressType.PPE)) {
                                  getCity(value, EAddressType.PPE)
                                }
                              }
                              setEnableCorrespondenceCityInput(true)
                              if (postalCodeChangedHandler(value, EAddressType.CORRESPONDENCE)) {
                                getCity(value, EAddressType.CORRESPONDENCE)
                              }
                            } else {
                              // setEnableCityInput(false)
                              //zablokowanie pola city
                            }
                            return validateField(value, inputFields[19])
                          }}
                        >
                          {({ field, meta: { error, touched } }) => {
                            const showError = Boolean(error && touched)
                            return (
                              <CustomInput
                                field={field}
                                metaError={error}
                                showError={showError}
                                placeholder={inputFields[19].placeholder}
                                onClick={onClick}
                                disabled={Boolean(
                                  values.offerData.correspondenceAddressType === ECorrespondenceAddressType.ADDRESS
                                )}
                              />
                            )
                          }}
                        </Field>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <CustomAutocompleteField
                          id="city2"
                          options={citiesCorrespondence ?? []}
                          onInputChange={(event, value, reason) => {
                            setFieldValue(inputFields[4].name, value)
                            setFieldValue(inputFields[3].name, "")
                            setFieldValue(inputFields[20].name, "")
                            setFieldValue(inputFields[21].name, "")
                            setEnableCorrespondenceStreetInput(true)
                            getStreet(value, EAddressType.CORRESPONDENCE)
                          }}
                          disabled={!enableCorrespondenceCityInput}
                          loading={isLoadingCorrespondenceCities}
                          inputValue={
                            values.offerData.correspondenceAddressType === ECorrespondenceAddressType.ADDRESS
                              ? values.offerData.address.city
                              : values.offerData.correspondenceAddress.city
                          }
                          renderInput={(params) => (
                            <Field
                              ref={params.InputProps.ref}
                              name={
                                values.offerData.correspondenceAddressType === ECorrespondenceAddressType.ADDRESS
                                  ? inputFields[2].name
                                  : inputFields[4].name
                              }
                              validate={(value) => validateField(value, inputFields[4])}
                              InputProps={{ ...params.InputProps }}
                            >
                              {({ field, meta: { error, touched } }) => {
                                const showError = Boolean(error && touched)
                                return (
                                  <CustomInput
                                    field={field}
                                    {...params}
                                    isAutocomplete={true}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {isLoadingCorrespondenceCities ? (
                                            <CircularProgress className={classes.inputProgress} size={20} />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                    metaError={error}
                                    showError={showError}
                                    placeholder={inputFields[4].placeholder}
                                    disabled={
                                      values.offerData.correspondenceAddressType ===
                                        ECorrespondenceAddressType.ADDRESS || !enableCorrespondenceCityInput
                                    }
                                    onClick={onClick}
                                  />
                                )
                              }}
                            </Field>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <div className={classes.autocompletePadding} />
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12} md={3}>
                        <CustomAutocompleteField
                          id="street2"
                          options={streetsCorrespondence ?? []}
                          onInputChange={(event, value, reason) => {
                            setFieldValue(inputFields[3].name, value)
                            setFieldValue(inputFields[20].name, "")
                            setFieldValue(inputFields[21].name, "")
                            setEnableCorrespondenceApartmentsInput(true)
                          }}
                          disabled={!enableCorrespondenceStreetInput}
                          loading={isLoadingCorrespondenceStreets}
                          inputValue={
                            values.offerData.correspondenceAddressType === ECorrespondenceAddressType.ADDRESS
                              ? values.offerData.address.street
                              : values.offerData.correspondenceAddress.street
                          }
                          renderInput={(params) => (
                            <Field
                              ref={params.InputProps.ref}
                              name={
                                values.offerData.correspondenceAddressType === ECorrespondenceAddressType.ADDRESS
                                  ? inputFields[1].name
                                  : inputFields[3].name
                              }
                              validate={(value) => validateField(value, inputFields[3])}
                              InputProps={{ ...params.InputProps }}
                            >
                              {({ field, meta: { error, touched } }) => {
                                const showError = Boolean(error && touched)
                                return (
                                  <CustomInput
                                    field={field}
                                    {...params}
                                    isAutocomplete={true}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {isLoadingCorrespondenceStreets ? (
                                            <CircularProgress className={classes.inputProgress} size={20} />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                    metaError={error}
                                    showError={showError}
                                    placeholder={inputFields[3].placeholder}
                                    disabled={
                                      values.offerData.correspondenceAddressType ===
                                        ECorrespondenceAddressType.ADDRESS || !enableCorrespondenceCityInput
                                    }
                                    onClick={onClick}
                                  />
                                )
                              }}
                            </Field>
                          )}
                        />
                        {/* <Field
                          name={
                            values.offerData.correspondenceAddressType ===
                            ECorrespondenceAddressType.ADDRESS
                              ? inputFields[1].name
                              : inputFields[3].name
                          }
                          validate={value =>
                            validateField(value, inputFields[3])
                          }
                        >
                          {({ field, meta: { error, touched } }) => {
                            const showError = Boolean(error && touched)
                            return (
                              <CustomInput
                                field={field}
                                metaError={error}
                                showError={showError}
                                placeholder={inputFields[3].placeholder}
                                onClick={onClick}
                                disabled={Boolean(
                                  values.offerData.correspondenceAddressType ===
                                    ECorrespondenceAddressType.ADDRESS
                                )}
                              />
                            )
                          }}
                        </Field> */}
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Field
                          name={
                            values.offerData.correspondenceAddressType === ECorrespondenceAddressType.ADDRESS
                              ? inputFields[17].name
                              : inputFields[20].name
                          }
                          validate={(value) => validateField(value, inputFields[17])}
                        >
                          {({ field, meta: { error, touched } }) => {
                            const showError = Boolean(error && touched)
                            return (
                              <CustomInput
                                field={field}
                                metaError={error}
                                showError={showError}
                                placeholder={inputFields[17].placeholder}
                                onClick={onClick}
                                disabled={Boolean(
                                  values.offerData.correspondenceAddressType === ECorrespondenceAddressType.ADDRESS ||
                                    !enableCorrespondenceApartmentsInput
                                )}
                              />
                            )
                          }}
                        </Field>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Field
                          name={
                            values.offerData.correspondenceAddressType === ECorrespondenceAddressType.ADDRESS
                              ? inputFields[18].name
                              : inputFields[21].name
                          }
                          validate={(value) => validateField(value, inputFields[18])}
                        >
                          {({ field, meta: { error, touched } }) => {
                            const showError = Boolean(error && touched)
                            return (
                              <CustomInput
                                field={field}
                                metaError={error}
                                showError={showError}
                                placeholder={inputFields[18].placeholder}
                                onClick={onClick}
                                disabled={Boolean(
                                  values.offerData.correspondenceAddressType === ECorrespondenceAddressType.ADDRESS ||
                                    !enableCorrespondenceApartmentsInput
                                )}
                              />
                            )
                          }}
                        </Field>
                      </Grid>
                    </Grid>

                    <Divider className={classes.dividerPadding} style={{ width: "100%" }} />

                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                          <h3>4. Oświadczam, że:</h3>
                        ) : (
                          <>
                            <h3>Oświadczam, że:</h3>
                            <h4>1. Przy zawieraniu niniejszej Umowy</h4>
                          </>
                        )}
                      </Grid>
                      {contractType !== ContractType.COMMERCIAL_WITH_PREMISES && (
                        <>
                          <Grid item xs={12}>
                            <Field name={"offerData.statementType"}>
                              {({ field, form, meta }) => {
                                return (
                                  <RadioButtonsGroup
                                    documentType={field.value}
                                    form={form}
                                    field={field}
                                    className={classes.defaultRadioGroup}
                                    horizontal={true}
                                    setValue={(value) => {
                                      setFieldValue("offerData.statementType", value)
                                    }}
                                  >
                                    <FormControlLabel
                                      key={EStatementType.SELF}
                                      value={EStatementType.SELF}
                                      control={<Radio />}
                                      label="Działam osobiście"
                                    />
                                    <FormControlLabel
                                      disabled={values.customer.customerType === ContractType.COMMERCIAL_JDG}
                                      key={EStatementType.PARTNER}
                                      value={EStatementType.PARTNER}
                                      control={<Radio />}
                                      label="Zawieram Umowę wspólnie z drugą osobą"
                                    />
                                    <FormControlLabel
                                      key={EStatementType.PROXY}
                                      value={EStatementType.PROXY}
                                      control={<Radio />}
                                      label="Jestem pełnomocnikiem"
                                    />
                                  </RadioButtonsGroup>
                                )
                              }}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Field
                              name={inputFields[5].name}
                              validate={(value) => {
                                if (
                                  values.offerData.statementType === EStatementType.PROXY ||
                                  values.offerData.statementType === EStatementType.PARTNER
                                ) {
                                  return validateField(value, inputFields[5])
                                }
                              }}
                            >
                              {({ field, meta: { error, touched } }) => {
                                const showError = Boolean(
                                  error &&
                                    touched &&
                                    (values.offerData.statementType === EStatementType.PROXY ||
                                      values.offerData.statementType === EStatementType.PARTNER)
                                )
                                return (
                                  <CustomInput
                                    field={field}
                                    metaError={error}
                                    showError={showError}
                                    placeholder={String(inputFields[5].placeholder)}
                                    onClick={onClick}
                                    disabled={Boolean(
                                      values.offerData.statementType !== EStatementType.PROXY &&
                                        values.offerData.statementType !== EStatementType.PARTNER
                                    )}
                                  />
                                )
                              }}
                            </Field>
                          </Grid>

                          <Grid item xs={12} md={3}>
                            <Field
                              name={inputFields[6].name}
                              validate={(value) => {
                                if (
                                  values.offerData.statementType === EStatementType.PROXY ||
                                  values.offerData.statementType === EStatementType.PARTNER
                                ) {
                                  return validateField(value, inputFields[6])
                                }
                              }}
                            >
                              {({ field, meta: { error, touched } }) => {
                                const showError = Boolean(
                                  error &&
                                    touched &&
                                    (values.offerData.statementType === EStatementType.PROXY ||
                                      values.offerData.statementType === EStatementType.PARTNER)
                                )
                                return (
                                  <CustomInput
                                    field={field}
                                    metaError={error}
                                    showError={showError}
                                    placeholder={String(inputFields[6].placeholder)}
                                    onClick={onClick}
                                    disabled={Boolean(
                                      values.offerData.statementType !== EStatementType.PROXY &&
                                        values.offerData.statementType !== EStatementType.PARTNER
                                    )}
                                  />
                                )
                              }}
                            </Field>
                          </Grid>

                          {values.offerData.statementType === EStatementType.PROXY && (
                            <div className={classes.sectionContainer}>
                              <div className={classes.fileAdding}>
                                <div>
                                  <input
                                    accept="image/*,.pdf"
                                    id="button-fileProxy"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(event) => getFile(event, setFieldValue, EStatementType.PROXY)}
                                  />
                                  <label htmlFor="button-fileProxy">
                                    <Button
                                      startIcon={<AttachFile />}
                                      variant="outlined"
                                      color="primary"
                                      className={classes.fileButton}
                                      component="span"
                                    >
                                      {values["fileProxy"] ? "Zmień plik" : "Załącz plik"}
                                    </Button>
                                  </label>
                                </div>
                                <div>
                                  {values["fileProxy"] ? (
                                    <p>
                                      Plik{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {values["fileProxy"].name}{" "}
                                      </span>
                                      został załączony
                                    </p>
                                  ) : (
                                    <p>Skan lub zdjęcie umowy pełnomocnictwa.</p>
                                  )}
                                  <Transition
                                    items={wrongFileSizeProxy}
                                    from={{
                                      opacity: 0,
                                      height: "0px",
                                      marginTop: "0px",
                                    }}
                                    enter={{
                                      opacity: 1,
                                      height: "15px",
                                      marginTop: "2px",
                                    }}
                                    leave={{
                                      opacity: 0,
                                      height: "0px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    {(values, visible) => {
                                      return (
                                        visible && (
                                          <animated.p style={values} className={classes.fileSizeError}>
                                            {wrongProxyFileMessage}
                                          </animated.p>
                                        )
                                      )
                                    }}
                                  </Transition>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </Grid>

                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                          <h3 style={{ fontWeight: "normal" }}>Posiadam tytuł prawny do obiektu.</h3>
                        ) : (
                          <h4>2. Posiadam tytuł prawny do obiektu</h4>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <h6 style={{ marginLeft: "16px" }} className={classes.emailLabel}>
                          Tytuł wynika z:
                        </h6>
                      </Grid>
                      <Grid item xs={12} style={{ marginLeft: "16px" }}>
                        <Field name={"offerData.ownershipSourceType"}>
                          {({ field, form, meta }) => {
                            return (
                              <RadioButtonsGroup
                                documentType={field.value}
                                form={form}
                                field={field}
                                className={classes.defaultRadioGroup}
                                horizontal={true}
                                setValue={(value) => {
                                  setFieldValue("offerData.ownershipSourceType", value)
                                }}
                              >
                                <FormControlLabel
                                  key={EOwnershipSourceType.NOTARIAL_ACT}
                                  value={EOwnershipSourceType.NOTARIAL_ACT}
                                  control={<Radio disabled={values.offerData.ownershipType === EOwnershipType.NO} />}
                                  label="Aktu notarialnego"
                                />
                                <FormControlLabel
                                  key={EOwnershipSourceType.LAND_REGISTER}
                                  value={EOwnershipSourceType.LAND_REGISTER}
                                  control={<Radio disabled={values.offerData.ownershipType === EOwnershipType.NO} />}
                                  label="Odpisu z księgi wieczystej"
                                />
                                <FormControlLabel
                                  key={EOwnershipSourceType.LEASE_AGREEMENT}
                                  value={EOwnershipSourceType.LEASE_AGREEMENT}
                                  control={<Radio disabled={values.offerData.ownershipType === EOwnershipType.NO} />}
                                  label="Umowy najmu"
                                />
                                <FormControlLabel
                                  key={EOwnershipSourceType.OTHER}
                                  value={EOwnershipSourceType.OTHER}
                                  control={<Radio disabled={values.offerData.ownershipType === EOwnershipType.NO} />}
                                  label="Inny"
                                />
                              </RadioButtonsGroup>
                            )
                          }}
                        </Field>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={contractType === ContractType.COMMERCIAL_WITH_PREMISES ? { marginLeft: "16px" } : {}}
                      >
                        <Field
                          name={inputFields[7].name}
                          validate={(value) => {
                            if (values.offerData.ownershipSourceType === EOwnershipSourceType.OTHER) {
                              return validateField(value, inputFields[7])
                            }
                          }}
                        >
                          {({ field, meta: { error, touched } }) => {
                            const showError = Boolean(
                              error &&
                                touched &&
                                values.offerData.ownershipSourceType === EOwnershipSourceType.OTHER &&
                                values.offerData.ownershipType === EOwnershipType.YES
                            )

                            return (
                              <CustomInput
                                field={field}
                                metaError={error}
                                showError={showError}
                                placeholder={String(inputFields[7].placeholder)}
                                onClick={onClick}
                                disabled={Boolean(
                                  values.offerData.ownershipSourceType !== EOwnershipSourceType.OTHER ||
                                    values.offerData.ownershipType !== EOwnershipType.YES
                                )}
                              />
                            )
                          }}
                        </Field>
                      </Grid>
                    </Grid>

                    {contractType === ContractType.COMMERCIAL_WITH_PREMISES && (
                      <Divider className={classes.dividerPadding} style={{ width: "100%" }} />
                    )}

                    <Grid container alignItems="center" spacing={2}>
                      {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                        <Grid item xs={12}>
                          <h3>5. Nazwa/przeznaczenie obiektu:</h3>
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <h4>3. Energia elektryczna będzie dostarczana na potrzeby własne:</h4>
                          </Grid>
                          <Grid item xs={12}>
                            <h5 className={classes.formSubeHeader}>A. Gospodarstwa domowego</h5>
                          </Grid>
                        </>
                      )}
                      {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                        <Grid item xs={12}>
                          <Field name={"offerData.commercialEnergyDestinationType"}>
                            {({ field, form, meta }) => {
                              return (
                                <RadioButtonsGroup
                                  documentType={field.value}
                                  form={form}
                                  field={field}
                                  className={classes.defaultRadioGroup}
                                  horizontal={true}
                                  setValue={(value) => {
                                    setFieldValue("offerData.commercialEnergyDestinationType", value)
                                  }}
                                >
                                  <>
                                    <FormControlLabel
                                      key={ECommercialEnergyDestinationType.HOUSEHOLD}
                                      value={ECommercialEnergyDestinationType.HOUSEHOLD}
                                      control={<Radio />}
                                      label="Gospodarstwo domowe"
                                    />
                                    <FormControlLabel
                                      key={ECommercialEnergyDestinationType.COMMERCIAL_PREMISES}
                                      value={ECommercialEnergyDestinationType.COMMERCIAL_PREMISES}
                                      control={<Radio />}
                                      label="Lokal usługowy"
                                    />
                                    <FormControlLabel
                                      key={ECommercialEnergyDestinationType.OTHER}
                                      value={ECommercialEnergyDestinationType.OTHER}
                                      control={<Radio />}
                                      label="Inne, jakie? (działalność produkcyjna, działalność handlowo-usługowa itp.)"
                                    />
                                  </>
                                </RadioButtonsGroup>
                              )
                            }}
                          </Field>
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <Field name={"offerData.energyDestinationType"}>
                              {({ field, form, meta }) => {
                                return (
                                  <RadioButtonsGroup
                                    documentType={field.value}
                                    form={form}
                                    field={field}
                                    className={classes.defaultRadioGroup}
                                    horizontal={true}
                                    setValue={(value) => {
                                      setFieldValue("offerData.energyDestinationType", value)
                                    }}
                                  >
                                    <>
                                      <FormControlLabel
                                        key={EEnergyDestinationType.FLAT}
                                        value={EEnergyDestinationType.FLAT}
                                        control={
                                          <Radio
                                            disabled={values.customer.customerType === ContractType.COMMERCIAL_JDG}
                                          />
                                        }
                                        label="Mieszkania"
                                      />
                                      <FormControlLabel
                                        key={EEnergyDestinationType.HOUSE}
                                        value={EEnergyDestinationType.HOUSE}
                                        control={
                                          <Radio
                                            disabled={values.customer.customerType === ContractType.COMMERCIAL_JDG}
                                          />
                                        }
                                        label="Domu wolnostojącego"
                                      />
                                      <FormControlLabel
                                        key={EEnergyDestinationType.TARRACED_HOUSE}
                                        value={EEnergyDestinationType.TARRACED_HOUSE}
                                        control={
                                          <Radio
                                            disabled={values.customer.customerType === ContractType.COMMERCIAL_JDG}
                                          />
                                        }
                                        label="Dom typu bliźniak/szeregowego"
                                      />
                                      <FormControlLabel
                                        key={EEnergyDestinationType.OTHER}
                                        value={EEnergyDestinationType.OTHER}
                                        control={
                                          <Radio
                                            disabled={values.customer.customerType === ContractType.COMMERCIAL_JDG}
                                          />
                                        }
                                        label="Inne gospodarstwa domowe (jakie?)"
                                      />
                                    </>
                                  </RadioButtonsGroup>
                                )
                              }}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Field
                              name={inputFields[8].name}
                              validate={(value) => {
                                if (
                                  values.customer.customerType === ContractType.INDIVIDUAL &&
                                  values.offerData.energyDestinationType === EEnergyDestinationType.OTHER
                                ) {
                                  return validateField(value, inputFields[8])
                                }
                              }}
                            >
                              {({ field, meta: { error, touched } }) => {
                                const showError = Boolean(
                                  error &&
                                    touched &&
                                    values.customer.customerType === ContractType.INDIVIDUAL &&
                                    values.offerData.energyDestinationType === EEnergyDestinationType.OTHER
                                )
                                return (
                                  <CustomInput
                                    field={field}
                                    metaError={error}
                                    showError={showError}
                                    placeholder={String(inputFields[8].placeholder)}
                                    onClick={onClick}
                                    disabled={Boolean(
                                      !(
                                        values.customer.customerType === ContractType.INDIVIDUAL &&
                                        values.offerData.energyDestinationType === EEnergyDestinationType.OTHER
                                      )
                                    )}
                                  />
                                )
                              }}
                            </Field>
                          </Grid>

                          <Grid item xs={12}>
                            <h5 className={classes.formSubeHeader}>B. Prowadzonej działalności</h5>
                          </Grid>
                          <Grid item xs={12}>
                            <Field name={"offerData.jdgEnergyDestinationType"}>
                              {({ field, form, meta }) => {
                                return (
                                  <RadioButtonsGroup
                                    documentType={field.value}
                                    form={form}
                                    field={field}
                                    className={classes.defaultRadioGroup}
                                    horizontal={true}
                                    setValue={(value) => {
                                      setFieldValue("offerData.jdgEnergyDestinationType", value)
                                    }}
                                  >
                                    <>
                                      <Grid item xs={12}>
                                        <h5 className={classes.formSubeHeader}>dla grup taryfowych G:</h5>
                                      </Grid>
                                      <FormControlLabel
                                        key={EJdgEnergyDestinationType.RENTING}
                                        value={EJdgEnergyDestinationType.RENTING}
                                        control={
                                          <Radio disabled={values.customer.customerType === ContractType.INDIVIDUAL} />
                                        }
                                        label="Wynajmu w celach mieszkaniowych innym podmiotom domu/mieszkania/lokalu"
                                      />
                                      <FormControlLabel
                                        key={EJdgEnergyDestinationType.POWERSUPPLY}
                                        value={EJdgEnergyDestinationType.POWERSUPPLY}
                                        control={
                                          <Radio disabled={values.customer.customerType === ContractType.INDIVIDUAL} />
                                        }
                                        label="Zasilania części wspólnych budynku mieszkalnego (np. windy)"
                                      />
                                      <FormControlLabel
                                        key={EJdgEnergyDestinationType.FREELANCER_PROFESSION}
                                        value={EJdgEnergyDestinationType.FREELANCER_PROFESSION}
                                        control={
                                          <Radio disabled={values.customer.customerType === ContractType.INDIVIDUAL} />
                                        }
                                        label="Wolnego zawodu (np. adwokat)"
                                      />
                                      <Grid item xs={12}>
                                        <h5 className={classes.formSubeHeader}>dla grup taryfowych C:</h5>
                                      </Grid>
                                      <FormControlLabel
                                        key={EJdgEnergyDestinationType.SERVICES}
                                        value={EJdgEnergyDestinationType.SERVICES}
                                        control={
                                          <Radio disabled={values.customer.customerType === ContractType.INDIVIDUAL} />
                                        }
                                        label="Usługowe"
                                      />
                                      <FormControlLabel
                                        key={EJdgEnergyDestinationType.PRODUCTION}
                                        value={EJdgEnergyDestinationType.PRODUCTION}
                                        control={
                                          <Radio disabled={values.customer.customerType === ContractType.INDIVIDUAL} />
                                        }
                                        label="Produkcyjne"
                                      />
                                      <FormControlLabel
                                        key={EJdgEnergyDestinationType.TRADING}
                                        value={EJdgEnergyDestinationType.TRADING}
                                        control={
                                          <Radio disabled={values.customer.customerType === ContractType.INDIVIDUAL} />
                                        }
                                        label="Handlowe"
                                      />
                                      <FormControlLabel
                                        key={EJdgEnergyDestinationType.CAR_CHARGING_STATION}
                                        value={EJdgEnergyDestinationType.CAR_CHARGING_STATION}
                                        control={
                                          <Radio disabled={values.customer.customerType === ContractType.INDIVIDUAL} />
                                        }
                                        label="Stacji ładowania samochodu"
                                      />
                                    </>
                                  </RadioButtonsGroup>
                                )
                              }}
                            </Field>
                          </Grid>
                        </>
                      )}

                      {values.offerData.commercialEnergyDestinationType === ECommercialEnergyDestinationType.OTHER && (
                        <Grid item xs={12} md={3}>
                          <Field
                            name={inputFields[23].name}
                            validate={(value) => {
                              if (
                                values.offerData.commercialEnergyDestinationType ===
                                ECommercialEnergyDestinationType.OTHER
                              ) {
                                return validateField(value, inputFields[23])
                              }
                            }}
                          >
                            {({ field, meta: { error, touched } }) => {
                              const showError = Boolean(
                                error &&
                                  touched &&
                                  values.offerData.commercialEnergyDestinationType ===
                                    ECommercialEnergyDestinationType.OTHER
                              )
                              return (
                                <CustomInput
                                  field={field}
                                  metaError={error}
                                  showError={showError}
                                  placeholder={inputFields[23].placeholder}
                                  onClick={onClick}
                                  disabled={Boolean(
                                    !(
                                      values.offerData.commercialEnergyDestinationType ===
                                      ECommercialEnergyDestinationType.OTHER
                                    )
                                  )}
                                />
                              )
                            }}
                          </Field>
                        </Grid>
                      )}

                      {values.offerData.commercialEnergyDestinationType ===
                        ECommercialEnergyDestinationType.COMMERCIAL_PREMISES && (
                        <>
                          <Grid item xs={12}>
                            <h4 style={{ marginLeft: "16px" }} className={classes.emailLabel}>
                              Zamawiam moc umowną (zaznacz właściwe):
                            </h4>
                          </Grid>
                          <Field
                            name={inputFields[22].name}
                            required={true}
                            validate={(value) => {
                              if (
                                values.offerData.commercialEnergyDestinationType ===
                                  ECommercialEnergyDestinationType.COMMERCIAL_PREMISES &&
                                values.offerData.contractedPowerType === EPowerOption.CUSTOM
                              ) {
                                if (
                                  (powerData !== null &&
                                    value !== "" &&
                                    (Number(value) > powerData?.maximumContractedPower ||
                                      Number(value) < powerData?.minimumContractedPower)) ||
                                  !/^\d+$/.test(value)
                                ) {
                                  return inputFields[22].errorMsg
                                }
                                if (powerData !== null && value === "") {
                                  return inputFields[22].errorEmpty
                                }
                              }
                            }}
                          >
                            {({ field, form, meta: { error, touched } }) => {
                              const showError = Boolean(
                                error &&
                                  touched &&
                                  values.offerData.commercialEnergyDestinationType ===
                                    ECommercialEnergyDestinationType.COMMERCIAL_PREMISES
                              )
                              return (
                                <WattageSelector
                                  inputFieldValue={values.offerData.contractedPower}
                                  inputField={field}
                                  showFieldError={showError}
                                  setFieldValue={form.setFieldValue}
                                  inputMetaError={error}
                                  powerData={powerData}
                                  showSendUsEmailModal={() => setShowSendUsEmailModal(true)}
                                />
                              )
                            }}
                          </Field>
                        </>
                      )}
                    </Grid>

                    {contractType === ContractType.COMMERCIAL_WITH_PREMISES && (
                      <Divider className={classes.dividerPadding} style={{ width: "100%" }} />
                    )}

                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={6}>
                        <Grid item xs={12}>
                          <div className={classes.tooltipHeaderContainer}>
                            {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                              <h3>6. E-faktura</h3>
                            ) : (
                              <h4>4. E-faktura</h4>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <h5 className={classes.formSubeHeader}>Wyrażam zgodę:</h5>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"offerData.eInvoiceType"}>
                            {({ field, form, meta }) => {
                              return (
                                <RadioButtonsGroup
                                  documentType={field.value}
                                  form={form}
                                  field={field}
                                  className={classes.defaultRadioGroup}
                                  horizontal={true}
                                  setValue={(value) => {
                                    setFieldValue("offerData.eInvoiceType", value)
                                  }}
                                >
                                  <FormControlLabel
                                    key={EEInvoiceType.YES}
                                    value={EEInvoiceType.YES}
                                    control={<Radio />}
                                    label="Tak"
                                  />
                                  <FormControlLabel
                                    key={EEInvoiceType.NO}
                                    value={EEInvoiceType.NO}
                                    control={<Radio />}
                                    label="Nie"
                                  />
                                </RadioButtonsGroup>
                              )
                            }}
                          </Field>
                        </Grid>
                      </Grid>

                      <Grid item xs={6}>
                        <h4>
                          Dzięki e-fakturze chronisz środowisko i masz dostęp do wszystkich faktur w dowolnej chwili, z
                          dowolnego urządzenia.
                        </h4>
                      </Grid>

                      {/* TODO: Po co to pole skoro jest zablokowane CONST? */}
                      {/* <Grid item xs={12}>
                        <h6 className={classes.emailLabel}>
                          Adres e-mail dla e-faktury:
                        </h6>

                        <Grid item xs={12} md={3}>
                          <Field
                            name={"customer.email"}
                            validate={value => {
                              return validateField(value, inputFields[9])
                            }}
                          >
                            {({ field, meta: { error, touched } }) => {
                              const showError = Boolean(
                                error &&
                                  touched &&
                                  values.offerData.eInvoiceAddressType !==
                                    EEInvoiceAddressType.CLIENT &&
                                  values.offerData.eInvoiceType !==
                                    EEInvoiceType.NO
                              )

                              return (
                                <CustomInput
                                  field={field}
                                  metaError={error}
                                  showError={showError}
                                  placeholder={String(
                                    inputFields[9].placeholder
                                  )}
                                  onClick={onClick}
                                  disabled={true}
                                />
                              )
                            }}
                          </Field>
                        </Grid>
                      </Grid> */}
                    </Grid>

                    {contractType === ContractType.COMMERCIAL_WITH_PREMISES && (
                      <Divider className={classes.dividerPadding} style={{ width: "100%" }} />
                    )}

                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={6}>
                        <Grid item xs={12}>
                          <div className={classes.tooltipHeaderContainer}>
                            {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                              <h3>7. Moja Polenergia (eBOK)</h3>
                            ) : (
                              <h4>5. Moja Polenergia (eBOK)</h4>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <h5 className={classes.formSubeHeader}>
                            Wyrażam zgodę na założenie elektronicznego konta Biura Obsługi Klienta (eBOK) oraz
                            przesłania linku aktywacyjnego:
                          </h5>
                        </Grid>
                        <Grid item xs={12}>
                          <Field name={"offerData.aggreeType"}>
                            {({ field, form, meta }) => {
                              return (
                                <RadioButtonsGroup
                                  documentType={field.value}
                                  form={form}
                                  field={field}
                                  className={classes.defaultRadioGroup}
                                  horizontal={true}
                                  setValue={(value) => {
                                    setFieldValue("offerData.aggreeType", value)
                                  }}
                                >
                                  <FormControlLabel
                                    key={EAggreeType.YES_CLIENT}
                                    value={EAggreeType.YES_CLIENT}
                                    control={<Radio />}
                                    label="Tak, na adres e-mail Klienta"
                                  />
                                  <FormControlLabel
                                    key={EAggreeType.NO}
                                    value={EAggreeType.NO}
                                    control={<Radio />}
                                    label="Nie wyrażam zgody"
                                  />
                                </RadioButtonsGroup>
                              )
                            }}
                          </Field>
                        </Grid>
                      </Grid>

                      <Grid item xs={6}>
                        <h4>
                          Moja Polenergia to prosty i szybki dostęp do faktur i salda, wygodne i bezpieczne płatności
                          elektroniczne, dane dotyczące zużycia energii elektrycznej, powiadomienia o wystawieniu faktur
                          i zbliżającym się terminie płatności.
                        </h4>
                      </Grid>
                    </Grid>

                    {contractType === ContractType.COMMERCIAL_WITH_PREMISES && (
                      <Divider className={classes.dividerPadding} style={{ width: "100%" }} />
                    )}

                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={6}>
                        <Grid item xs={12}>
                          <div className={classes.tooltipHeaderContainer}>
                            {contractType === ContractType.COMMERCIAL_WITH_PREMISES ? (
                              <h3>8. Zgody na kontakt</h3>
                            ) : (
                              <h4>6. Zgody na kontakt</h4>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Field name={"customer.allAgree"}>
                              {() => {
                                return (
                                  <AllMarketingConsentsCheckboxCopy
                                    checked={Boolean(values.customer.emailAgree && values.customer.phoneAgree)}
                                    onChange={(value) => {
                                      if (value.target.checked) {
                                        setFieldValue("customer.phoneAgree", true)
                                        setFieldValue("customer.emailAgree", true)
                                      }
                                    }}
                                  ></AllMarketingConsentsCheckboxCopy>
                                )
                              }}
                            </Field>
                          </Grid>

                          <Field name={"customer.phoneAgree"}>
                            {({ field, form, meta }) => {
                              return (
                                <RadioButtonsGroup
                                  documentType={field.value}
                                  form={form}
                                  field={field}
                                  className={classes.defaultRadioGroup}
                                  horizontal={true}
                                  setValue={(value) => {
                                    setFieldValue("customer.phoneAgree", value === "true")
                                    if (value !== "true") {
                                      setFieldValue("customer.allAgree", false)
                                    }
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <h4>Wyrażam zgodę na kontakt telefoniczny</h4>
                                    <div>
                                      <span
                                        className={classes.spanHover}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          setShowPhoneContactConsent((prev) => !prev)
                                        }}
                                      >
                                        {showPhoneContactConsent ? "Zwiń" : "Zobacz pełną treść zgody"}
                                      </span>
                                    </div>
                                    <Collapse in={showPhoneContactConsent}>
                                      <p>{consents[0].description}</p>
                                    </Collapse>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormControlLabel
                                      key={EConsentAggreeType.YES}
                                      value={true}
                                      control={<Radio />}
                                      label="Tak"
                                    />
                                    <FormControlLabel
                                      key={EConsentAggreeType.NO}
                                      value={false}
                                      control={<Radio />}
                                      label="Nie"
                                    />
                                  </Grid>
                                </RadioButtonsGroup>
                              )
                            }}
                          </Field>

                          <Field name={"customer.emailAgree"}>
                            {({ field, form, meta }) => {
                              return (
                                <RadioButtonsGroup
                                  documentType={field.value}
                                  form={form}
                                  field={field}
                                  className={classes.defaultRadioGroup}
                                  horizontal={true}
                                  setValue={(value) => {
                                    setFieldValue("customer.emailAgree", value === "true")
                                    if (value !== "true") {
                                      setFieldValue("customer.allAgree", false)
                                    }
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <h4>Wyrażam zgodę na kontakt drogą elektroniczną</h4>
                                    <div>
                                      <span
                                        className={classes.spanHover}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          setShowEmailContactConsent((prev) => !prev)
                                        }}
                                      >
                                        {showEmailContactConsent ? "Zwiń" : "Zobacz pełną treść zgody"}
                                      </span>
                                    </div>
                                    <Collapse in={showEmailContactConsent}>
                                      <p>{consents[1].description}</p>
                                    </Collapse>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormControlLabel
                                      key={EConsentAggreeType.YES}
                                      value={true}
                                      control={<Radio />}
                                      label="Tak"
                                    />
                                    <FormControlLabel
                                      key={EConsentAggreeType.NO}
                                      value={false}
                                      control={<Radio />}
                                      label="Nie"
                                    />
                                  </Grid>
                                </RadioButtonsGroup>
                              )
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <h4>
                          Jeśli wyrazisz zgodę na kontakt telefoniczny i/lub drogą elektroniczną, będziemy mogli
                          poinformować Cię telefonicznie lub mailowo o naszych nowych ofertach obniżających koszty
                          zużycia prądu, o ofertach z usługami dodatkowymi, o bonusach i ciekawych wydarzeniach.
                          Wyrażone przez Ciebie zgody możesz w każdej chwili odwołać.
                        </h4>
                      </Grid>
                    </Grid>

                    <Consumer>
                      {({ showForm, setShowForm }) => (
                        <Grid item xs={12} md={7}>
                          <ErrorModal
                            show={showErrorModal}
                            toggleModal={() => {
                              setShowErrorModal(false)
                            }}
                            error={errorMsg}
                          />
                          <SuccessModal
                            show={showSuccessModal}
                            toggleModal={() => {
                              setShowSuccessModal(false)
                            }}
                            now={now}
                          />
                          <MeetingModal
                            show={showCalendarModal}
                            toggleModal={() => {
                              setShowCalendarModal(false)
                              dateReject()
                            }}
                            onSubmit={submitDate}
                          />
                          <SendUsEmailModal
                            show={showSendUsEmailModal}
                            toggleModal={() => setShowSendUsEmailModal(false)}
                          />
                          {showContactComponent ? (
                            <>
                              <FormModal
                                id={EFormTypes.PESEL}
                                show={true}
                                toggleModal={() => setShowContactComponent((value) => !value)}
                                getCalendarValue={() => getCalendarDataPromise()}
                                onSucces={(now) => {
                                  setShowSuccessModal(true)
                                  setShowCalendarModal(false)
                                  setShowForm(false)
                                  setNow(now)
                                }}
                                onError={(errMsg) => {
                                  setErrorMsg(errMsg)
                                  setShowSuccessModal(false)
                                  setShowCalendarModal(false)
                                  setShowForm(false)
                                  setShowErrorModal(true)
                                }}
                                avoidFileAdding={true}
                              />
                              <Portal>
                                <StyledButton
                                  variant="contained"
                                  color="primary"
                                  className={classes.formButton}
                                  onClick={() => setShowForm(true)}
                                >
                                  <MailOutlineIcon />
                                </StyledButton>
                              </Portal>
                            </>
                          ) : null}

                          {unableToDownloadData ? (
                            <>
                              <FormModal
                                id={EFormTypes.SERVER_ERROR}
                                show={true}
                                toggleModal={() => setUnableToDownloadData((value) => !value)}
                                getCalendarValue={() => getCalendarDataPromise()}
                                onSucces={(now) => {
                                  setShowSuccessModal(true)
                                  setShowCalendarModal(false)
                                  setShowForm(false)
                                  setNow(now)
                                }}
                                onError={(errMsg) => {
                                  setErrorMsg(errMsg)
                                  setShowSuccessModal(false)
                                  setShowCalendarModal(false)
                                  setShowForm(false)
                                  setShowErrorModal(true)
                                }}
                              />
                              <Portal>
                                <StyledButton
                                  variant="contained"
                                  color="primary"
                                  className={classes.formButton}
                                  onClick={() => setShowForm(true)}
                                >
                                  <MailOutlineIcon />
                                </StyledButton>
                              </Portal>
                            </>
                          ) : null}
                        </Grid>
                      )}
                    </Consumer>
                  </Grid>
                </Card>
              </div>

              <div className={classes.buttonsContainer}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  disabled={showContactComponent}
                  onClick={async () => {
                    if (values.offerData.contractedPowerType === EPowerOption.OTHER) {
                      setShowSendUsEmailModal(true)
                    } else {
                      await submitForm()
                      submit(values, await validateForm(), setSubmitting, resetForm, onValidationError)
                    }
                  }}
                >
                  Zamawiam
                </StyledButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}
