import React from "react"
import { IconButton, makeStyles, Theme } from "@material-ui/core"
import scrollTo from "gatsby-plugin-smoothscroll"

export interface ScrollToButtonProps {
  scrollSelector: string
}

export const ScrollToButton: React.FC<ScrollToButtonProps> = (
  props: ScrollToButtonProps
) => {
  const useStyles = makeStyles((theme: Theme) => ({
    scrollButtonContainer: {
      textAlign: "center",
      marginBottom: "40px",
      marginTop: "40px",
    },
    button: {
      background: "white",
      padding: "0",
    },
  }))

  const classes = useStyles()

  return (
    <div className={classes.scrollButtonContainer}>
      <IconButton
        className={classes.button}
        onClick={() => {
          scrollTo(props.scrollSelector)
        }}
      >
        <svg
          width="52"
          height="52"
          viewBox="0 -5 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.05 34.3996L26 38.6496L21.1 34.3996C20.7 34.0496 20.65 33.3996 21 32.9496C21.2 32.7496 21.45 32.6496 21.7 32.5996C21.95 32.5996 22.25 32.6496 22.45 32.8496L26.05 35.9496L29.75 32.8496C30.2 32.4996 30.8 32.5496 31.2 32.9496C31.5 33.3996 31.45 34.0496 31.05 34.3996ZM32.9 28.6996L26 34.6496L19.1 28.6996C18.7 28.3496 18.65 27.6996 19 27.2496C19.35 26.8496 20 26.7996 20.45 27.1496L26 31.9996L31.55 27.1996C31.75 27.0496 32 26.9496 32.3 26.9496C32.55 26.9496 32.8 27.0996 33 27.2996C33.15 27.4996 33.25 27.7496 33.25 28.0496C33.2 28.2996 33.1 28.5496 32.9 28.6996ZM33.5 21.6496C33.5 24.3496 31.3 26.5496 28.6 26.5496C27.85 26.5496 27.25 25.9496 27.25 25.1496C27.25 24.3996 27.85 23.7996 28.6 23.7996C29.8 23.7996 30.75 22.8496 30.75 21.6496V10.2496C30.75 9.04961 29.8 8.09961 28.6 8.09961H23.4C22.2 8.09961 21.25 9.04961 21.25 10.2496V21.6496C21.25 22.8496 22.2 23.7996 23.4 23.7996C24.15 23.7996 24.75 24.3996 24.75 25.1496C24.75 25.8996 24.15 26.5496 23.4 26.5496C20.7 26.5496 18.5 24.3496 18.5 21.6496V10.2496C18.5 7.54961 20.7 5.34961 23.4 5.34961H28.55C31.25 5.34961 33.45 7.54961 33.45 10.2496V21.6496H33.5Z"
            fill="#11A090"
          />
        </svg>
      </IconButton>
    </div>
  )
}
