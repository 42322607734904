import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { MegaphoneProps } from "../../utils/MegaphoneProps"

const Megaphone: React.FC<MegaphoneProps> = ({ className, styles }) => {
    const data = useStaticQuery(graphql`
    {
      megaPhone: file(relativePath: { eq: "megaphone.png" }) {
        publicURL
      }
    }
  `)

  return (
    <img
      alt="megafon"
      className={className}
      src={data.megaPhone.publicURL}
      style={styles}
    />
  )
}

export default Megaphone
