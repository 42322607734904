import React from "react"
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { Button, Grid } from "@material-ui/core"
import scrollTo from "gatsby-plugin-smoothscroll"

export interface MyPoleneBannerProps {}

export interface MyPoleneCardProps {
  isBannerHeader?: boolean
  icon: React.ReactNode
  content: React.ReactNode
}

export const MyPoleneCard: React.FC<MyPoleneCardProps> = (
  props: MyPoleneCardProps
) => {
  const useStyles = makeStyles((theme: Theme) => ({
    cardSpace: {
      margin: "48px",
    },
    card: {
      padding: "40px 48px 56px 67px",
      borderRadius: "16px",
      boxShadow: "0 0 6px 4px rgba(122, 122, 122, 0.08)",
      backgroundColor: "#fff",
      maxWidth: "880px",
      margin: "auto",
    },
    cardGrid: {
      display: "flex",
      flexDirection: "row",
    },
    cardIcon: {
      paddingRight: "48px",
      margin: "auto",
    },
    smallCardIcon: {
      paddingRight: "0px",
      margin: "auto",
    },
    cardContent: {
      flex: 1,
    },
    headerCard: {
      padding: "30px 50px 30px 50px",
      borderRadius: "8px",
      boxShadow: "0 0 6px 4px rgba(122, 122, 122, 0.08)",
      backgroundColor: "#fff",
      margin: "auto",
      maxWidth: "1100px",
    },
    startButton: {
      width: "25%",
      paddingLeft: "24px",
      paddingRight: "24px",
      borderRadius: "24px",
      borderWidth: "2.5px !important",
      fontSize: "14px",
      letterSpacing: "0.56px",
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        minWidth: "206px",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "170px",
      },
      padding: "8px 12.4px 8px 12.4px",
      borderColor: "#12beab",
    },
    startButtonWide: {
      width: "100%",
      paddingLeft: "24px",
      paddingRight: "24px",
      borderRadius: "24px",
      borderWidth: "2.5px !important",
      fontSize: "14px",
      letterSpacing: "0.56px",
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        minWidth: "206px",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "170px",
      },
      padding: "8px 12.4px 8px 12.4px",
      borderColor: "#12beab",
    },
    justRight: {
      textAlign: "right",
    },
    smallCardGrid: {
      display: "flex",
      flexDirection: "column",
    },
  }))

  const classes = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className={classes.cardSpace}>
      <div className={props.isBannerHeader ? classes.headerCard : classes.card}>
        <div className={smallScreen ? classes.smallCardGrid : classes.cardGrid}>
          <div
            className={smallScreen ? classes.smallCardIcon : classes.cardIcon}
          >
            {props.icon}
          </div>
          <div className={classes.cardContent}>{props.content}</div>
        </div>
        {props.isBannerHeader && (
          <div className={classes.justRight}>
            <Button
              variant="outlined"
              color="primary"
              className={
                smallScreen ? classes.startButtonWide : classes.startButton
              }
              component="span"
              onClick={() => {
                scrollTo(`#section-1`)
              }}
            >
              Rozpocznij
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export const MyPoleneBanner: React.FC<MyPoleneBannerProps> = (
  props: MyPoleneBannerProps
) => {
  const myPoleneImages = useStaticQuery(graphql`
    {
      greenEnergy: file(relativePath: { eq: "Green_Energy.svg" }) {
        publicURL
      }
      greenHand: file(relativePath: { eq: "Green_Hand.svg" }) {
        publicURL
      }
      dot: file(relativePath: { eq: "Dot.svg" }) {
        publicURL
      }
    }
  `)

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      backgroundImage: "linear-gradient(to top, #1a3e4e, #19a49f)",
      paddingBottom: "40px",
      paddingTop: "48px",
      textAlign: "center",
    },
    lightHeader: {
      fontSize: "24px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "0.32px",
      textAlign: "left",
      color: "#fff",
    },
    contentHeader: {
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "0.64px",
      textAlign: "left",
      color: "#414141",
    },
    contentDots: {
      listStyleImage: `url(${myPoleneImages.dot.publicURL})`,
      "& li": {
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.29,
        letterSpacing: "0.12px",
        textAlign: "justify",
        color: "#414141",
        padding: "6px",
        "& span": {
          fontWeight: "bold",
        },
        "& a": {
          fontWeight: "bold",
          color: "#12beab",
          textDecoration: "none !important",
        },
      },
    },
    contentEmptyDots: {
      listStyleImage: "none",
      listStyleType: "none",
      paddingLeft: "24px",
    },
  }))

  const classes = useStyles()

  const greenHandContent = (
    <div>
      <p className={classes.contentHeader}>
        Moja Polenergia to Elektroniczne Biuro Obsługi w którym zapewniamy:
      </p>
      <ul className={classes.contentDots}>
        <li>Łatwy i szybki dostep do faktur i salda</li>
        <li>
          Możliwość zapoznania się ze szczegółowymi danymi dotyczącymi zużycia
          energii elektrycznej
        </li>
        <li>
          Powiadomienia o wystawieniu faktury i zbliżającym się terminie
          płatności
        </li>
        <li>Szybkie i wygodne płatności elektroniczne</li>
        <li>
          Dostęp do indywidualnie przygotowanych nowych ofert Polenergia
          Dystrybucja
        </li>
      </ul>
    </div>
  )

  const greenEnergyContent = (
    <div>
      <p className={classes.contentHeader}>
        Aby w pełni korzystać z serwisu wystarczy:
      </p>
      <ul className={[classes.contentDots, classes.contentEmptyDots].join(" ")}>
        <li>
          <span>Krok 1:</span> Wejść na stronę{" "}
          <a href="https://moja.polenergia.pl">https://moja.polenergia.pl</a>{" "}
          oraz wybrać opcję <span>"Zarejestruj się"</span>
        </li>
        <li>
          <span>Krok 2:</span> Uzupełnić formularz rejestracyjny
        </li>
        <li>
          <span>Krok 3:</span> Aktywować konto klikając w link, który otrzymasz
          na swój adres e-mail
        </li>
      </ul>
    </div>
  )

  return (
    <div className={classes.container}>
      <label className={classes.lightHeader}>Moja Polenergia</label>
      <MyPoleneCard
        icon={<img src={myPoleneImages.greenHand.publicURL} />}
        content={greenHandContent}
      />
      <MyPoleneCard
        icon={<img src={myPoleneImages.greenEnergy.publicURL} />}
        content={greenEnergyContent}
      />
    </div>
  )
}
