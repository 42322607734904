enum CustomerTabDataField {
  PESEL = "customerTabData.customer.pesel",
  CUSTOMER_NUMBER = "customerTabData.customer.customerNumber",
  FIRST_NAME = "customerTabData.customer.firstName",
  LAST_NAME = "customerTabData.customer.lastName",
  EMAIL = "customerTabData.customer.email",
  PHONE = "customerTabData.customer.phone",
  STREET = "customerTabData.customer.street",
  CITY = "customerTabData.customer.city",
  PASSPORT_NUMBER = "customerTabData.customer.passportNumber",
  RESIDANCE_CARD = "customerTabData.customer.residenceCardNumber",
}

export default CustomerTabDataField
