import React, { useState } from "react"
import {
  makeStyles,
  Theme,
  useMediaQuery,
  Portal,
  Divider,
  Button,
  Fade,
} from "@material-ui/core"
import Card from "@material-ui/core/Card"
import { createStyles, useTheme } from "@material-ui/styles"
import { SuccessModal } from "../successModal/successModal"
import { maxWidth, relativeContentPadding } from "../../styles/constants"
import scrollTo from "gatsby-plugin-smoothscroll"
import { gwarancje } from "./static-data/gwarancje"
import { ErrorModal } from "../errorModal"
import Consumer from "../../context/Context"
import { Alert, AlertTitle } from "@material-ui/lab"
import CheckIcon from "@material-ui/icons/Check"
import Filter1 from "@material-ui/icons/Filter1"
import { ValidationModal } from "../validationModal"
import { SummaryModal } from "../summaryModal"
import { useStaticQuery, graphql } from "gatsby"
import { SmallLogo } from "../images/smallLogo"
import { EDocumentVersion, ELandingPage } from "../../models/OffersEnums"
import { LightsOnBanner } from "./lightsOnBanner"
import { LightsOnForm } from "./lightsOnForm"
import { LightsOnSummary } from "./lightsOnSummary"
import { LightsOnFormCurrentCustomer } from "./lightsOnFormCurrentCustomer"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    simpleContainer: {
      position: "relative",
      ...relativeContentPadding(),
    },
    overlay: {
      zIndex: 0,
    },
    topContainer: {
      ...relativeContentPadding,
    },
    numberContainerOuter: {
      borderRadius: "50%",
      width: "50px",
      height: "50px",
      backgroundColor: "red",
      position: "relative",
    },
    numberContainerInner: {
      borderRadius: "50%",
      width: "46px",
      height: "46px",
      backgroundColor: "white",
      position: "absolute",
      top: "2px",
      left: "2px",
      textAlign: "center",
      "& p": {
        lineHeight: "46px",
      },
    },
    cardsContainer: {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      overflow: "hidden",
      flexDirection: "row",
      paddingBottom: "80px",
      "& .MuiCard-root": {
        flex: 1,
        [theme.breakpoints.down("lg")]: {
          margin: "20px 15px",
        },
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    infoContainer: {
      ...maxWidth,
      display: "flex",
      alignItems: "flex-start",
      overflow: "hidden",
      flexDirection: "row",
      paddingBottom: "80px",
      "& .MuiCard-root": {
        flex: 1,
        [theme.breakpoints.down("lg")]: {
          margin: "20px 15px",
        },
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
      },
      paddingTop: "80px",
    },
    endSection: {
      paddingTop: "100px",
      paddingBottom: "100px",
      margin: 0,
      marginRight: "-30px",
      [theme.breakpoints.down("lg")]: {
        marginRight: "-15px",
      },
      [theme.breakpoints.down("md")]: {
        width: "auto",
        margin: 0,
        maxWidth: "100%",
      },
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    endCard: {
      paddingTop: "60px",
      textAlign: "center",
    },
    endCardBoldP: {
      fontWeight: 700,
      lineHeight: "17px",
      marginBottom: "20px",
    },
    endCardNormalP: {
      lineHeight: "17px",
      marginBottom: "20px",
    },
    formSection: {
      margin: 0,
      marginTop: 30,
      marginRight: "-30px",
      [theme.breakpoints.down("lg")]: {
        marginRight: "-15px",
      },
      [theme.breakpoints.down("md")]: {
        width: "auto",
        margin: 0,
        maxWidth: "100%",
      },
      width: "100%",
      position: "relative",
      ...relativeContentPadding(),
      padding: "0px",
    },
    formButton: {
      position: "fixed",
      bottom: "12px",
      right: "12px",
      width: "65px",
      height: "65px",
      borderRadius: "32.5px",
      zIndex: 999,
    },
    mainCard: {
      flex: "1 1 auto !important",
      width: "100%",
    },
    clickableCard: {
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
    },
    infoSection: {
      display: "flex",
      flexWrap: "wrap",
      flex: "1 1 auto",
      margin: 0,
      marginLeft: "-30px",
      "& h2": {
        textAlign: "center",
        marginBottom: "20px",
        marginTop: 0,
      },
      "& b": {
        marginBottom: "15px",
        display: "block",
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: "-15px",
      },
      [theme.breakpoints.down("md")]: {
        margin: 0,
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        margin: 0,
        "& .MuiCard-root": {
          margin: "20px 0",
          flex: 1,
          width: "100%",
        },
      },
    },
    infoImg: {
      height: "145px",
      marginBottom: "20px",
      borderRadius: "8px",
    },
    endCardContainer: {
      maxWidth: "660px",
      background: "#12BEAB",
    },
    iconContainer: {
      marginBottom: "-50px",
      position: "relative",
      textAlign: "center",
      zIndex: 1,
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
  })
)

export interface ILightsOnContentProps {
  showSummary?: boolean
  setShowSummary?: any
  landingPage: ELandingPage
}

export const LightsOnContent: React.FC<ILightsOnContentProps> = ({
  showSummary,
  setShowSummary,
  landingPage,
}) => {
  const styles = useStyles()

  const [showCalendarModal, setShowCalendarModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [now, setNow] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [errorHeader, setErrorHeader] = useState("")
  const [validationMsg, setValidationMsg] = useState([])
  const [anyMissingData, setAnyMissingData] = useState(false)
  const [showValidationModal, setShowValidationModal] = useState(false)
  const [showSummaryModal, setShowSummaryModal] = useState(false)
  const [documentVersion, setDocumentVersion] = useState(EDocumentVersion.NONE)

  return (
    <div>
      {!showSummary && <LightsOnBanner landingPage={landingPage} />}
      <Consumer>
        {({ showForm, setShowForm }) => (
          <div>
            {showSummary && (
              <LightsOnSummary
                setShowSummary={setShowSummary}
                documentVersion={documentVersion}
              />
            )}
            {!showSummary && (
              <div>
                <ErrorModal
                  show={showErrorModal}
                  toggleModal={() => {
                    setShowErrorModal(false)
                  }}
                  error={errorMsg}
                  header={errorHeader}
                />
                <SuccessModal
                  show={showSuccessModal}
                  toggleModal={() => {
                    setShowSuccessModal(false)
                  }}
                  now={now}
                />
                <ValidationModal
                  show={showValidationModal}
                  toggleModal={() => {
                    setShowValidationModal(false)
                  }}
                  messages={validationMsg}
                  anyMissingData={anyMissingData}
                ></ValidationModal>

                <div className={styles.formSection}>
                  {landingPage === ELandingPage.CURRENT_CUSTOMER ? (
                    <LightsOnFormCurrentCustomer
                      documentVersion={documentVersion}
                      onSucces={(documentVersion: EDocumentVersion) => {
                        setDocumentVersion(documentVersion)
                        setShowSummary(true)
                        setTimeout(() => {
                          scrollTo("#formTop")
                        })
                      }}
                      onError={(errMsg: string, header: string) => {
                        setErrorMsg(errMsg)
                        setErrorHeader(header)
                        setShowSummaryModal(false)
                        setShowErrorModal(true)
                      }}
                      onValidationError={(
                        anyMissingData: boolean,
                        messages: string[]
                      ) => {
                        setValidationMsg(messages)
                        setAnyMissingData(anyMissingData)
                        setShowValidationModal(true)
                      }}
                    />
                  ) : (
                    <LightsOnForm
                      onSucces={(documentVersion: EDocumentVersion) => {
                        scrollTo(`#formTop`)
                        setDocumentVersion(documentVersion)
                        setShowSummary(true)
                      }}
                      onError={(errMsg: string, header: string) => {
                        setErrorMsg(errMsg)
                        setErrorHeader(header)
                        setShowSummaryModal(false)
                        setShowErrorModal(true)
                      }}
                      onValidationError={(
                        anyMissingData: boolean,
                        messages: string[]
                      ) => {
                        setValidationMsg(messages)
                        setAnyMissingData(anyMissingData)
                        setShowValidationModal(true)
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Consumer>
    </div>
  )
}
