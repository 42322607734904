import React, { useState, CSSProperties, useEffect } from "react"
import { Theme, makeStyles } from "@material-ui/core/styles"
import { useFormikContext } from "formik"
import { Transition, animated } from "react-spring"
import PhoneInput, { CountryData } from "react-phone-input-2"
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input"
import "react-phone-input-2/lib/style.css"
import { graphql, useStaticQuery } from "gatsby"

type StylesProps = {
  searchIconUrl: string
}

type GraphQLData = {
  searchIcon: {
    publicURL: string
  }
}

interface PhoneTextFieldProps {
  onClick: () => void
  phoneFieldName: string
  prefixFieldName: string
}

interface IPhoneNumberFormValues {
  customer: {
    phone: string
  }
}

const phoneFieldStyles = ({ searchIconUrl }: StylesProps) =>
  makeStyles((theme: Theme) => ({
    root: {
      "& .react-tel-input .selected-flag": {
        backgroundColor: "transparent !important",
      },
      "& .react-tel-input .search-box": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        border: "none",
        height: "50px",
        padding: "0 0 0 45px",
        borderBottom: "1px solid #D3CFCF",
        fontSize: "13px",
        color: "#3A3A3A",
        marginLeft: 0,
        fontFamily: `"Muli", "Helvetica", "Arial", sans-serif`,
      },
      '& input[type="search"].search-box::-webkit-search-cancel-button': {
        WebkitAppearance: "none",
        height: "19px",
        width: "19px",
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='rgb(72,169,161)' d='M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z'/%3E%3C/svg%3E") no-repeat`,
        backgroundSize: "contain",
        opacity: 1,
        cursor: "pointer",
      },
      '& input[type="search"].search-box::-ms-clear': {
        WebkitAppearance: "none",
        height: "19px",
        width: "19px",
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='rgb(72,169,161)' d='M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z'/%3E%3C/svg%3E") no-repeat`,
        backgroundSize: "contain",
        opacity: 1,
        cursor: "pointer",
      },
      "& .react-tel-input .country-list": {
        top: "40px",
        left: 0,
        overflowX: "hidden",
        borderRadius: "5px",
        "&::-webkit-box-shadow": "0px 2px 3px 0px rgba(0, 0, 0, 0.25)",
        "&::-moz-box-shadow": "0px 2px 3px 0px rgba(0, 0, 0, 0.25)",
        boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.25)",
        fontFamily: `"Muli", "Helvetica", "Arial", sans-serif`,
      },
      "& .react-tel-input .search-emoji": {
        textIndent: "-9999px",
        fontSize: 0,
      },
      "& .react-tel-input .search-emoji::before": {
        content: `url(${searchIconUrl}) !important`,
        position: "absolute !important",
        background: `url(${searchIconUrl}) !important`,
        top: "16px",
        left: "5px",
        height: "19px",
        width: "19px",
        zIndex: 99999,
        marginLeft: "10px",
      },
      "& .react-tel-input li:nth-child(1)": {
        paddingTop: "25px",
      },
      "& .react-tel-input .country-list .country": {
        paddingLeft: "20px",
        fontFamily: `"Muli", "Helvetica", "Arial", sans-serif`,
        fontSize: "13px",
      },
      "& .react-tel-input .country-list .no-entries-message": {
        paddingLeft: "20px",
        color: "#3A3A3A",
        fontFamily: `"Muli", "Helvetica", "Arial", sans-serif`,
        fontSize: "13px",
      },
    },
  }))

const styles: Record<string, CSSProperties> = {
  input: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    height: "54px",
    padding: "0 4px 0 56px",
    border: "2px solid transparent",
    borderRadius: "7px",
    boxShadow: "inset -2px -2px 7px rgba(255, 255, 255, 0.75), inset 1px 1px 5px rgba(61, 61, 61, 0.15)",
    backgroundColor: "#fcfcfc",
    color: "#3A3A3A",
    letterSpacing: "1.16px",
    overflow: "hidden",
    fontSize: "13px",
    fontWeight: 700,
    fontFamily: `"Muli", "Helvetica", "Arial", sans-serif`,
    transition: "all 200ms ease-in-out",
  },
  inputError: {
    border: "2px solid #CC1450",
    padding: "6px 4px 0 56px",
  },
  dropdownButton: {
    padding: "0 0 0 12px",
    borderTop: "2px solid transparent",
    borderLeft: "2px solid transparent",
    borderBottom: "2px solid transparent",
    borderRight: "none",
    borderRadius: "7px 0 0 7px",
    boxShadow: "inset -2px -2px 7px rgba(255, 255, 255, 0.75), inset 1px 1px 5px rgba(61, 61, 61, 0.15)",
    backgroundColor: "#fcfcfc",
    transition: "all 200ms ease-in-out",
  },
  dropdownButtonError: {
    borderTop: "2px solid #CC1450",
    borderLeft: "2px solid #CC1450",
    borderBottom: "2px solid #CC1450",
    borderRight: "none",
    padding: "5px 0 0 12px",
  },
  errorLabel: {
    position: "absolute",
    top: "6px",
    left: "20px",
    color: "#CC1450",
    padding: 0,
    fontSize: "10px",
    boxSizing: "border-box",
    overflow: "hidden",
    margin: 0,
    zIndex: 9999,
    fontFamily: `"Muli", "Helvetica", "Arial", sans-serif`,
    letterSpacing: "1px",
    fontWeight: 400,
    transition: "all 200ms ease-in-out",
  },
}

const PhoneTextField: React.FC<PhoneTextFieldProps> = ({ onClick, phoneFieldName, prefixFieldName }) => {
  const [fieldError, setFieldError] = useState<string>("")
  const { setFieldValue, touched, errors, setFieldTouched } = useFormikContext<IPhoneNumberFormValues>()
  const isError = Boolean(fieldError.length > 0)

  const iconsData = useStaticQuery<GraphQLData>(graphql`
    {
      searchIcon: file(relativePath: { eq: "Search.svg" }) {
        publicURL
      }
    }
  `)

  const phoneStyles = phoneFieldStyles({
    searchIconUrl: iconsData.searchIcon.publicURL,
  })
  const classes = phoneStyles()

  useEffect(() => {
    const errorPath = phoneFieldName.split(".").reduce((acc, part) => acc?.[part], errors)
    const touchedPath = phoneFieldName.split(".").reduce((acc, part) => acc?.[part], touched)

    if (Boolean(touchedPath && errorPath)) {
      setFieldError(String(errorPath))
    } else {
      setFieldError("")
    }
  }, [phoneFieldName, touched, errors])

  const validatePhoneNumber = (phone: string | undefined, prefix: string): string => {
    const phoneWithoutPrefix = phone?.slice(prefix.length)
    if (!!phone && isValidPhoneNumber(phone) && !!phoneWithoutPrefix && phoneWithoutPrefix?.length >= 9) {
      return ""
    } else {
      return "Wpisz poprawny numer telefonu"
    }
  }

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const phone = parsePhoneNumber(event.target.value)?.number.toString()
    const prefix = event.target.value?.split(" ")[0].toString()
    setFieldTouched(phoneFieldName)
    const error = validatePhoneNumber(phone, prefix)
    setFieldError(error)
  }

  const handleOnChange = (phone: string, data: CountryData) => {
    setFieldValue(phoneFieldName, phone.slice(data.dialCode.length))
    setFieldValue(prefixFieldName, `+${data.dialCode}`)
    setFieldTouched(phoneFieldName)

    if (touched.customer?.phone) {
      const phoneWithPrefix = `+${phone}`
      const error = validatePhoneNumber(phoneWithPrefix, data.dialCode)
      setFieldError(error)
    } else {
      setFieldTouched(phoneFieldName, false)
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <Transition
        items={isError}
        from={{
          position: "absolute",
          opacity: 0,
          top: "0px",
        }}
        enter={{ opacity: 1, top: "4px" }}
        leave={{ opacity: 0, top: "0px" }}
      >
        {(values, visible) => {
          return visible && <animated.p style={styles.errorLabel}>{fieldError}</animated.p>
        }}
      </Transition>
      <div className={classes.root}>
        <PhoneInput
          country="pl"
          placeholder="Numer telefonu"
          enableSearch
          countryCodeEditable={false}
          onClick={onClick}
          onBlur={(event) => handleOnBlur(event)}
          onChange={(phone, data: CountryData) => handleOnChange(phone, data)}
          inputStyle={isError ? { ...styles.input, ...styles.inputError } : styles.input}
          buttonStyle={isError ? { ...styles.dropdownButton, ...styles.dropdownButtonError } : styles.dropdownButton}
        ></PhoneInput>
      </div>
    </div>
  )
}

export default PhoneTextField
