import React, { useState } from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  useMediaQuery,
  Divider,
  Grid,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core"
import StyledButton from "./Button"
import { CheckCircle } from "@material-ui/icons"
import { AgreementsRequest } from "../models/OffersInterfaces"
import { EOfferType } from "../models/OffersEnums"
import { ELandingPage } from "../models/OffersEnums"
import { DocumentIcon } from "./controls/DocumentIcon"
import { useEffect } from "react"
import { ContractType } from "./ToggleButtons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      padding: 0,
    },
    title: {
      maxHeight: "0px",
      padding: 0,
      textAlign: "right",
      "& button": {
        top: "-8px",
        right: "-8px",
        position: "relative",
        padding: "4px",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      padding: "10px 0",
      "& h1": {
        margin: 0,
        fontSize: "32px",
        color: "#0E3A35",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "& h2": {
        margin: "12px 0 0",
        fontSize: "20px",
        color: "#0E3A35",
      },
      "& h4": {
        margin: "12px 0",
        fontSize: "16px",
        color: "#656D76",
        opacity: 0.7,
      },
      "& h3": {
        margin: "24px 0 0",
        fontSize: "18px",
        color: "#656D76",
        opacity: 0.85,
      },
      [theme.breakpoints.down("sm")]: {
        "& h1": {
          fontSize: "30px",
        },
        "& h2": {
          fontSize: "18px",
        },
      },
      "& a": {
        textDecoration: "none",
        color: theme.palette.secondary.main,
        transition: "color 0.15s ease-in-out",
        "&:hover": {
          color: theme.palette.primary.dark,
          backgroundColor: "transparent",
        },
      },
    },
    button: {
      display: "block",
      margin: "24px 0 0",
      width: "225px",
      height: "48px",
      fontSize: "17px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        width: "60vw",
      },
    },
    bold: {
      color: "#4F4F4F",
      fontWeight: 700,
    },
    separator: {
      color: "#4F4F4F",
      width: "100%",
      marginTop: "20px",
      marginBottom: "20px",
    },
    summaryDataContainer: {
      fontSize: "20px",
      paddingTop: "22px",
      paddingBottom: "12px",
      "& p:first-child": {
        textAlign: "center",
      },
      "& p": {
        textAlign: "left",
      },
    },
    summaryLine: {
      display: "flex",
      padding: "7px",
      "& p": {
        margin: "0px",
        lineHeight: "24px",
      },
      "& svg": {
        marginRight: "16px",
      },
    },
    summaryButtonsContainer: {
      display: "flex",
    },
    acceptedCheck: {
      textAlign: "left",
    },
    documentSectionHeader: {
      marginTop: "24px",
      marginBottom: "24px",
      textAlign: "center",
      "& p": {
        textAlign: "left",
        fontSize: "31px",
        "& span": {},
      },
      [theme.breakpoints.down("md")]: {
        "& p": {
          // textAlign: "center",
        },
      },
    },
    cardsContainer: {
      display: "flex",
      alignItems: "stretch",
      alignContent: "center",
      flexDirection: "row",
      overflow: "hidden",
      paddingTop: "20px",
      justifyContent: "center",
      "& .MuiCard-root": {
        flex: 1,
        [theme.breakpoints.down("lg")]: {
          margin: "20px 15px",
        },
      },
      [theme.breakpoints.down("md")]: {
        //male
        flexWrap: "wrap",
        alignItems: "center",
        height: "300px",
      },
    },
    offerPaperCard: {
      textAlign: "center",
      height: "100%",
      width: "150px",
      paddingTop: "15px",
      paddingBottom: "0px",
      "& p": {
        fontSize: "16px",
        margin: "0px",
        paddingTop: "14px",
        paddingBottom: "14px",
      },
      [theme.breakpoints.down("md")]: {
        //male
        height: "150px",
      },
    },
    offerHeader: {
      fontSize: "34px;",
      marginTop: "11px",
      marginBottom: "8px",
    },
    offerTitleAccent: {
      color: "#12beab",
    },
    checkbox: {
      paddingTop: "12px",
      paddingBottom: "12px",
    },
    backButton: {
      borderColor: "#12beab",
      color: "#12beab",
      display: "block",
      margin: "24px 0 0",
      width: "225px",
      height: "48px",
      fontSize: "17px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        width: "60vw",
      },
    },
    buttonProgress: {
      color: "#00BDA8",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    loadingButtonWrapper: {
      position: "relative",
      margin: "24px 0 0",
      "& button": {
        margin: "0px",
      },
    },
  })
)

interface Props {
  landingPage?: ELandingPage
  show: boolean
  toggleModal: () => void
  togglePrompt: () => void
  data?: AgreementsRequest
  confirmForm: () => void
}

export const SummaryModal: React.FC<Props> = ({ show, toggleModal, data, togglePrompt, confirmForm, landingPage }) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  // let documents = landingPage === ELandingPage.CURRENT_CUSTOMER
  //   ? currentCustomerOfferData[0].documents
  //   : newCustomerOfferData[0].documents

  let initialDocument = [
    {
      name: "",
      path: "",
    },
  ]

  const [acceptedFirstCheckbox, setAcceptedFirstCheckbox] = useState(false)
  const [acceptedSecondCheckbox, setAcceptedSecondCheckbox] = useState(false)
  const [acceptedThirdCheckbox, setAcceptedThirdCheckbox] = useState(false)
  const [document, setDocument] = useState(initialDocument)
  const [isFormSending, setIsFormSending] = useState(false)

  const handleChangeFirstCheckbox = (event) => {
    setAcceptedFirstCheckbox(!acceptedFirstCheckbox)
  }
  const handleChangeSecondCheckbox = (event) => {
    setAcceptedSecondCheckbox(!acceptedSecondCheckbox)
  }
  const handleChangeThirdCheckbox = (event) => {
    setAcceptedThirdCheckbox(!acceptedThirdCheckbox)
  }

  const checkOfferDocuments = () => {
    let document = [
      {
        name: "",
        path: "",
      },
    ]
    if (data) {
      switch (data.customer.customerType) {
        case ContractType.INDIVIDUAL:
          document[0] = {
            name: "Umowa - konsument",
            path: "/UMOWA-siec_POLD-Komplex-konsument.pdf",
          }
          document[1] = {
            name: "OWU",
            path: "/OWU-siec_POLD_kompleks_G-konsum_dzialalnosc.pdf",
          }
          document[2] = {
            name: "Wybór oferty - konsument",
            path: "/Wybor_oferty_zgody_RODO_klauzula-siec_POLD_G_konsument.pdf",
          }
          break
        case ContractType.COMMERCIAL_JDG:
          document[0] = {
            name: "Umowa - działalność",
            path: "/UMOWA-siec_POLD-Komplex-dzialalnosc.pdf",
          }
          document[1] = {
            name: "OWU",
            path: "/OWU-siec_POLD_kompleks_G-konsum_dzialalnosc.pdf",
          }
          document[2] = {
            name: "Wybór oferty - działalność",
            path: "/Wybor_oferty_zgody_RODO_klauzula-siec_POLD_G_dzialalnosc.pdf",
          }
          break
        case ContractType.COMMERCIAL_WITH_PREMISES:
          // TODO: Przypisać odpowiednie pliki / zmienić ścieżki
          document[0] = {
            name: "Umowa - komercyjny",
            path: "/UmowaKompleksowaKlientBiznesowykoncesjaCXorazkomercyjneGX.pdf",
          }
          document[1] = {
            name: "OWU",
            path: "/OgolneWarunkiUmowybizkompleks.pdf",
          }
          document[2] = {
            name: "Cennik Sprzedawcy",
            path: "/", // X
          }
      }
      switch (data.offer.offerType) {
        case EOfferType.STANDARD:
          document.push({
            name: "Taryfa Polenergia Dystrybucja",
            path: "/Taryfa_PolenergiaDystrybucja.pdf",
          })
          break
      }
      if (data.customer.customerType !== ContractType.COMMERCIAL_WITH_PREMISES) {
        document.push({
          name: "Zbiór praw konsumenta",
          path: "/Zbior_praw_konsumenta_energii_elektrycznej__wersja_kolorowa_.pdf",
        })
        document.push({
          name: "Odstąpienie od umowy",
          path: "/Odstąpienie_od_umowy.pdf",
        })
      }
      return document
    }
  }

  useEffect(() => {
    async function getDocuments() {
      const promise = await checkOfferDocuments()
      if (promise) {
        setDocument(promise)
      }
    }
    getDocuments()
  }, [data])

  const getOfferName = () => {
    if (data) {
      switch (data.offer.offerType) {
        case EOfferType.STANDARD:
          return data.customer.customerType === ContractType.COMMERCIAL_WITH_PREMISES
            ? "Taryfa Sprzedawcy, Cennik Sprzedawcy"
            : "Taryfa Sprzedawcy"
      }
    } else {
      return "Brak"
    }
  }

  const checkBoxTopAlign = {
    alignItems: "start",
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-describedby="form-modal"
      open={show}
      onClose={toggleModal}
      scroll="body"
      fullWidth
      maxWidth="md"
      className={classes.contentWrapper}
      PaperProps={{
        style: { borderRadius: 16 },
      }}
    >
      <DialogTitle className={classes.title} />
      <DialogContent className={classes.content}>
        <h1>Podsumowanie</h1>

        <div className={classes.summaryDataContainer}>
          {/* <p>
            <span className={classes.bold}>Grupa taryfowa: </span>G11
          </p> */}
          <div className={classes.summaryLine}>
            <CheckCircle style={{ color: "#12BEAB" }} />
            <p>
              <span className={classes.bold}>Umowa: </span>Kompleksowa sprzedaży energii elektrycznej i świadczenia
              usług dystrybucji.
            </p>
          </div>
          <div className={classes.summaryLine}>
            <CheckCircle style={{ color: "#12BEAB" }} />
            <p>
              <span className={classes.bold}>Okres obowiązywania umowy: </span>
              Czas nieokreślony
            </p>
          </div>
          <div className={classes.summaryLine}>
            <CheckCircle style={{ color: "#12BEAB" }} />
            <p>
              <span className={classes.bold}>
                Cennik {data?.customer.customerType === ContractType.COMMERCIAL_WITH_PREMISES ? "" : "produktowy"}:{" "}
              </span>
              {getOfferName()}
            </p>
          </div>
        </div>

        <Divider className={classes.separator} />
        <h2>Dokumenty dotyczące oferty</h2>
        <Grid container justify="center">
          <div className={classes.cardsContainer}>
            {document.map((document) => {
              return (
                <div className={classes.offerPaperCard}>
                  <a target="_blank" key={document.path} href={document.path}>
                    <DocumentIcon />
                  </a>
                  <p>{document.name}</p>
                </div>
              )
            })}
          </div>
        </Grid>

        <Divider className={classes.separator} />

        <div className={classes.checkbox}>
          {data?.customer.customerType !== ContractType.COMMERCIAL_WITH_PREMISES && (
            <>
              <FormControlLabel
                className={classes.acceptedCheck}
                style={checkBoxTopAlign}
                control={
                  <Checkbox
                    checked={acceptedFirstCheckbox}
                    name={"acceptedCheck"}
                    value={true}
                    onChange={handleChangeFirstCheckbox}
                  />
                }
                label={
                  "Wyrażam zgodę na rozpoczęcie dostaw energii elektrycznej zgodnie z zapisami Umowy przed upływem terminu na odstąpienie od Umowy, oraz potwierdzam, że wiąże się to z obowiązkiem zapłaty za dostarczoną energię elektryczną do dnia ewentualnego odstąpienia."
                }
              />
              <p></p>
            </>
          )}
          <FormControlLabel
            className={classes.acceptedCheck}
            style={checkBoxTopAlign}
            control={
              <Checkbox
                checked={acceptedSecondCheckbox}
                name={"acceptedCheck"}
                value={true}
                onChange={handleChangeSecondCheckbox}
              />
            }
            label={
              "Oświadczam, że zapoznałam/zapoznałem się z ofertą oraz prezentowanymi dokumentami, dane zawarte w formularzu są zgodne ze stanem faktycznym. Wyrażam zgodę na przesłanie dokumentów na mój adres e-mail."
            }
          />
          <p></p>
          <FormControlLabel
            className={classes.acceptedCheck}
            style={checkBoxTopAlign}
            control={
              <Checkbox
                checked={acceptedThirdCheckbox}
                name={"acceptedCheck"}
                value={true}
                onChange={handleChangeThirdCheckbox}
              />
            }
            label={
              <div>
                Oświadczam, że zapoznałam/zapoznałem się z informacją o możliwej aktualizacji danych.
                <br />
                Jeśli jesteś już naszym Klientem i wprowadzone przez Ciebie dane w formularzu różnią się od danych w
                naszych systemach, Twoje dotychczasowe dane takie jak: adres e-mail Klienta, numer telefonu, adres
                korespondencyjny oraz adres{" "}
                {data?.customer.customerType === ContractType.COMMERCIAL_WITH_PREMISES
                  ? "siedziby"
                  : "zamieszkania"}{" "}
                zostaną zaktualizowane. Zanim przejdziesz do kolejnego kroku sprawdź uważnie wszystkie wprowadzone
                informacje.
              </div>
            }
          />
        </div>
        <div className={classes.summaryButtonsContainer}>
          <StyledButton color="primary" variant="outlined" onClick={() => toggleModal()} className={classes.backButton}>
            Powrót
          </StyledButton>
          &nbsp; &nbsp; &nbsp;
          <div className={classes.loadingButtonWrapper}>
            <StyledButton
              disabled={
                !(
                  (data?.customer.customerType === ContractType.COMMERCIAL_WITH_PREMISES
                    ? true
                    : acceptedFirstCheckbox) &&
                  acceptedSecondCheckbox &&
                  acceptedThirdCheckbox
                ) || isFormSending
              }
              color="primary"
              variant="contained"
              onClick={() => {
                togglePrompt()
              }}
              className={classes.button}
            >
              Zatwierdź
            </StyledButton>
            {isFormSending && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
