enum ComercialTabDataField {
  TAX_NUMBER = "customerTabData.comercial.taxNumber",
  CUSTOMER_NUMBER = "customerTabData.comercial.customerNumber",
  COMPANY_NAME = "customerTabData.comercial.companyName",
  FIRST_NAME = "customerTabData.comercial.firstName",
  LAST_NAME = "customerTabData.comercial.lastName",
  EMAIL = "customerTabData.comercial.email",
  PHONE = "customerTabData.comercial.phone",
  STREET = "customerTabData.comercial.street",
  CITY = "customerTabData.comercial.city",
}

export default ComercialTabDataField
