import React from "react"
import { RadioGroup } from "@material-ui/core"

const RadioButtonsGroup = ({
  field,
  form: { touched, errors },
  children,
  documentType,
  setValue,
  className,
  horizontal,
  ...props
}) => {
  const fieldName = field.name

  const handleChange = event => {
    setValue(event.target.value)
  }

  if (horizontal) {
    return (
      <React.Fragment>
        <RadioGroup
          {...field}
          {...props}
          name={fieldName}
          value={documentType}
          row
          onChange={handleChange}
          className={className}
        >
          {children}
        </RadioGroup>
        {touched[fieldName] && errors[fieldName] && (
          <span style={{ color: "red", fontFamily: "sans-serif" }}>
            {errors[fieldName]}
          </span>
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <RadioGroup
        {...field}
        {...props}
        name={fieldName}
        value={documentType}
        onChange={handleChange}
        className={className}
      >
        {children}
      </RadioGroup>
      {touched[fieldName] && errors[fieldName] && (
        <span style={{ color: "red", fontFamily: "sans-serif" }}>
          {errors[fieldName]}
        </span>
      )}
    </React.Fragment>
  )
}

export default RadioButtonsGroup
