import React, { useRef, useEffect, useState } from "react"
import { Formik, Form, Field } from "formik"
import { useTheme } from "@material-ui/styles"
import {
  makeStyles,
  Grid,
  Theme,
  Button,
  Portal,
  FormControlLabel,
  Radio,
  Divider,
  Card,
  Collapse,
  IconButton,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@material-ui/core"
import { CustomInput } from "../Input"
import { StyledButton } from "../Button"
import { OfferDurationToggleButtons, ToggleButtons } from "../ToggleButtons"
import { CustomCheckbox } from "../CheckBox"
import { FormTypes, placeholdersDict, FormID } from "./static-data/formularz"
import { currentCustomerOfferData } from "./static-data/currentCustomerOffer"
import {
  CustomerDataResponse,
  submitRenewalAgreements,
  getCustomerDataByToken,
} from "../../api/wlaczPradForm"
import { greyColor, maxWidth } from "../../styles/constants"
import scrollTo from "gatsby-plugin-smoothscroll"
import ReCAPTCHA from "react-google-recaptcha"
import { trackCustomEvent } from "../../utils/analytics"
import { Input } from "@material-ui/icons"
import { useStaticQuery, graphql } from "gatsby"
import { SummaryModal } from "../summaryModal"
import { DocumentModal } from "../documentModal"
import OfferCard from "../controls/OfferCard"
import ServiceCard from "../controls/ServiceCard"
import RadioButtonsGroup from "../controls/RadioButtonsGroup"
import { consentFileds } from "./static-data/lightsOnConsents"
import {
  getBasicInfoFieldsForCurrentCustomer,
  getComercialTabData,
  getCustomerTabData,
  getOfferDataFieldsForCurrentCustomer,
} from "./static-data/lightsOnFields"
import {
  checkServiceType,
  checkStatementType,
  isValidPesel,
} from "../../utils/lightsOnFormUtils"
import { AddressCheckboxCopy } from "../controls/AddressCheckboxCopy"
import { EmailAddressCheckboxCopy } from "../controls/EmailAddressCheckboxCopy"
import { CorrespondenceAddressCheckboxCopy } from "../controls/CorrespondenceAddressCheckboxCopy"
import {
  EDocumentVersion,
  EDocumentType,
  EOfferType,
  EServiceType,
  EServiceOptionType,
  EAddressType,
  ECorrespondenceAddressType,
  EStatementType,
  EOwnershipType,
  EOwnershipSourceType,
  EEnergyDestinationType,
  EJdgEnergyDestinationType,
  EEInvoiceType,
  EEInvoiceAddressType,
  EAggreeType,
  EWallBoxType,
  EFormTypes,
} from "../../models/OffersEnums"
import { AgreementsRequest } from "../../models/OffersInterfaces"
import { EPlugInType } from "../../models/OffersEnums"
import { LpTabs } from "../tabs/LpTabs"
import { CustomInputField } from "../forms/CustomInputField"
import CustomerTabDataField from "./static-data/formUtils/CustomerTabDataField"
import GetCustomerTabDataRequest from "./static-data/formUtils/GetCustomerTabDataRequest"
import CustomerRequestFactory from "./static-data/formUtils/CustomerRequestFactory"
import GetComercialTabDataRequest from "./static-data/formUtils/GetComercialTabDataRequest"
import { CustomerType } from "../../models/CustomerType"
import { ConsentPanel } from "./consentPanel"
import { SectionHeader } from "./sectionHeader"
import { MyPoleneBanner } from "./myPoleneBanner"
import { ScrollToButton } from "../controls/ScrollToButton"
import BackgroundImage from "gatsby-background-image"
import { LightsOnSummary } from "./lightsOnSummary"
import { ELandingPage } from "../../models/OffersEnums"
import { getTokenFromURL } from "../../utils/getTokenFromURL"
import { FormModal } from "../formModal/formModal"
import { DateRange } from "../meetingModal/datePicker/hourPicker/hourPicker"
import Consumer from "../../context/Context"
import { SuccessModal } from "../successModal/successModal"
import { MeetingModal } from "../meetingModal/meetingModal"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import { ErrorModal } from "../errorModal/errorModal"

let dateResolver = null
let dateReject = null

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    "& h1": {
      alignSelf: "flex-start",
      margin: 0,
      fontSize: "31px",
      letterSpacing: "1.05px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& h2": {
      alignSelf: "flex-start",
      color: greyColor,
      margin: 0,
      fontSize: "14px",
      letterSpacing: "0.10px",
      fontWeight: theme.typography.fontWeightRegular,
    },
    width: "100%",
  },
  recaptcha: {
    position: "fixed",
    top: "25%",
    left: "50%",
    zIndex: 1600,
    [theme.breakpoints.down("sm")]: {
      "& .grecaptcha-badge": {
        bottom: "5px !important",
      },
    },
  },
  formComponent: {
    width: "100%",
  },
  gridPaddingLeft: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px",
    },
    paddingLeft: "12px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      marginBottom: "4px",
    },
  },
  gridPaddingRight: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px",
    },
    paddingRight: "12px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      marginBottom: "4px",
    },
  },
  checkboxesSectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#465B71",
    marginTop: "0px",
    marginBottom: "26px",
    "& p": {
      fontSize: "11px",
      letterSpacing: "0.3px",
      margin: 0,
    },
    "& > *": {
      display: "flex",
      alignItems: "center",
      margin: "16px 0 0",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#465B71",
    marginTop: "12px",
    "& p": {
      fontSize: "11px",
      letterSpacing: "0.3px",
      margin: 0,
    },
    "& > *": {
      display: "flex",
      alignItems: "center",
      margin: "16px 0 0",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
    marginBottom: "26px",
  },
  label: {
    alignItems: "flex-start",
    "& p": {
      marginLeft: "12px",
    },
  },
  fileAdding: {
    margin: "0px 0 0",
    "& p": {
      marginLeft: "16px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "16px 0 0",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "16px",
      "& p": {
        margin: "8px 0 0",
        textAlign: "center",
      },
    },
  },
  fileButton: {
    minWidth: "126px",
    height: "37px",
    borderRadius: "7px",
    padding: 0,
    fontSize: "12px",
    letterSpacing: "0.22px",
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      minWidth: "206px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "170px",
    },
  },
  buttonIntro: {
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "20px",
    paddingRight: "20px",
    minWidth: "126px",
    height: "37px",
    borderRadius: "7px",
    borderSize: "2.5px",
    padding: 0,
    fontSize: "12px",
    letterSpacing: "0.22px",
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("sm")]: {
      minWidth: "206px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "170px",
    },
  },
  fillPpeButton: {
    width: "100%",
    paddingLeft: "24px",
    paddingRight: "24px",
    borderRadius: "24px",
    borderWidth: "2.5px !important",
    fontSize: "14px",
    letterSpacing: "0.56px",
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      minWidth: "206px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "170px",
    },
    padding: "8px 12.4px 8px 12.4px",
    borderColor: "#12beab",
  },
  buttonsContainer: {
    ...maxWidth,
    display: "block",
    margin: "0px auto",
    justifyContent: "right",
    marginTop: "40px",
    marginBottom: "64px",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      paddingRight: "10px",
      paddingLeft: "10px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "20px auto",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "20px auto",
      flexDirection: "column",
    },
    "& button": {
      "&:first-child": {
        minWidth: "201px",
        [theme.breakpoints.down("xs")]: {
          minWidth: "85%",
          margin: "10px 0",
        },
      },
      "&:last-child": {
        minWidth: "268px",
        [theme.breakpoints.down("xs")]: {
          minWidth: "85%",
          margin: "10px 0",
        },
      },
      height: "46px",
      lineHeight: "normal",
    },
  },
  consentRequired: {
    color: theme.palette.error.main,
  },
  fileSizeError: {
    color: theme.palette.error.main,
    display: "block",
  },
  formValidationError: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    padding: "0 12px",
    borderRadius: "24px",
    backgroundColor: theme.palette.error.main,
    color: "white",
    width: "78%",
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      fontSize: "10.5px",
    },
    "& p": {
      margin: 0,
      marginLeft: "4px",
    },
  },
  footer: {
    margin: 0,
    color: "#465B71",
    textAlign: "center",
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.main,
      transition: "color 0.15s ease-in-out",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  },
  privacy: {
    margin: "12px 0 0",
    color: "black",
    textAlign: "center",
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      margin: "16px 0 4px",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
      transition: "color 0.15s ease-in-out",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  },
  dividerPadding: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  maxRadioGroupWidth: {
    width: "100%",
  },
  defaultRadioGroup: {},
  maxWidthCardContainer: {
    maxWidth: "1100px",
    margin: "auto",
  },
  cardsContainer: {
    display: "flex",
    alignItems: "flex-start",
    overflow: "hidden",
    flexDirection: "row",
    marginBottom: "40px",
    "& .MuiCard-root": {
      flex: 1,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  backgroundCardsContainer: {
    display: "flex",
    alignItems: "flex-start",
    overflow: "hidden",
    flexDirection: "row",
    "& .MuiCard-root": {
      flex: 1,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  offerCard: {
    textAlign: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    height: "100%",
    backgroundColor: "#f8f8f8",
    paddingTop: "0px",
    offerLinkButton: {},
  },
  serviceCard: {
    textAlign: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    height: "100%",
    backgroundColor: "#fff",
    margin: "auto",
  },
  serviceCardDisabled: {
    textAlign: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    opacity: "0.7",
    height: "100%",
    backgroundColor: "#fff",
    margin: "auto",
    transform: "scale(0.8)",
  },
  offerTitleAccent: {
    color: "#12beab",
  },
  offerHeader: {
    fontSize: "34px;",
    marginTop: "11px",
    marginBottom: "0px",
    paddingRight: "16px",
    paddingLeft: "16px",
    minHeight: "100px",
  },
  offerSubHeader: {
    color: "#656d76",
    paddingBottom: "35px",
    margin: "0px",
    fontWeight: "bold",
    paddingRight: "16px",
    paddingLeft: "16px",
  },
  offerCardDisabled: {
    textAlign: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    opacity: "0.7",
    height: "100%",
    backgroundColor: "#f8f8f8",
    paddingTop: "0px",
  },
  sectionHeader: {
    textAlign: "center",
    "& p": {
      display: "inline-block",
      fontSize: "30px",
      margin: "0px",
    },
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  documentSectionHeader: {
    marginTop: "24px",
    marginBottom: "24px",
    textAlign: "center",
    "& p": {
      fontSize: "30px",
      "& span": {},
    },
  },
  serviceBoxesContainer: {
    paddingLeft: "50px",
    paddingBottom: "44px",
    marginBottom: "11px",
    paddingTop: "11px",
  },
  servicePlaceholderContainer: {
    paddingTop: "44px",
    paddingBottom: "44px",
    marginBottom: "11px",
  },
  offerLinkButton: {
    position: "absolute",
    width: "100%",
    bottom: "0px",
    left: "0px",
  },
  cardButtonPadding: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  offerPaperCard: {
    textAlign: "center",
    height: "100%",
    "& p": {
      fontSize: "22px",
      margin: "0px",
      paddingTop: "14px",
    },
  },
  offerPaperCardSmall: {
    textAlign: "center",
    paddingTop: "14px",
    paddingBottom: "14px",
    backgroundColor: "#fff",
    "& p": {
      fontSize: "14px",
      margin: "0px",
      paddingTop: "8px",
      fontWeight: 700,
      paddingRight: "16px",
      paddingLeft: "16px",
      minHeight: "50px",
    },
  },
  offerDurationLabel: {
    textAlign: "center",
    margin: "0px",
    fontSize: "20px",
  },
  sectionHeaderWrapper: {
    display: "inline-flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      "& p": {
        display: "block",
      },
    },
    "& svg": {
      height: "44px",
      width: "44px",
    },
  },
  cardTop: {
    backgroundColor: "#fff",
    paddingTop: "30px",
  },
  wpInforAlertContainer: {
    backgroundColor: "#12BEAB",
    marginBottom: "32px",
    "& p": {
      paddingTop: "11px",
      paddingBottom: "11px",
      paddingLeft: "25px",
      paddingRight: "25px",
      margin: "0px",
      color: "#fff",
      fontWeight: "600",
      fontSize: "17px",
      lineHeight: "18px",
    },
  },
  wpOfferDurationContainer: {
    paddingBottom: "0px",
    paddingTop: "40px",
  },
  wpFirstFormCollapsePadding: {
    paddingBottom: "16px",
  },
  policyContainer: {
    textAlign: "right",
    fontSize: "11px",
    margin: "0px",
    paddingTop: "0px",
    "& a": {
      textDecoration: "none",
      color: "#000",
      fontWeight: 700,
    },
  },
  tooltipHeader: {
    display: "flex",
  },
  documentsFooter: {
    backgroundColor: "#F2F2F2",
  },
  expandMoreContainer: {
    textAlign: "center",
    paddingTop: "40px",
  },
  innerLink: {
    color: "#fff",
  },
  emailLabel: {
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 600,
    color: "#1E1D1D",
    margin: "0px",
  },
  formSubeHeader: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 600,
    color: "#1E1D1D",
  },
  labelPlacement: {
    textAlign: "left",
  },
  formSubHeader: {
    fontSize: "11px",
    color: "#465B71",
    fontWeight: 400,
  },
  afterSectionHeader: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
  },
  root: {
    display: "flex",
    padding: "0px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    paddingLeft: "50px",
    paddingRight: "50px",
    "& img": {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
    "& button": {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
  },
  cover: {
    width: 500,
    [theme.breakpoints.down("md")]: {
      width: 0,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  justRight: {
    textAlign: "right",
  },
  scrollButtonContainer: {
    textAlign: "center",
  },
  scrollButtonContainerMoreSpace: {
    textAlign: "center",
    paddingTop: "20px",
  },
  wallboxImg: {
    width: "100%",
  },
  wallboxDescription: {
    color: "#465B71",
    fontSize: "11px",
    fontWeight: 400,
  },
  invoiceImg: {
    border: "1px solid #12beab",
    maxWidth: "200px",
    cursor: "pointer",
  },
  invoiceImgContainer: {
    textAlign: "center",
  },
  buttonProgress: {
    color: "#00BDA8",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  loadingButtonWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  tabCard: {
    padding: 0,
  },
  headerImage: {
    width: "100%",
    backgroundPosition: "bottom center",
    backgroundRepeat: "repeat-y",
    backgroundSize: "cover",
  },
  serviceBackground: {
    paddingBottom: "53px",
    paddingTop: "53px",
  },
  formSection: {
    minWidth: "600px",
    maxWidth: "610px",
    margin: 0,
    marginRight: "-30px",
    [theme.breakpoints.down("lg")]: {
      marginRight: "-15px",
    },
    [theme.breakpoints.down("md")]: {
      width: "auto",
      margin: 0,
      maxWidth: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  formButton: {
    position: "fixed",
    bottom: "12px",
    right: "12px",
    width: "65px",
    height: "65px",
    borderRadius: "32.5px",
    zIndex: 999,
  },
  textNoPesel: {
    textAlign: "left",
    fontWeight: theme.typography.fontWeightBold,
    width: "100%",
    marginTop: "-32px",
    marginBottom: "9px",
    marginLeft: "2.5%",
  },
  textNoPeselLink: {
    color: "#00BDA8",
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

export interface LightsOnFormCurrentCustomerProps {
  setShowSummary?: any
  documentVersion?: EDocumentVersion
  showSummary?: boolean
  onSucces: (documentVersion: EDocumentVersion) => void
  onError: (err?: string, header?: string) => void
  onValidationError: (missingData: boolean, messages: string[]) => void
}

export const LightsOnFormCurrentCustomer: React.FC<LightsOnFormCurrentCustomerProps> = ({
  documentVersion,
  showSummary,
  setShowSummary,
  onSucces,
  onError,
  onValidationError,
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const recaptchaRef = useRef<any>()
  const noPeselHelperText = "Nie masz numeru PESEL? "
  const noPeselHelperLink = "Uzupełnij formularz kontaktowy"

  var documentType: EDocumentType = EDocumentType.PESEL
  const documentNumberValidation = el => {
    switch (documentType) {
      case EDocumentType.PESEL:
        return !isValidPesel(el)
      case EDocumentType.PASSPORT:
        return !/^(?!^0+$)[a-zA-Z0-9]{6,9}$/i.test(el)
    }
    return el.length < 2
  }

  const [showDocumentModal, setShowDocumentModal] = useState(false)
  const [documentModalSource, setDocumentModalSource] = useState("invoice")
  const [showSummaryModal, setShowSummaryModal] = useState(false)
  const [offerData, setOfferData] = useState(null)
  const [isFormReady, setIsFormReady] = useState(false)
  const [unableToDownloadData, setUnableToDownloadData] = useState(false)

  const [customerTabDataLoading, setCustomerTabDataLoading] = useState(false)
  const [comercialTabDataLoading, setComercialTabDataLoading] = useState(false)

  const [showContactComponent, setShowContactComponent] = useState(false)
  const [showCalendarModal, setShowCalendarModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [now, setNow] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [isPeselHelperVisible, setIsPeselHelperVisible] = useState(true)

  const [
    individualCustomerDataLoaded,
    setIndividualCustomerDataLoaded,
  ] = useState(false)
  const [
    comercialCustomerDataLoaded,
    setComercialCustomerDataLoaded,
  ] = useState(false)

  const [
    individualCustomerDataLoadedFromToken,
    setIndividualCustomerDataLoadedFromToken,
  ] = useState(false)
  const [
    comercialCustomerDataLoadedFromToken,
    setComercialCustomerDataLoadedFromToken,
  ] = useState(false)

  const [toggleFormikFormInputs, setToggleFormikFormInputs] = useState(
    CustomerType.INDIVIDUAL_CUSTOMER
  )

  const validateField = (value, field: FormTypes) => {
    if (field.required && !value) {
      return field.errorEmpty
    }

    if (value && field.validation && field.validation(value)) {
      return field.errorMsg
    }
  }

  const getCalendarDataPromise = () => {
    setShowCalendarModal(true)
    return new Promise<DateRange>((resolve, reject) => {
      dateResolver = resolve
      dateReject = reject
    })
  }
  const submitDate = (value: DateRange) => {
    dateResolver(value)
  }

  useEffect(() => {
    trackCustomEvent({
      category: "Wysłanie formularza",
      action: `Użytkownik wyświetlił formularz`,
    })
  }, [])

  const fillForm = response => {
    if (response.accountType === "Commercial") {
      setToggleFormikFormInputs(CustomerType.COMERCIAL_CUSTOMER)
      setComercialCustomerDataLoadedFromToken(true)
      setComercialCustomerDataLoaded(true)
      setIsPeselHelperVisible(false)
      currentCustomerOfferData[0].customerTabData.comercial.customerNumber =
        response.customerNumber
      currentCustomerOfferData[0].customerTabData.comercial.companyName =
        response.companyName
      currentCustomerOfferData[0].customerTabData.comercial.firstName =
        response.firstName
      currentCustomerOfferData[0].customerTabData.comercial.lastName =
        response.lastName
      currentCustomerOfferData[0].customerTabData.comercial.email =
        response.email
      currentCustomerOfferData[0].customerTabData.comercial.phone =
        response.phoneNumber
      currentCustomerOfferData[0].customerTabData.comercial.street =
        response.addressLine1
      currentCustomerOfferData[0].customerTabData.comercial.city =
        response.addressLine2
    }
    if (response.accountType === "Individual") {
      setIndividualCustomerDataLoadedFromToken(true)
      setIndividualCustomerDataLoaded(true)
      currentCustomerOfferData[0].customer.commercialClient = false
      currentCustomerOfferData[0].customerTabData.customer.customerNumber =
        response.customerNumber
      currentCustomerOfferData[0].customerTabData.customer.firstName =
        response.firstName
      currentCustomerOfferData[0].customerTabData.customer.lastName =
        response.lastName
      currentCustomerOfferData[0].customerTabData.customer.email =
        response.email
      currentCustomerOfferData[0].customerTabData.customer.phone =
        response.phoneNumber
      currentCustomerOfferData[0].customerTabData.customer.street =
        response.addressLine1
      currentCustomerOfferData[0].customerTabData.customer.city =
        response.addressLine2
    }
  }

  async function fetchDataUsingToken() {
    try{
    const getUserToken = getTokenFromURL()
    if (getUserToken) {
      const userData = await getCustomerDataByToken(getUserToken)
      fillForm(userData)
    }
    }
    catch(error){
      console.log(error)
    }
    finally{
      setIsFormReady(true)
    }
  }

  useEffect(() => {
    fetchDataUsingToken()
  }, [])

  const submitCore = async (values: AgreementsRequest) => {
    const captcha = await recaptchaRef.current.executeAsync()

    if (!captcha) {
      onError("Nie została wykonana autoryzacja z użyciem reCaptcha")
      return
    }

    try {
      await submitRenewalAgreements({
        captcha,
        formValue: { ...values },
      })

      trackCustomEvent({
        category: "Wysłanie formularza",
        action: "Zgłoszenie zostało przyjęte poprawnie",
      })
      recaptchaRef.current.reset()
      onSucces(EDocumentVersion.NONE)
    } catch (e) {
      trackError(e)
      recaptchaRef.current.reset()

      onError(
        e.status === 400
          ? "Nie udało się pobrać danych. Upewnij się, że podane informacje są poprawne."
          : ""
      )

      setShowSummaryModal(false)
    }
  }

  const openSummaryModal = async (
    values,
    errors,
    setSubmitting,
    onValidationError,
    now: boolean = true
  ) => {
    trackCustomEvent({
      category: "Wysłanie formularza",
      action: "Wysłanie formularza",
    })

    const anyMissingData: boolean = Object.keys(errors).length > 0
    const additionalMessages: string[] = []

    if (values.customer.commercialClient) {
      const serviceTypeMessage: string = checkServiceType(
        values.service.serviceType
      )
      if (serviceTypeMessage) {
        additionalMessages.push(serviceTypeMessage)
        errors["JDG1"] = serviceTypeMessage
      }

      const statementTypeMessage: string = checkStatementType(
        values.offerData.statementType
      )
      if (statementTypeMessage) {
        additionalMessages.push(statementTypeMessage)
        errors["JDG2"] = statementTypeMessage
      }
    }

    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(key => {
        trackCustomEvent({
          category: "Błąd walidacji formularza",
          action: "Błędnie wypełnione pole",
          label: `${placeholdersDict[key]} - ${errors[key]}`,
        })
      })
      onValidationError(anyMissingData, additionalMessages)
    } else {
      setSubmitting(false)
      setOfferData(values)
      setShowSummaryModal(true)
    }
  }

  const trackError = e => {
    if (!e) {
      return
    }

    switch (e.status) {
      case 400:
        Object.keys(e.data).forEach(key => {
          trackCustomEvent({
            category: "Błąd serwera",
            action: "Niepoprawna walidacja danych",
            label: `${key} - ${e.data[key].join(", ")}`,
          })
        })
        return
      default:
        trackCustomEvent({
          category: "Błąd serwera",
          action: `Kod błędu serwera - ${e.status}`,
        })
    }
  }

  const getCustomerTab = (onClick: () => void, dataFilled: boolean) => {
    const customerTabData: FormTypes[] = getCustomerTabData({
      documentNumberValidation,
      isValidPesel,
    })

    const fillDataButton = (
      <Grid item xs={12} md={3}>
        <div className={classes.loadingButtonWrapper}>
          <Button
            disabled={customerTabDataLoading}
            variant="outlined"
            color="primary"
            className={classes.fillPpeButton}
            component="span"
            onClick={onClick}
          >
            Pobierz dane
          </Button>
          {customerTabDataLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Grid>
    )

    const inputs: JSX.Element = (
      <div style={{ marginBottom: "12px" }}>
        <Grid container alignItems="center" spacing={2}>
          {[customerTabData[0], customerTabData[1]].map(fieldConfig => {
            return (
              <CustomInputField
                formData={fieldConfig}
                validateField={validateField}
                key={fieldConfig.name}
              />
            )
          })}
          {fillDataButton}
        </Grid>
      </div>
    )

    const forms: JSX.Element = (
      <div>
        <Grid container alignItems="center" spacing={2}>
          {customerTabData.slice(2, 8).map(fieldConfig => {
            return (
              <CustomInputField
                formData={fieldConfig}
                validateField={validateField}
                key={fieldConfig.name}
              />
            )
          })}
        </Grid>
      </div>
    )

    return (
      <>
        {inputs}
        <Collapse in={dataFilled}>{forms}</Collapse>
      </>
    )
  }

  const getComercialTab = (onClick: () => void, dataFilled: boolean) => {
    const comercialCustomerTabData: FormTypes[] = getComercialTabData({
      documentNumberValidation,
      isValidPesel,
    })

    const fillDataButton = (
      <Grid item xs={12} md={3}>
        <div className={classes.loadingButtonWrapper}>
          <Button
            disabled={comercialTabDataLoading}
            variant="outlined"
            color="primary"
            className={classes.fillPpeButton}
            component="span"
            onClick={onClick}
          >
            Pobierz dane
          </Button>
          {comercialTabDataLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Grid>
    )
    const inputs: JSX.Element = (
      <div style={{ marginBottom: "12px" }}>
        <Grid container alignItems="center" spacing={2}>
          {[comercialCustomerTabData[0], comercialCustomerTabData[1]].map(
            fieldConfig => {
              return (
                <CustomInputField
                  formData={fieldConfig}
                  validateField={validateField}
                  key={fieldConfig.name}
                />
              )
            }
          )}
          {fillDataButton}
        </Grid>
      </div>
    )

    const forms: JSX.Element = (
      <div>
        <Grid container alignItems="center" spacing={2}>
          {comercialCustomerTabData.slice(2).map(fieldConfig => {
            return (
              <CustomInputField
                formData={fieldConfig}
                validateField={validateField}
                key={fieldConfig.name}
              />
            )
          })}
        </Grid>
      </div>
    )

    return (
      <>
        {inputs}
        <Collapse in={dataFilled}>{forms}</Collapse>
      </>
    )
  }

  const customerRequestFactory: CustomerRequestFactory = new CustomerRequestFactory()

  const LpImages = useStaticQuery(graphql`
    {
      headerBackground: file(relativePath: { eq: "bg_banner_2.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1367) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imageData = LpImages.headerBackground.childImageSharp.fluid

  return (
    <div className={classes.formContainer} id={FormID}>
      <Portal>
        <ReCAPTCHA
          className={classes.recaptcha}
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.GATSBY_RECAPTCHA_KEY}
        />
      </Portal>

      <SummaryModal
        landingPage={ELandingPage.CURRENT_CUSTOMER}
        show={showSummaryModal}
        toggleModal={() => {
          setShowSummaryModal(false)
        }}
        togglePrompt = {
          () => {
            setShowSummaryModal(false)
          }
        }
        data={offerData}
        confirmForm={async () => {
          if (offerData.customer.commercialClient) {
            offerData.customer = {
              ...offerData.customer,
              ...offerData.customerTabData.comercial,
            }

            offerData.customer.PPE = {
              street: offerData.customerTabData.comercial.street,
              city: offerData.customerTabData.comercial.city,
            }
          } else {
            offerData.customer = {
              ...offerData.customer,
              ...offerData.customerTabData.customer,
            }

            offerData.customer.PPE = {
              street: offerData.customerTabData.customer.street,
              city: offerData.customerTabData.customer.city,
            }
          }

          await submitCore(offerData)
        }}
      ></SummaryModal>

      <DocumentModal
        show={showDocumentModal}
        toggleModal={() => {
          setShowDocumentModal(false)
        }}
        source={documentModalSource}
      ></DocumentModal>

      {isFormReady ? (
        <Formik initialValues={currentCustomerOfferData[0]} onSubmit={() => {}}>
          {({
            isSubmitting,
            values,
            isValid,
            setFieldValue,
            validateForm,
            submitForm,
            setSubmitting,
            resetForm,
          }) => (
            <>
              <Form className={classes.formComponent}>
                <SectionHeader
                  id="section-1"
                  sectionId={1}
                  header={"Uzupełnij dane w formularzu"}
                />

                <div
                  className={[
                    classes.cardsContainer,
                    classes.maxWidthCardContainer,
                  ].join(" ")}
                  id="section-1b"
                >
                  <Card className={classes.tabCard}>
                    <LpTabs
                      ariaLabel={"todo: aria label for tabs"}
                      onInvoiceTipClick={() => {
                        setShowDocumentModal(true)
                      }}
                      initialValue={toggleFormikFormInputs}
                      onChange={(value: number) => {
                        if (value == 0) {
                          setIsPeselHelperVisible(true)
                        } else {
                          setIsPeselHelperVisible(false)
                        }
                        const isCommercial: boolean =
                          value === CustomerType.COMERCIAL_CUSTOMER
                        setFieldValue("customer.commercialClient", isCommercial)
                      }}
                      tabs={[
                        {
                          label: "Klient indywidualny",
                          index: CustomerType.INDIVIDUAL_CUSTOMER,
                          content: getCustomerTab(async () => {
                            const dataRequest: GetCustomerTabDataRequest = customerRequestFactory.createCustomerTabDataRequest(
                              setFieldValue,
                              onError,
                              () => {
                                setIndividualCustomerDataLoaded(true)
                              }
                            )

                            const pesel: string =
                              values.customerTabData.customer.pesel
                            const customerNumber: string =
                              values.customerTabData.customer.customerNumber

                            setCustomerTabDataLoading(true)

                            try {
                              await dataRequest.execute(pesel, customerNumber)
                            } catch (error) {
                              trackError(error)
                              setUnableToDownloadData(true)
                            } finally {
                              setCustomerTabDataLoading(false)
                            }
                          }, individualCustomerDataLoaded),
                        },
                        {
                          label: "Jednoosobowa działalność gospodarcza",
                          index: CustomerType.COMERCIAL_CUSTOMER,
                          content: getComercialTab(async () => {
                            const dataRequest: GetComercialTabDataRequest = customerRequestFactory.createComercialClientTabDataRequest(
                              setFieldValue,
                              onError,
                              () => {
                                setComercialCustomerDataLoaded(true)
                              }
                            )
                            const taxNumber: string =
                              values.customerTabData.comercial.taxNumber
                            const customerNumber: string =
                              values.customerTabData.comercial.customerNumber

                            setComercialTabDataLoading(true)

                            try {
                              await dataRequest.execute(
                                taxNumber,
                                customerNumber
                              )
                            } catch (error) {
                              trackError(error)
                              setUnableToDownloadData(true)
                            } finally {
                              setComercialTabDataLoading(false)
                            }
                          }, comercialCustomerDataLoaded),
                        },
                      ]}
                    />
                    {isPeselHelperVisible && !individualCustomerDataLoaded ? (
                      <div className={classes.textNoPesel}>
                        {noPeselHelperText}
                        <span
                          className={classes.textNoPeselLink}
                          onClick={() =>
                            setShowContactComponent(value => !value)
                          }
                        >
                          {noPeselHelperLink}
                        </span>
                      </div>
                    ) : null}
                  </Card>
                </div>

                <ScrollToButton scrollSelector={"#section-2"} />

                <SectionHeader
                  id="section-2"
                  sectionId={2}
                  header={"Wybierz jedną z ofert"}
                />

                <Grid>
                  <p className={classes.afterSectionHeader}>
                    Jeżeli jesteś zainteresowany grupą taryfową inną niż G11
                    skontaktuj się z naszą infolinią
                  </p>

                  <div className={classes.cardsContainer}>
                    <Field name={"offer.offerType"}>
                      {({ field, form, meta }) => {
                        return (
                          <RadioButtonsGroup
                            documentType={field.value}
                            form={form}
                            field={field}
                            className={classes.maxRadioGroupWidth}
                            horizontal={true}
                            setValue={value => {
                              setFieldValue("offer.offerType", value)
                              if (value === EOfferType.STANDARD) {
                                setFieldValue(
                                  "service.serviceType",
                                  EServiceType.NONE
                                )
                                setFieldValue(
                                  "service.serviceOptionType",
                                  EServiceOptionType.NONE
                                )
                              }
                            }}
                          >
                            <Grid container justify="center" spacing={2}>
                              <OfferCard
                                className={
                                  values.offer.offerType === EOfferType.PRICE
                                    ? classes.offerCard
                                    : classes.offerCardDisabled
                                }
                                value={EOfferType.PRICE}
                                offer={values.offer.offers[0]}
                                sm={4}
                              ></OfferCard>
                              <OfferCard
                                className={
                                  values.offer.offerType === EOfferType.DISCOUNT
                                    ? classes.offerCard
                                    : classes.offerCardDisabled
                                }
                                value={EOfferType.DISCOUNT}
                                offer={values.offer.offers[1]}
                                sm={4}
                              ></OfferCard>
                            </Grid>
                          </RadioButtonsGroup>
                        )
                      }}
                    </Field>
                  </div>

                  <div className={classes.cardsContainer}>
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      spacing={2}
                      className={classes.wpOfferDurationContainer}
                    >
                      <Grid item xs={12} sm={12}>
                        <p className={classes.offerDurationLabel}>
                          Czas obowiązywania cennika:
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Field name="offer.isLong">
                          {({ field }) => (
                            <OfferDurationToggleButtons
                              disabled={
                                values.offer.offerType === EOfferType.STANDARD
                              }
                              isLong={field.value}
                              handleIsLong={isLong =>
                                isLong !== null &&
                                setFieldValue("offer.isLong", isLong)
                              }
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <ScrollToButton scrollSelector={"#section-3"} />

                <SectionHeader
                  id="section-3"
                  sectionId={3}
                  header={"Wybierz jedną z usług dodatkowych do Cennika"}
                />

                <BackgroundImage
                  Tag="section"
                  className={classes.headerImage}
                  fluid={imageData}
                  backgroundColor={`#040e18`}
                >
                  <div className={classes.serviceBackground}>
                    <Grid>
                      <div className={classes.backgroundCardsContainer}>
                        <Field name={"service.serviceType"}>
                          {({ field, form, meta }) => {
                            return (
                              <RadioButtonsGroup
                                documentType={field.value}
                                form={form}
                                field={field}
                                className={classes.maxRadioGroupWidth}
                                horizontal={true}
                                setValue={value => {
                                  setFieldValue("service.serviceType", value)
                                  switch (value) {
                                    case EServiceType.EXPERT:
                                      setFieldValue(
                                        "service.serviceOptionType",
                                        EServiceOptionType.EXPERT_STANDARD
                                      )
                                      break
                                    case EServiceType.HELPFUL:
                                      setFieldValue(
                                        "service.serviceOptionType",
                                        EServiceOptionType.HELPFUL_STANDARD
                                      )
                                      break
                                    case EServiceType.NONE:
                                      setFieldValue(
                                        "service.serviceOptionType",
                                        EServiceOptionType.NONE
                                      )
                                      break
                                  }
                                }}
                              >
                                <Grid container justify="center" spacing={2}>
                                  <ServiceCard
                                    className={
                                      values.service.serviceType ===
                                        EServiceType.HELPFUL &&
                                      values.offer.offerType !==
                                        EOfferType.STANDARD
                                        ? classes.serviceCard
                                        : classes.serviceCardDisabled
                                    }
                                    value={EServiceType.HELPFUL}
                                    service={values.service.services[1]}
                                    sm={3}
                                    fullDisabled={
                                      values.offer.offerType ===
                                      EOfferType.STANDARD
                                    }
                                    disabled={
                                      values.service.serviceType !==
                                      EServiceType.HELPFUL
                                    }
                                    setValue={value => {
                                      setFieldValue(
                                        "service.serviceOptionType",
                                        value
                                      )
                                    }}
                                  ></ServiceCard>
                                  <ServiceCard
                                    className={
                                      values.service.serviceType ===
                                        EServiceType.EXPERT &&
                                      values.offer.offerType !==
                                        EOfferType.STANDARD &&
                                      !values.customer.commercialClient
                                        ? classes.serviceCard
                                        : classes.serviceCardDisabled
                                    }
                                    value={EServiceType.EXPERT}
                                    service={values.service.services[0]}
                                    sm={3}
                                    fullDisabled={
                                      values.offer.offerType ===
                                        EOfferType.STANDARD ||
                                      values.customer.commercialClient
                                    }
                                    disabled={
                                      values.service.serviceType !==
                                      EServiceType.EXPERT
                                    }
                                    setValue={value => {
                                      setFieldValue(
                                        "service.serviceOptionType",
                                        value
                                      )
                                    }}
                                  ></ServiceCard>
                                  <ServiceCard
                                    className={
                                      values.service.serviceType ===
                                        EServiceType.NONE &&
                                      values.offer.offerType !==
                                        EOfferType.STANDARD
                                        ? classes.serviceCard
                                        : classes.serviceCardDisabled
                                    }
                                    value={EServiceType.NONE}
                                    service={values.service.services[2]}
                                    sm={3}
                                    fullDisabled={
                                      values.offer.offerType ===
                                      EOfferType.STANDARD
                                    }
                                    disabled={
                                      values.service.serviceType !==
                                      EServiceType.NONE
                                    }
                                    setValue={value => {
                                      setFieldValue(
                                        "service.serviceOptionType",
                                        value
                                      )
                                    }}
                                  ></ServiceCard>
                                </Grid>
                              </RadioButtonsGroup>
                            )
                          }}
                        </Field>
                      </div>
                    </Grid>
                  </div>
                </BackgroundImage>

                <ScrollToButton scrollSelector={"#section-4"} />

                <SectionHeader
                  id="section-4"
                  sectionId={4}
                  header={
                    "Wyraź co najmniej jedną zgodę marketingową i obniż miesięczną opłatę stałą o 5 zł"
                  }
                />

                <ConsentPanel
                  emailFiledName={"customer.emailAgree"}
                  phoneFiledName={"customer.phoneAgree"}
                  setEmailAgree={(value: boolean) => {
                    setFieldValue("customer.emailAgree", value)
                  }}
                  setPhoneAgree={(value: boolean) => {
                    setFieldValue("customer.phoneAgree", value)
                  }}
                />

                <Consumer>
                  {({ showForm, setShowForm }) => (
                    <Grid item xs={12} md={7}>
                      <ErrorModal
                        show={showErrorModal}
                        toggleModal={() => {
                          setShowErrorModal(false)
                        }}
                        error={errorMsg}
                      />
                      <SuccessModal
                        show={showSuccessModal}
                        toggleModal={() => {
                          setShowSuccessModal(false)
                        }}
                        now={now}
                      />
                      <MeetingModal
                        show={showCalendarModal}
                        toggleModal={() => {
                          setShowCalendarModal(false)
                          dateReject()
                        }}
                        onSubmit={submitDate}
                      />
                      {showContactComponent ? (
                        <>
                          <FormModal
                            id={EFormTypes.PESEL}
                            show={true}
                            toggleModal={() =>
                              setShowContactComponent(value => !value)
                            }
                            getCalendarValue={() => getCalendarDataPromise()}
                            onSucces={now => {
                              setShowSuccessModal(true)
                              setShowCalendarModal(false)
                              setShowForm(false)
                              setNow(now)
                            }}
                            onError={errMsg => {
                              setErrorMsg(errMsg)
                              setShowSuccessModal(false)
                              setShowCalendarModal(false)
                              setShowForm(false)
                              setShowErrorModal(true)
                            }}
                          />
                          <Portal>
                            <StyledButton
                              variant="contained"
                              color="primary"
                              className={classes.formButton}
                              onClick={() => setShowForm(true)}
                            >
                              <MailOutlineIcon />
                            </StyledButton>
                          </Portal>
                        </>
                      ) : null}
                      {unableToDownloadData ? (
                        <>
                          <FormModal
                            id={EFormTypes.SERVER_ERROR}
                            show={true}
                            toggleModal={() =>
                              setUnableToDownloadData(value => !value)
                            }
                            getCalendarValue={() => getCalendarDataPromise()}
                            onSucces={now => {
                              setShowSuccessModal(true)
                              setShowCalendarModal(false)
                              setShowForm(false)
                              setNow(now)
                            }}
                            onError={errMsg => {
                              setErrorMsg(errMsg)
                              setShowSuccessModal(false)
                              setShowCalendarModal(false)
                              setShowForm(false)
                              setShowErrorModal(true)
                            }}
                          />
                          <Portal>
                            <StyledButton
                              variant="contained"
                              color="primary"
                              className={classes.formButton}
                              onClick={() => setShowForm(true)}
                            >
                              <MailOutlineIcon />
                            </StyledButton>
                          </Portal>
                        </>
                      ) : null}
                    </Grid>
                  )}
                </Consumer>

                <div className={classes.buttonsContainer}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      await submitForm()
                      const errors = await validateForm()
                      openSummaryModal(
                        values,
                        errors,
                        setSubmitting,
                        onValidationError
                      )
                    }}
                  >
                    Zamawiam
                  </StyledButton>
                </div>
              </Form>
            </>
          )}
        </Formik>
      ) : null}
    </div>
  )
}
