import React from "react"
import {
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { createStyles } from "@material-ui/styles"
import { maxWidth, relativeContentPadding } from "../../styles/constants"
import { graphql, useStaticQuery } from "gatsby"

const useSmallStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: "59px",
      [theme.breakpoints.down("sm")]: {
        height: "23px",
      },
    },
  })
)

const LogoSmall = () => {
  const styles = useSmallStyles()
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
        publicURL
      }
    }
  `)
  return (
    <img
      alt="polenergia_logo"
      className={styles.logo}
      src={data.file.publicURL}
    />
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      ...relativeContentPadding(),
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "95px",
        paddingRight: "95px",
      },
    },
    header: {
      ...maxWidth,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "40px 0",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
  })
)

export const LightsOnHeader: React.FC = () => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <div id="formTop" className={styles.container}>
      <header className={styles.header}>
        <LogoSmall />
      </header>
    </div>
  )
}
