import {
  EDocumentVersion,
  EStatementType,
} from "../models/OffersEnums"

const checkFileType = (type: string): string => {
  const acceptedTypes: string[] = ["application/pdf", "image/png", "image/jpeg"]
  if (!acceptedTypes.includes(type)) {
    return "Dołączony plik jest niepoprawny - akceptowane są tylko obrazy lub pliki PDF."
  }
  return undefined
}

export const checkFile = (file: any, id?: string): string => {
  if (!file) {
    if (id == EStatementType.PROXY)
      return "Do formularza proszę dołączyć skan pełnomocnictwa."
    else
      return "Do formularza proszę dołączyć skan Protokołu Zdawczo – Odbiorczego lub Zlecenia Obsługi Technicznej."
  } else {
    return checkFileType(file.type)
  }
}

export const checkStatementType = (type: EStatementType): string => {
  if (type === EStatementType.PARTNER) {
    return "Dla osób prowadzących Jednoosobową Działalność Gospodarczą zawarcie umowy z drugą osobą jest niemożliwe."
  }
  return undefined
}

export const isValidPesel = (pesel: string) => {
  if (pesel.length !== 11) {
    return false
  }

  if (!/^[0-9]*$/.test(pesel)) {
    return false
  }

  if (pesel === "00000000000") {
    return false
  }

  let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
  let sum = 0
  let controlNumber = parseInt(pesel.substring(10, 11))

  for (let i = 0; i < weight.length; i++) {
    sum += parseInt(pesel.substring(i, i + 1)) * weight[i]
  }
  sum = sum % 10
  return (10 - sum) % 10 === controlNumber
}

export const getDocumentVersion = (
  documentNumber: string
): EDocumentVersion => {
  const d: string = documentNumber.toLowerCase()
  const dParts: string[] = d.split("/")
  if (dParts.length) {
    switch (dParts[0]) {
      case "pzo":
        return EDocumentVersion.PZO
      case "ot":
        return EDocumentVersion.OT
    }
  }

  return EDocumentVersion.NONE
}
