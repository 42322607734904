import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import {
  MyPoleneBannerProps,
  MyPoleneCardProps,
  MyPoleneCard,
} from "./myPoleneBanner"

export const MyPoleneBannerFooter: React.FC<MyPoleneBannerProps> = (
  props: MyPoleneBannerProps
) => {
  const myPoleneImages = useStaticQuery(graphql`
    {
      greenEnergy: file(relativePath: { eq: "Green_Energy.svg" }) {
        publicURL
      }
      greenHand: file(relativePath: { eq: "Green_Hand.svg" }) {
        publicURL
      }
      dot: file(relativePath: { eq: "Dot.svg" }) {
        publicURL
      }
    }
  `)

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      backgroundImage: "linear-gradient(to top, #1a3e4e, #19a49f)",
      paddingBottom: "40px",
      paddingTop: "48px",
      textAlign: "center",
    },
    lightHeader: {
      fontSize: "24px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "0.32px",
      textAlign: "left",
      color: "#fff",
    },
    contentHeader: {
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "0.64px",
      textAlign: "left",
      color: "#414141",
    },
    contentDots: {
      listStyleImage: `url(${myPoleneImages.dot.publicURL})`,
      "& li": {
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.29,
        letterSpacing: "0.12px",
        textAlign: "justify",
        color: "#414141",
        padding: "6px",
        "& span": {
          fontWeight: "bold",
        },
        "& a": {
          fontWeight: "bold",
          color: "#12beab",
          textDecoration: "none !important",
        },
      },
    },
    contentEmptyDots: {
      listStyleImage: "none",
      listStyleType: "none",
      paddingLeft: "24px",
    },
  }))

  const classes = useStyles()
  const greenEnergyContent = (
    <div>
      <p className={classes.contentHeader}>
        Aby w pełni korzystać z serwisu wystarczy:
      </p>
      <ul className={[classes.contentDots, classes.contentEmptyDots].join(" ")}>
        <li>
          <span>Krok 1:</span> Wejść na stronę{" "}
          <a href="https://moja.polenergia.pl">https://moja.polenergia.pl</a>{" "}
          oraz wybrać opcję <span>"Zarejestruj się"</span>
        </li>
        <li>
          <span>Krok 2:</span> Uzupełnić formularz rejestracyjny
        </li>
        <li>
          <span>Krok 3:</span> Aktywować konto klikając w link, który otrzymasz
          na swój adres e-mail
        </li>
      </ul>
    </div>
  )

  return (
    <div className={classes.container}>
      <label className={classes.lightHeader}>Dalsze kroki</label>
      <MyPoleneCard
        icon={<img src={myPoleneImages.greenEnergy.publicURL} />}
        content={greenEnergyContent}
      />
    </div>
  )
}
