import React, { useEffect } from "react"
import {
  AppBar,
  Box,
  Button,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBarRoot: {
      backgroundColor: "white",
      border: "none",
      boxShadow: "none",
      padding: "0px",
    },
    tabRoot: {
      maxWidth: "none",
      padding: "16px 24px",
      textTransform: "none",
      fontSize: "13px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.28px",
      textAlign: "center",
      color: "#5b5b5b",
    },
    tabSelected: {
      color: "#12beab",
    },
    indicator: {
      backgroundColor: "#12beab",
      height: "3px",
    },
    scroller: {
      borderBottom: "1px solid #ebebeb",
    },
    tabPanel: {
      padding: "24px",
    },
    alert: {
      position: "relative",
      padding: "32px 24px 32px 24px",
      backgroundColor: "#12beab",
      margin: "8px 0 0px",
      "& p": {
        fontSize: "13px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.38,
        letterSpacing: "0.16px",
        color: "#fff",
        margin: 0,
        "& span": {
          fontWeight: 800,
        },
      },
    },
    invoiceContainer: {
      position: "absolute",
      right: "8px",
      top: "0px",
    },
  })
)

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  const classes = useStyles()

  return (
    <div
      className={classes.tabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export interface ClientDataTab {
  label: string
  index: number
  content: React.ReactNode
}

export interface LpTabsProps {
  ariaLabel: string
  tabs: ClientDataTab[]
  onChange: (value: number) => void
  onInvoiceTipClick: () => void
  initialValue?: number
}

export const LpTabs: React.FC<LpTabsProps> = ({
  ariaLabel,
  tabs,
  onChange,
  onInvoiceTipClick,
  initialValue,
  ...props
}) => {
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
    onChange(newValue)
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      key: `customer-tab-${index}`,
    }
  }

  const [value, setValue] = React.useState(initialValue ?? 0)

  const customerTabImages = useStaticQuery(graphql`
    {
      invoiceMedium: file(relativePath: { eq: "Invoice_Medium.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <>
      <AppBar position="static" classes={{ root: classes.appBarRoot }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label={ariaLabel}
          classes={{ indicator: classes.indicator, scroller: classes.scroller }}
        >
          {tabs.map((tab: ClientDataTab) => {
            return (
              <Tab
                label={tab.label}
                {...a11yProps(tab.index)}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            )
          })}
        </Tabs>
      </AppBar>

      <div className={classes.alert}>
        <p>
          <span>WAŻNE</span> — Numer płatnika znajdziesz na fakturze w lewym,
          górnym rogu.
        </p>
        <div className={classes.invoiceContainer}>
          <Button
            onClick={() => {
              onInvoiceTipClick()
            }}
          >
            <img src={customerTabImages.invoiceMedium.publicURL} />
          </Button>
        </div>
      </div>

      {tabs.map((tab: ClientDataTab) => {
        return (
          <TabPanel value={value} index={tab.index} key={tab.index}>
            {tab.content}
          </TabPanel>
        )
      })}
    </>
  )
}
