import { CustomerDataResponse } from "../../../../api/wlaczPradForm"
import CustomerTabDataField from "./CustomerTabDataField"
import GetCustomerTabDataRequest from "./GetCustomerTabDataRequest"
import scrollTo from "gatsby-plugin-smoothscroll"
import GetComercialTabDataRequest from "./GetComercialTabDataRequest"
import ComercialTabDataField from "./ComercialTabDataField"

export default class CustomerRequestFactory {
  createCustomerTabDataRequest(
    setFieldValue: (field: string, value: any) => void,
    onError: (message: string, header: string) => void,
    showFields: () => void
  ) {
    const onRequestSuccess = (data: CustomerDataResponse) => {
      setFieldValue(CustomerTabDataField.EMAIL, data.email)
      setFieldValue(CustomerTabDataField.FIRST_NAME, data.firstName)
      setFieldValue(CustomerTabDataField.LAST_NAME, data.lastName)
      setFieldValue(CustomerTabDataField.PHONE, data.phone)
      setFieldValue(CustomerTabDataField.CITY, data.address.city)
      setFieldValue(CustomerTabDataField.STREET, data.address.street)

      showFields()
    }

    const dataRequest: GetCustomerTabDataRequest = new GetCustomerTabDataRequest(
      onRequestSuccess,
      (message: string) => {
        onError(message, "Uwaga")
      }
    )

    return dataRequest
  }

  createComercialClientTabDataRequest(
    setFieldValue: (field: string, value: any) => void,
    onError: (message: string, header: string) => void,
    showFields: () => void
  ) {
    const onRequestSuccess = (data: CustomerDataResponse) => {
      setFieldValue(ComercialTabDataField.EMAIL, data.email)
      setFieldValue(ComercialTabDataField.FIRST_NAME, data.firstName)
      setFieldValue(ComercialTabDataField.LAST_NAME, data.lastName)
      setFieldValue(ComercialTabDataField.PHONE, data.phone)
      setFieldValue(ComercialTabDataField.CITY, data.address.city)
      setFieldValue(ComercialTabDataField.STREET, data.address.street)

      showFields()
    }

    const dataRequest: GetComercialTabDataRequest = new GetComercialTabDataRequest(
      onRequestSuccess,
      (message: string) => {
        onError(message, "Uwaga")
      }
    )

    return dataRequest
  }
}
