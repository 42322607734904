export interface FieldLabels {
  placeholder: string
  errorMsg: string
  errorEmpty: string
}

export interface FieldDescription {
  name: string
  labels: FieldLabels
}

export const getDefaultFieldDescriptions = (name: string): FieldLabels => {
  const defaultFields: FieldDescription[] = [
    {
      name: "pesel",
      labels: {
        placeholder: "Numer PESEL",
        errorMsg: "Niepoprawny numer PESEL",
        errorEmpty: "Podaj numer PESEL",
      },
    },
    {
      name: "customerNumber",
      labels: {
        placeholder: "Numer płatnika",
        errorMsg: "Niepoprawny numer płatnika",
        errorEmpty: "Podaj numer płatnika",
      },
    },
    {
      name: "firstName",
      labels: {
        placeholder: "Imię",
        errorMsg: "Wprowadź co najmniej 2 znaki",
        errorEmpty: "Podaj imię",
      },
    },
    {
      name: "lastName",
      labels: {
        placeholder: "Nazwisko",
        errorMsg: "Wprowadź co najmniej 2 znaki",
        errorEmpty: "Podaj nazwisko",
      },
    },
    {
      name: "email",
      labels: {
        placeholder: "Email",
        errorMsg: "Niepoprawny adres E-mail",
        errorEmpty: "Podaj adres E-mail Klienta",
      },
    },
    {
      name: "phone",
      labels: {
        placeholder: "Telefon",
        errorMsg: "Niepoprawny numer telefonu",
        errorEmpty: "Podaj numer telefonu",
      },
    },
    {
      name: "street",
      labels: {
        placeholder: "Ulica, nr domu/nr lokalu",
        errorMsg: "Wprowadź co najmniej 2 znaki",
        errorEmpty: "Podaj dane adresowe",
      },
    },
    {
      name: "city",
      labels: {
        placeholder: "Kod pocztowy i miejscowość",
        errorMsg: "Wprowadź co najmniej 2 znaki",
        errorEmpty: "Podaj dane adresowe",
      },
    },
    {
      name: "companyName",
      labels: {
        placeholder: "Nazwa przedsiębiorstwa",
        errorMsg: "Wprowadź co najmniej 2 znaki",
        errorEmpty: "Podaj nazwę przedsiębiorstwa",
      },
    },
    {
      name: "taxNumber",
      labels: {
        placeholder: "NIP",
        errorMsg: "Niepoprawny NIP",
        errorEmpty: "Podaj NIP",
      },
    },
    {
      name: "passport",
      labels: {
        placeholder: "Numer paszportu",
        errorMsg: "Niepoprawny numer paszportu",
        errorEmpty: "Podaj numer paszportu",
      },
    },
    {
      name: "residence",
      labels: {
        placeholder: "Numer karty pobytu",
        errorMsg: "Niepoprawny numer karty pobytu",
        errorEmpty: "Podaj numer karty pobytu",
      },
    },
  ]

  const result: FieldDescription = defaultFields.find(
    customField => customField.name === name
  )
  return result?.labels
}
