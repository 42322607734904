import React from "react"

export const ServerErrorModalContent = () => {
  return (
    <>
      <h1>Brak łączności z serwerem</h1>
      <h2>
        Prosze wypełnić formularz kontaktowy w celu uzyskania pomocy
        telefonicznej
      </h2>
    </>
  )
}
