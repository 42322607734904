import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

export const DocumentIcon: React.FC = () => {
  const useStyles = makeStyles((theme: Theme) => ({
    documentWrapper: {
      display: "flex",
    },
    documentContainer: {
      padding: "8px",
      boxShadow: "2px 4px 32px 0 rgba(28, 28, 28, 0.16)",
      backgroundColor: "#fff",
      margin: "auto",
      borderRadius: "24px",
      height: "48px",
      width: "48px",
    },
  }))

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "Preview.svg" }) {
        publicURL
      }
    }
  `)

  const classes = useStyles()

  return (
    <div className={classes.documentWrapper}>
      <div className={classes.documentContainer}>
        <img src={data.file.publicURL} />
      </div>
    </div>
  )
}
