import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core"
import React from "react"

export const AllMarketingConsentsCheckboxCopy = ({
  checked,
  onChange,
}) => {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
          />
        }
        label={"Zaznacz wszystkie zgody"}
      />
    </FormGroup>
  )
}
