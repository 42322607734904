import {
  CustomerDataResponse,
  getCurrentIndividualCustomerData,
} from "../../../../api/wlaczPradForm"
import { isValidPesel } from "../../../../utils/lightsOnFormUtils"

export default class GetCustomerTabDataRequest {
  onRequestSuccess: (data: CustomerDataResponse) => void
  onMessage: (message: string) => void

  constructor(onRequestSuccess, onMessage) {
    this.onRequestSuccess = onRequestSuccess
    this.onMessage = onMessage
  }

  async execute(pesel: string, customerNumber: string) {
    if (
      this.isPeselValid(pesel) &&
      this.isCustomerNumberValid(customerNumber)
    ) {
      await this.fillCustomerForm(pesel, customerNumber)
    } else {
      this.onMessage(
        "Dane możemy pobrać tylko na podstawie numeru PESEL i numeru płatnika - proszę o wprowadznie odpowiednich wartości."
      )
    }
  }

  private isPeselValid(pesel: string): boolean {
    return !!pesel && isValidPesel(pesel)
  }

  private isCustomerNumberValid(customerNumber: string): boolean {
    return !!customerNumber
  }

  private async fillCustomerForm(pesel: string, customerNumber: string) {
    const customerData: CustomerDataResponse = await getCurrentIndividualCustomerData(
      pesel,
      customerNumber
    )
    this.onRequestSuccess(customerData)
  }
}
