import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core"
import React from "react"
import { EAddressType } from "../../models/OffersEnums"

export const AddressCheckboxCopy = ({ field, addressType, setValue }) => {
  const handleChange = event => {
    const name: string = event.target.name
    const checked: boolean = event.target.checked

    if (!checked) {
      setValue(EAddressType.NONE)
    } else {
      var address: EAddressType = EAddressType.NONE
      switch (name) {
        case "addressType":
          address = EAddressType.PPE
          break
      }
      setValue(address)
    }
  }

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(addressType === EAddressType.PPE)}
            name={"addressType"}
            value={EAddressType.PPE}
            onChange={handleChange}
          />
        }
        label={"Jak punkt poboru energii"}
      />
    </FormGroup>
  )
}
