import React, { useState, createContext, useContext, ReactNode } from "react"
import { ContractType } from "../components/ToggleButtons"

type ContractTypeProviderProps = {
  children: ReactNode
}

type ContractTypeContextType = {
  contractType: ContractType
  updateContractType: (contractType: ContractType) => void
}

export const ContractTypeContext = createContext<ContractTypeContextType | null>(
  null
)

export const ContractTypeProvider = ({
  children,
}: ContractTypeProviderProps) => {
  const [contractType, setContractType] = useState<ContractType>(
    ContractType.INDIVIDUAL
  )

  const updateContractType = (contractType: ContractType) => {
    setContractType(contractType)
  }

  return (
    <ContractTypeContext.Provider value={{ contractType, updateContractType }}>
      {children}
    </ContractTypeContext.Provider>
  )
}
