import React from "react"
import {
  Grid,
  Card,
  Radio,
  Theme,
  makeStyles,
  FormControlLabel,
  Link,
} from "@material-ui/core"
import { CardStyledButton } from "../Button"
import { Field } from "formik"
import { AttachFile, CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"
import RadioButtonsGroup from "./RadioButtonsGroup"

const ServiceCard = ({
  className,
  value,
  service,
  sm,
  setValue,
  disabled,
  fullDisabled,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    offerHeader: {
      fontSize: "34px;",
      marginTop: "11px",
      marginBottom: "0px",
      paddingRight: "16px",
      paddingLeft: "16px",
      minHeight: "100px",
    },
    offerTitleAccent: {
      color: "#12beab",
    },
    offerLinkButton: {
      position: "absolute",
      width: "100%",
      bottom: "0px",
      left: "0px",
    },
    cardButtonPadding: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
    servicePlaceholderContainer: {
      paddingTop: "44px",
      paddingBottom: "44px",
      marginBottom: "11px",
    },
    serviceBoxesContainer: {
      paddingLeft: "50px",
      paddingBottom: "84px",
      marginBottom: "11px",
      paddingTop: "11px",
    },
    maxRadioGroupWidth: {
      width: "100%",
    },
    labelPlacement: {
      textAlign: "left",
    },
  }))

  const classes = useStyles()

  return (
    <Grid item xs={12} lg={sm}>
      <Card className={className}>
        <div>
          <Radio key={value} value={value} disabled={fullDisabled} />
          {(() => {
            var parts: string[] = service.title.split(" ")
            return (
              <p className={classes.offerHeader}>
                <span className={classes.offerTitleAccent}>{parts[0]}</span>{" "}
                {parts[1]}
              </p>
            )
          })()}
          {(() => {
            if (!service.option.boxes.length) {
              return (
                <div className={classes.servicePlaceholderContainer}>
                  <p>{service.option.placeholder}</p>
                </div>
              )
            } else {
              return (
                <div className={classes.serviceBoxesContainer}>
                  <Field name={"service.serviceOptionType"}>
                    {({ field, form, meta }) => {
                      return (
                        <RadioButtonsGroup
                          documentType={field.value}
                          form={form}
                          field={field}
                          className={classes.maxRadioGroupWidth}
                          horizontal={false}
                          setValue={value => {
                            setValue(value)
                          }}
                        >
                          {service.option.boxes.map(box => {
                            return (
                              <FormControlLabel
                                key={box.type}
                                value={box.type}
                                control={
                                  <Radio
                                    disabled={disabled || fullDisabled}
                                    icon={<CheckBoxOutlineBlank />}
                                    checkedIcon={<CheckBox />}
                                  />
                                }
                                label={box.description}
                                className={classes.labelPlacement}
                              />
                            )
                          })}
                        </RadioButtonsGroup>
                      )
                    }}
                  </Field>
                </div>
              )
            }
          })()}
          {service.path && (
            <Link
              href={service.path}
              target="_blank"
              rel="noreferrer"
              className={classes.offerLinkButton}
            >
              <CardStyledButton
                variant="contained"
                color="primary"
                startIcon={<AttachFile />}
                className={classes.cardButtonPadding}
              >
                Pobierz
              </CardStyledButton>
            </Link>
          )}
        </div>
      </Card>
    </Grid>
  )
}

export default ServiceCard
