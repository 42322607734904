import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core"
import React from "react"
import { ECorrespondenceAddressType } from "../../models/OffersEnums"

export const CorrespondenceAddressCheckboxCopy = ({
  field,
  correspondenceAddressType,
  setValue,
  label,
}) => {
  const handleChange = event => {
    const name: string = event.target.name
    const checked: boolean = event.target.checked

    if (!checked) {
      setValue(ECorrespondenceAddressType.NONE)
    } else {
      var address: ECorrespondenceAddressType = ECorrespondenceAddressType.NONE
      switch (name) {
        case "correspondenceAddressType":
          address = ECorrespondenceAddressType.ADDRESS
          break
      }
      setValue(address)
    }
  }

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(
              correspondenceAddressType === ECorrespondenceAddressType.ADDRESS
            )}
            name={String("correspondenceAddressType")}
            value={ECorrespondenceAddressType.ADDRESS}
            onChange={handleChange}
          />
        }
        label={label}
      />
    </FormGroup>
  )
}
