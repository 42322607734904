export const getTokenFromURL = (): string => {
  const urlLocation: string =
    typeof window !== "undefined" ? window.location.href : ""
  if (urlLocation) {
    const url: URL = new URL(urlLocation)
    const tokenID: string = url.searchParams.get("token")
    if (tokenID) {
      return tokenID
    }
  }
}
