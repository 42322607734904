import { FormTypes } from "./formularz"
import ComercialTabDataField from "./formUtils/ComercialTabDataField"
import CustomerTabDataField from "./formUtils/CustomerTabDataField"
import { getDefaultFieldDescriptions } from "./formUtils/DescriptionsUtils"

export interface IGetOfferDataFieldsProps {
  documentNumberValidation: (el: string) => boolean
  isValidPesel: (el: string) => boolean
}

export const getBasicInfoFields = (): FormTypes[] => {
  return [
    {
      name: "customer.companyName",
      placeholder: "Nazwa przedsiębiorstwa",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj nazwę przedsiębiorstwa",
      required: true,
      width: 6,
    },
    {
      name: "customer.taxNumber",
      placeholder: "NIP",
      validation: (el) => el.length < 10 || el.length > 15 || !/^[A-Z0-9-]+$/i.test(el),
      errorMsg: "Niepoprawny NIP",
      errorEmpty: "Podaj NIP",
      required: true,
      width: 6,
    },
    {
      name: "customer.firstName",
      placeholder: "Imię",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj imię",
      required: true,
      width: 3,
    },
    {
      name: "customer.lastName",
      placeholder: "Nazwisko",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj nazwisko",
      required: true,
      width: 3,
    },
    {
      name: "customer.email",
      placeholder: "Email",
      validation: (el) => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(el),
      errorMsg: "Niepoprawny adres E-mail",
      errorEmpty: "Podaj adres E-mail Klienta",
      required: true,
      width: 3,
    },
    {
      name: "customer.phone",
      placeholder: "Telefon",
      validation: (el) => !/^(([+][0-9]{2}|[0]{2}[0-9]{2}){0,1}[1-9][0-9]{8})$/i.test(el),
      errorMsg: "Niepoprawny numer telefonu",
      errorEmpty: "Podaj numer telefonu",
      required: true,
      width: 3,
    },
    {
      name: "customer.PzoZot",
      placeholder: "Numer PZO/ZOT",
      validation: (el) => !/^(OT|PZO)\/\w+\/\w+/i.test(el),
      errorMsg: "Niepoprawny numer dokumentu",
      errorEmpty: "Podaj numer PZO/ZOT",
      width: 3,
      required: true,
    },
    {
      name: "customer.meterNumber",
      placeholder: "Numer licznika",
      validation: (el) => el.length < 2,
      errorMsg: "Niepoprawny format",
      errorEmpty: "Podaj numer licznika",
      width: 3,
      required: true,
    },
    {
      name: "customer.meterQuantity",
      placeholder: "Stan licznika [kWh]",
      validation: (el) => !(/^\d+(,\d{0,2})?$/i.test(el) || /^\d+(.\d{0,2})?$/i.test(el)),
      errorMsg: "Niepoprawny format",
      errorEmpty: "Podaj stan licznika",
      width: 3,
      required: true,
    },
    {
      name: "customer.residenceCardNumber",
      placeholder: "Numer karty pobytu",
      validation: (el) => !/^[a-zA-Z]{2}[0-9]{7}|[a-zA-Z]{2} [0-9]{7}$/.test(el),
      errorMsg: "Niepoprawny format",
      errorEmpty: "Podaj numer karty pobytu",
      width: 3,
      required: true,
    },
    {
      name: "customer.passportNumber",
      placeholder: "Numer paszportu",
      validation: (el) => !/^[a-zA-Z]{2}[0-9]{7}|[a-zA-Z]{2} [0-9]{7}$/.test(el),
      errorMsg: "Niepoprawny format",
      errorEmpty: "Podaj numer paszportu",
      width: 3,
      required: true,
    },
    {
      name: "customer.regon",
      placeholder: "REGON",
      validation: (el) => !/^(\d{9}|\d{14})$/.test(el),
      errorMsg: "Niepoprawny format",
      errorEmpty: "Podaj numer REGON",
      width: 3,
      required: true,
    },
    {
      name: "customer.krs",
      placeholder: "KRS",
      validation: (el) => !/^\d{10}$/.test(el),
      errorMsg: "Niepoprawny format",
      errorEmpty: "Podaj numer KRS",
      width: 3,
      required: false,
    },
  ]
}

export const getComercialTabData = (params: IGetOfferDataFieldsProps): FormTypes[] => {
  return [
    {
      name: ComercialTabDataField.TAX_NUMBER,
      validation: (el) => el.length < 10 || el.length > 15 || !/^[A-Z0-9-]+$/i.test(el),
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("taxNumber"),
    },
    {
      name: ComercialTabDataField.CUSTOMER_NUMBER,
      validation: (el) => !/^\w+/i.test(el),
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("customerNumber"),
    },
    {
      name: ComercialTabDataField.COMPANY_NAME,
      validation: (el) => el.length < 2,
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("companyName"),
    },
    {
      name: ComercialTabDataField.FIRST_NAME,
      validation: (el) => el.length < 2,
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("firstName"),
    },
    {
      name: ComercialTabDataField.LAST_NAME,
      validation: (el) => el.length < 2,
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("lastName"),
    },
    {
      name: ComercialTabDataField.EMAIL,
      validation: (el) => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(el),
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("email"),
    },
    {
      name: ComercialTabDataField.PHONE,
      validation: (el) => !/^(([+][0-9]{2}|[0]{2}[0-9]{2}){0,1}[1-9][0-9]{8})$/i.test(el),
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("phone"),
    },
    {
      name: ComercialTabDataField.STREET,
      validation: (el) => el.length < 2,
      required: true,
      width: 3,
      disabled: true,
      ...getDefaultFieldDescriptions("street"),
    },
    {
      name: ComercialTabDataField.CITY,
      validation: (el) => el.length < 2,
      required: true,
      width: 3,
      disabled: true,
      ...getDefaultFieldDescriptions("city"),
    },
  ]
}

export const getCustomerTabData = (params: IGetOfferDataFieldsProps): FormTypes[] => {
  return [
    {
      name: CustomerTabDataField.PESEL,
      validation: (el) => !params.isValidPesel(el),
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("pesel"),
    },
    {
      name: CustomerTabDataField.CUSTOMER_NUMBER,
      validation: (el) => !/^\w+/i.test(el),
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("customerNumber"),
    },
    {
      name: CustomerTabDataField.FIRST_NAME,
      validation: (el) => el.length < 2,
      required: true,
      width: 3,
      disabled: true,
      ...getDefaultFieldDescriptions("firstName"),
    },
    {
      name: CustomerTabDataField.LAST_NAME,
      validation: (el) => el.length < 2,
      required: true,
      width: 3,
      disabled: true,
      ...getDefaultFieldDescriptions("lastName"),
    },
    {
      name: CustomerTabDataField.EMAIL,
      validation: (el) => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(el),
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("email"),
    },
    {
      name: CustomerTabDataField.PHONE,
      validation: (el) => !/^(([+][0-9]{2}|[0]{2}[0-9]{2}){0,1}[1-9][0-9]{8})$/i.test(el),
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("phone"),
    },
    {
      name: CustomerTabDataField.STREET,
      validation: (el) => el.length < 2,
      required: true,
      width: 3,
      disabled: true,
      ...getDefaultFieldDescriptions("street"),
    },
    {
      name: CustomerTabDataField.CITY,
      validation: (el) => el.length < 2,
      required: true,
      width: 3,
      disabled: true,
      ...getDefaultFieldDescriptions("city"),
    },
    {
      name: CustomerTabDataField.PASSPORT_NUMBER,
      validation: (el) => !/^[a-zA-Z]{2}[0-9]{7}|[a-zA-Z]{2} [0-9]{7}$/.test(el),
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("passport"),
    },
    {
      name: CustomerTabDataField.RESIDANCE_CARD,
      validation: (el) => !/^[a-zA-Z]{2}[0-9]{7}|[a-zA-Z]{2} [0-9]{7}$/.test(el),
      required: true,
      width: 3,
      disabled: false,
      ...getDefaultFieldDescriptions("residence"),
    },
  ]
}

export const getBasicInfoFieldsForCurrentCustomer = (): FormTypes[] => {
  return [
    {
      name: "customer.companyName",
      placeholder: "Nazwa przedsiębiorstwa",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj nazwę przedsiębiorstwa",
      required: true,
      width: 6,
    },
    {
      name: "customer.taxNumber",
      placeholder: "NIP",
      validation: (el) => el.length < 10 || el.length > 15 || !/^[A-Z0-9-]+$/i.test(el),
      errorMsg: "Niepoprawny NIP",
      errorEmpty: "Podaj NIP",
      required: true,
      width: 6,
    },
    {
      name: "customer.firstName",
      placeholder: "Imię",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj imię",
      required: true,
      width: 3,
      disabled: true,
    },
    {
      name: "customer.lastName",
      placeholder: "Nazwisko",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj nazwisko",
      required: true,
      width: 3,
      disabled: true,
    },
    {
      name: "customer.email",
      placeholder: "Email",
      validation: (el) => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(el),
      errorMsg: "Niepoprawny adres E-mail",
      errorEmpty: "Podaj adres E-mail Klienta",
      required: true,
      width: 3,
    },
    {
      name: "customer.phone",
      placeholder: "Telefon",
      validation: (el) => !/^(([+][0-9]{2}|[0]{2}[0-9]{2}){0,1}[1-9][0-9]{8})$/i.test(el),
      errorMsg: "Niepoprawny numer telefonu",
      errorEmpty: "Podaj numer telefonu",
      required: true,
      width: 3,
    },
  ]
}

export const getOfferDataFields = (parmas: IGetOfferDataFieldsProps): FormTypes[] => {
  return [
    {
      name: "offerData.document.documentNumber",
      placeholder: "Wpisz numer PESEL",
      validation: parmas.documentNumberValidation,
      errorMsg: "Niepoprawny format dokumentu",
      errorEmpty: "Podaj PESEL lub numer paszportu",
      required: true,
    },
    {
      name: "offerData.address.street",
      placeholder: "Ulica",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    {
      name: "offerData.address.city",
      placeholder: "Miejscowość",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    {
      name: "offerData.correspondenceAddress.street",
      placeholder: "Ulica",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    {
      name: "offerData.correspondenceAddress.city",
      placeholder: "Miejscowość",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [5]
    {
      name: "offerData.partnerData.name",
      placeholder: "Imię i nazwisko pełnomocnika / drugiej osoby",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj imię i nazwisko pełnomocnika / drugiej osoby",
      required: true,
    },
    // [6]
    {
      name: "offerData.partnerData.document.documentNumber",
      placeholder: "Wpisz numer PESEL pełnomocnika / drugiej osoby",
      validation: (el) => !parmas.isValidPesel(el),
      errorMsg: "Niepoprawny format dokumentu",
      errorEmpty: "Podaj numer PESEL pełnomocnika / drugiej osoby",
      required: true,
    },
    // [7]
    {
      name: "offerData.ownershipSource",
      placeholder: "Jaki?",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj źródło tytułu prawnego",
      required: true,
    },
    // [8]
    {
      name: "offerData.energyDestination",
      placeholder: "Inne przeznaczenie obiektu",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj przeznaczenie obiektu",
      required: true,
    },
    // [9]
    {
      name: "offerData.eInvoiceAddress",
      placeholder: "E-mail",
      validation: (el) => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(el),
      errorMsg: "Niepoprawny adres E-mail",
      errorEmpty: "Podaj adres E-mail Klienta",
      required: true,
    },
    // [10]
    {
      name: "customer.PPE.city",
      placeholder: "Miejscowość",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znak",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [11]
    {
      name: "customer.PPE.nr1",
      placeholder: "Numer domu",
      validation: (el) => el.length < 1,
      errorMsg: "Wprowadź co najmniej 1 znak",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [12]
    {
      name: "customer.PPE.nr2",
      placeholder: "Numer mieszkania",
      validation: (el) => el.length < 1,
      errorMsg: "Wprowadź co najmniej 1 znak",
      errorEmpty: "Podaj dane adresowe",
      required: false,
    },
    // [13]
    {
      name: "customer.PPE.post",
      placeholder: "Kod pocztowy",
      validation: (el) => !/^\d{2}-\d{3}$/i.test(el),
      errorMsg: "Wprowadź poprawny kod pocztowy",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [14]
    {
      name: "customer.PPE.street",
      placeholder: "Ulica",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [15]
    {
      name: "offerData.jdgEnergyDestination",
      placeholder: "Inne",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj nazwę działaności gospodarczej",
      required: true,
    },
    // [16]
    {
      name: "offerData.address.post",
      placeholder: "Kod pocztowy",
      validation: (el) => !/^\d{2}-\d{3}$/i.test(el),
      errorMsg: "Wprowadź poprawny kod pocztowy",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [17]
    {
      name: "offerData.address.nr1",
      placeholder: "Numer domu",
      validation: (el) => el.length < 1,
      errorMsg: "Wprowadź co najmniej 1 znak",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [18]
    {
      name: "offerData.address.nr2",
      placeholder: "Numer mieszkania",
      validation: (el) => el.length < 1,
      errorMsg: "Wprowadź co najmniej 1 znak",
      errorEmpty: "Podaj dane adresowe",
      required: false,
    },
    // [19]
    {
      name: "offerData.correspondenceAddress.post",
      placeholder: "Kod pocztowy",
      validation: (el) => !/^\d{2}-\d{3}$/i.test(el),
      errorMsg: "Wprowadź poprawny kod pocztowy",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [22]
    {
      name: "offerData.correspondenceAddress.nr1",
      placeholder: "Numer domu",
      validation: (el) => el.length < 1,
      errorMsg: "Wprowadź co najmniej 1 znak",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [21]
    {
      name: "offerData.correspondenceAddress.nr2",
      placeholder: "Numer mieszkania",
      validation: (el) => el.length < 1,
      errorMsg: "Wprowadź co najmniej 1 znak",
      errorEmpty: "Podaj dane adresowe",
      required: false,
    },
    // [22]
    {
      name: "offerData.contractedPower",
      placeholder: "Moc w watach",
      validation: (el) => !/^\d+$/i.test(el),
      errorMsg: "Niepoprawna wartość",
      errorEmpty: "Podaj moc umowną",
    },
    // [23]
    {
      name: "offerData.commercialEnergyDestination",
      placeholder: "Inne przeznaczenie obiektu",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj przeznaczenie obiektu",
      required: true,
    },
  ]
}

export const getOfferDataFieldsForCurrentCustomer = (parmas: IGetOfferDataFieldsProps): FormTypes[] => {
  return [
    {
      name: "offerData.document.documentNumber",
      placeholder: "Wpisz numer dokumentu",
      validation: parmas.documentNumberValidation,
      errorMsg: "Niepoprawny format dokumentu",
      errorEmpty: "Podaj PESEL lub numer paszportu",
      required: true,
    },
    {
      name: "offerData.address.street",
      placeholder: "Ulica, nr domu/nr lokalu",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    {
      name: "offerData.address.city",
      placeholder: "Kod pocztowy i miejscowość",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    {
      name: "offerData.correspondenceAddress.street",
      placeholder: "Ulica, nr domu/nr lokalu",
      validation: (el) => el.length < 2,
      errorMsg: "Niepoprawny adres",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    {
      name: "offerData.correspondenceAddress.city",
      placeholder: "Kod pocztowy i miejscowość",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [5]
    {
      name: "offerData.partnerData.name",
      placeholder: "Imię i nazwisko pełnomocnika / drugiej osoby",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj imię i nazwisko pełnomocnika / drugiej osoby",
      required: true,
    },
    // [6]
    {
      name: "offerData.partnerData.document.documentNumber",
      placeholder: "Wpisz numer PESEL pełnomocnika / drugiej osoby",
      validation: (el) => !parmas.isValidPesel(el),
      errorMsg: "Niepoprawny format dokumentu",
      errorEmpty: "Podaj numer PESEL pełnomocnika / drugiej osoby",
      required: true,
    },
    // [7]
    {
      name: "offerData.ownershipSource",
      placeholder: "Jaki?",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj źródło tytułu prawnego",
      required: true,
    },
    // [8]
    {
      name: "offerData.energyDestination",
      placeholder: "Inne gospodarstwa domowe",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj nazwę gospodarstwa domowego",
      required: true,
    },
    // [9]
    {
      name: "offerData.eInvoiceAddress",
      placeholder: "E-mail",
      validation: (el) => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(el),
      errorMsg: "Niepoprawny adres E-mail",
      errorEmpty: "Podaj adres E-mail Klienta",
      required: true,
    },
    // [10]
    {
      name: "customer.PPE.street",
      placeholder: "Ulica, nr domu/nr lokalu",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: true,
      disabled: true,
    },
    // [11]
    {
      name: "customer.PPE.nr1",
      placeholder: "Numer domu",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [12]
    {
      name: "customer.PPE.nr2",
      placeholder: "Numer mieszkania",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: false,
    },
    // [13]
    {
      name: "customer.PPE.post",
      placeholder: "Kod pocztowy",
      validation: (el) => !/^\d{2}-\d{3}$/i.test(el),
      errorMsg: "Wprowadź poprawny kod pocztowy",
      errorEmpty: "Podaj dane adresowe",
      required: true,
    },
    // [14]
    {
      name: "customer.PPE.city",
      placeholder: "Kod pocztowy i miejscowość",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj dane adresowe",
      required: true,
      disabled: true,
    },
    // [15]
    {
      name: "offerData.jdgEnergyDestination",
      placeholder: "Inne",
      validation: (el) => el.length < 2,
      errorMsg: "Wprowadź co najmniej 2 znaki",
      errorEmpty: "Podaj nazwę działaności gospodarczej",
      required: true,
    },
    // [16]
    {
      name: "customer.customerNumber",
      placeholder: "Numer płatnika",
      validation: (el) => !/^\w+/i.test(el),
      errorMsg: "Niepoprawny numer płatnika",
      errorEmpty: "Podaj numer płatnika",
      width: 3,
      required: true,
    },
    // [17]
    {
      name: "customer.ppeNumber",
      placeholder: "Numer punktu poboru",
      validation: (el) => !/^(PLPOLD)\w+/i.test(el),
      errorMsg: "Niepoprawny numer punktu poboru",
      errorEmpty: "Podaj numer punktu poboru",
      width: 3,
      required: true,
    },
    // [18]
    {
      name: "customer.pesel",
      placeholder: "Numer PESEL",
      errorMsg: "Niepoprawny numer PESEL",
      errorEmpty: "Podaj numer PESEL",
      width: 3,
      required: true,
      validation: (el) => !parmas.isValidPesel(el),
    },
  ]
}
