import React from "react"
import {
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Popover,
} from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fpContainer: {
      position: "fixed",
      bottom: 120,
      right: 5,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    fpPopover: {},
    fpContentContainer: {
      textAlign: "center",
    },
    fpHeader: {
      color: "#12BEAB",
      fontSize: "12px",
      fontWeight: 800,
      lineHeight: "12px",
    },
    fpPhone: {
      color: "#f2a900",
      fontSize: "22px",
      fontWeight: 800,
      lineHeight: "22px",
      textDecoration: "none",
    },
    fpAvailability: {
      color: "#333",
      fontSize: "11px",
      fontWeight: 800,
      lineHeight: "11px",
    },
    fpDescription: {
      color: "#333",
      fontSize: "11px",
      fontWeight: 400,
      lineHeight: "11px",
    },
  })
)

const FlyingPhone: React.FC = () => {
  const styles = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <div className={styles.fpContainer}>
      <Popover
        className={styles.fpPopover}
        disableScrollLock
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div className={styles.fpContentContainer}>
          <p className={styles.fpHeader}>Masz pytania? Zadzwoń</p>
          <p>
            <a className={styles.fpPhone} href="tel: +48 699 606 707">
              +48 699 606 707
            </a>
          </p>
          <p className={styles.fpAvailability}>
            Nasi konsultanci są dla Ciebie dostępni:
          </p>
          <p className={styles.fpAvailability}>pon - pt, 8:00 - 18:00</p>
          <p className={styles.fpDescription}>
            (Cena za połączenie zgodnie z taryfą Twojego operatora)
          </p>
        </div>
      </Popover>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
          version="1.1"
          id="svg25"
        >
          <circle cx="38" cy="38" fill="#f2a900" id="circle2" r="28" />
          <path
            d="m 38,26.1875 c 6.5127,0 11.8125,5.2998 11.8125,11.8125 0,6.5127 -5.2998,11.8125 -11.8125,11.8125 C 31.4873,49.8125 26.1875,44.5127 26.1875,38 26.1875,31.4873 31.4873,26.1875 38,26.1875 Z M 38,24.5 c -7.4553,0 -13.5,6.0447 -13.5,13.5 0,7.4553 6.0447,13.5 13.5,13.5 7.4553,0 13.5,-6.0447 13.5,-13.5 0,-7.4553 -6.0447,-13.5 -13.5,-13.5 z m 7.5262,18.0137 -0.0758,-0.2307 c -0.178,-0.5306 -0.7647,-1.0843 -1.3052,-1.2326 L 42.1496,40.5049 C 41.609,40.3582 40.8378,40.556 40.4407,40.9531 L 39.7188,41.675 C 37.0953,40.9647 35.037,38.9064 34.3284,36.2829 l 0.7218,-0.7219 c 0.3955,-0.3955 0.5933,-1.1667 0.4466,-1.7089 L 34.953,31.8564 c -0.1467,-0.5405 -0.7037,-1.1272 -1.2343,-1.3051 l -0.2275,-0.0759 c -0.5323,-0.1779 -1.292,0.0017 -1.6875,0.3989 l -1.081,1.081 c -0.1928,0.1912 -0.3165,0.7399 -0.3165,0.7416 -0.0362,3.4311 1.3085,6.7352 3.7343,9.1626 2.4192,2.4192 5.7118,3.7623 9.1313,3.7326 0.0181,0 0.5834,-0.1203 0.7762,-0.3131 l 1.0811,-1.0811 c 0.3955,-0.3938 0.5734,-1.1535 0.3971,-1.6842 z"
            fill="#ffffff"
            id="path6"
          />
        </svg>
      </IconButton>
    </div>
  )
}

export default FlyingPhone
