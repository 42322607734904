import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import { SectionNumber } from "./sectionNumber"

export interface SectionHeaderProps {
  id: string
  sectionId: number
  header: string
}

export const SectionHeader: React.FC<SectionHeaderProps> = (
  props: SectionHeaderProps
) => {
  const useStyles = makeStyles((theme: Theme) => ({
    headerContainer: {
      textAlign: "center",
      paddingBottom: "40px",
      paddingTop: "8px",
      display: "inline-flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        "& p": {
          display: "block",
        },
      },
      "& svg": {
        height: "56px",
        width: "56px",
      },
    },
    sectionHeader: {
      fontSize: "24px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "0.32px",
      color: "#414141",
      paddingLeft: "8px",
    },
    center: {
      textAlign: "center",
    },
  }))

  const classes = useStyles()

  return (
    <div className={classes.center} id={props.id}>
      <div className={classes.headerContainer}>
        <SectionNumber id={props.sectionId} />
        <label className={classes.sectionHeader}>{props.header}</label>
      </div>
    </div>
  )
}
