import React from "react"
import theme from "../../../styles/theme"
import { FormTypes } from "./formularz"
import { EWallBoxType } from "../../../models/OffersEnums"
import {
  EDocumentType,
  EServiceType,
  EServiceOptionType,
  EOfferType,
  EAddressType,
  ECorrespondenceAddressType,
  EStatementType,
  EPartnerAddressType,
  EOwnershipType,
  EOwnershipSourceType,
  EEnergyDestinationType,
  EJdgEnergyDestinationType,
  EEInvoiceType,
  EEInvoiceAddressType,
  EAggreeType,
  EOfferDocumentsAnhor,
  EOfferDuration,
  EPlugInType,
} from "../../../models/OffersEnums"

export const fileField: FormTypes = {
  name: "file",
  conditionalPlaceholder: (file) =>
    file ? (
      <p>
        Plik <span style={{ fontWeight: theme.typography.fontWeightBold }}>{file.file.name} </span>
        został załączony
      </p>
    ) : (
      <p>Skan lub zdjęcie aktualnej umowy z&nbsp;Twoim obecnym dostawca energii elektrycznej</p>
    ),
}

export const currentCustomerOfferData = [
  {
    customerTabData: {
      comercial: {
        taxNumber: "",
        customerNumber: "",
        companyName: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        street: "",
        city: "",
      },
      customer: {
        pesel: "",
        passportNumber: "",
        residenceCardNumber: "",
        customerNumber: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        street: "",
        city: "",
      },
    },
    customer: {
      commercialClient: false,
      foreignClient: false,
      companyName: "",
      taxNumber: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      PzoZot: "",
      meterNumber: "",
      phoneAgree: false,
      emailAgree: false,
      PPE: {
        street: "",
        nr1: "",
        nr2: "",
        post: "",
        city: "",
      },
      customerNumber: "",
      ppeNumber: "",
      pesel: "",
    },
    offer: {
      offerType: EOfferType.PRICE,
      offerDuration: EOfferDuration.TWO,
      isLong: true,
      offers: [
        {
          type: EOfferType.PRICE,
          title: "Gwarancja Ceny",
          subtitle: "Wybieram cennik produktowy z gwarancją ceny",
          description: (
            <span>
              Zastanawiasz się, ile będzie kosztowała energia elektryczna w&nbsp;przyszłości? Chcesz mieć komfort zakupu
              energii po stałej cenie w&nbsp;okresie 2&nbsp;lub 3&nbsp;lat? Skorzystaj z&nbsp;oferty Gwarancja Ceny
              i&nbsp;zabezpiecz się przed wzrostem cen. Wybierając Cennik z&nbsp;Gwarancją Ceny otrzymujesz stałą cenę
              energii z&nbsp;dnia podpisania Umowy przez wybrany przez Ciebie okres 2&nbsp;lub 3&nbsp;lat.
            </span>
          ),
          anhor: EOfferDocumentsAnhor.PRICE,
          img: "gwarancjaCeny",
          document: {
            name: "Cennik Produktowy z Gwarancją Ceny",
            path: "/Cennik_G-siec_POLD-gwarancja_ceny.pdf",
          },
        },
        {
          type: EOfferType.DISCOUNT,
          title: "Gwarancja Rabatu",
          subtitle: "Wybieram cennik produktowy z gwarancją rabatu",
          description: (
            <span>
              Nie chcesz przepłacać? Chcesz mieć zagwarantowany rabat przez cały okres obowiązywania Cennika? Wybierz
              Cennik z&nbsp;Gwarancją Rabatu na 2&nbsp;lub 3&nbsp;lata. Jeśli skorzystasz z&nbsp;oferty otrzymasz od nas
              stawkę zmienną za energię elektryczną niższą o&nbsp;gwarantowane 5&nbsp;%&nbsp;od stawek zmiennych cen
              wynikających z&nbsp;Taryfy.
            </span>
          ),
          anhor: EOfferDocumentsAnhor.PRICE,
          img: "gwarancjaRabatu",
          document: {
            name: "Cennik Produktowy z Gwarancją Rabatu",
            path: "/Cennik_G-siec_POLD-gwarancja_rabatu_5procent.pdf",
          },
        },
      ],
    },
    service: {
      serviceType: EServiceType.EXPERT,
      serviceOptionType: EServiceOptionType.EXPERT_PLUS,
      services: [
        {
          title: "Pomoc fachowców",
          path: "/Fachowiec.pdf",
          option: {
            placeholder: "",
            boxes: [
              {
                description: "Fachowiec Standard",
                type: EServiceOptionType.EXPERT_STANDARD,
              },
              {
                description: "Fachowiec Plus",
                type: EServiceOptionType.EXPERT_PLUS,
              },
              {
                description: "Fachowiec Premium",
                type: EServiceOptionType.EXPERT_PREMIUM,
              },
            ],
          },
        },
        {
          title: "Pomocny pakiet",
          path: "/Pomocny_Pakiet.pdf",
          option: {
            placeholder: "",
            boxes: [
              {
                description: "Pomocny pakiet Standard",
                type: EServiceOptionType.HELPFUL_STANDARD,
              },
              {
                description: "Pomocny pakiet Plus",
                type: EServiceOptionType.HELPFUL_PLUS,
              },
            ],
          },
        },
        {
          title: "Brak usług",
          path: "",
          option: {
            placeholder: "Nie wybrano usług dodatkowych",
            boxes: [],
          },
        },
      ],
    },
    documents: [
      {
        id: EOfferDocumentsAnhor.PRICE,
        header: "Ważne Dokumenty",
        papers: [
          {
            name: "Umowa - konsument",
            path: "/UMOWA-siec_POLD-Komplex-konsument.pdf",
          },
          {
            name: "Umowa - działalność",
            path: "/UMOWA-siec_POLD-Komplex-dzialalnosc.pdf",
          },
          {
            name: "OWU - konsument działalność",
            path: "/OWU-siec_POLD_kompleks_G-konsum_dzialalnosc.pdf",
          },
          {
            name: "Wybór oferty - konsument",
            path: "/Wybor_oferty_zgody_RODO_klauzula-siec_POLD_G_konsument.pdf",
          },
          {
            name: "Wybór oferty - działalność",
            path: "/Wybor_oferty_zgody_RODO_klauzula-siec_POLD_G_dzialalnosc.pdf",
          },
        ],
      },
      {
        id: EOfferDocumentsAnhor.DISCOUNT,
        header: "",
        papers: [
          {
            name: "Taryfa",
            path: "/Wyciag_z_taryfy_POLD_10.2020_Warszawa.pdf",
          },
          {
            name: "Regulamin usługi Fachowiec",
            path: "/Regulamin_Uslugi_Fachowiec.pdf",
          },
          {
            name: "Regulamin usługi Pomocny Pakiet",
            path: "/Regulamin_Uslugi_Pomocny_Pakiet.pdf",
          },
          {
            name: "Zbiór praw konsumenta",
            path: "/Zbior_praw_konsumenta_energii_elektrycznej__wersja_kolorowa_.pdf",
          },
          {
            name: "Odstąpienie od umowy",
            path: "/Odstąpienie_od_umowy.pdf",
          },
        ],
      },
    ],
    commercialDocuments: [
      {
        id: EOfferDocumentsAnhor.STANDARD,
        header: "Ważne Dokumenty",
        papers: [
          {
            name: "Umowa klient komercyjny koncesja",
            path: "/UmowaKompleksowaKlientBiznesowykoncesjaCXorazkomercyjneGX.pdf",
          },
          {
            name: "OWU klient komercyjny",
            path: "/OgolneWarunkiUmowybizkompleks.pdf",
          },
        ],
      },
    ],
    offerData: {
      document: {
        documentType: EDocumentType.PESEL,
        documentNumber: "",
      },
      addressType: EAddressType.NONE,
      address: {
        street: "",
        city: "",
      },
      correspondenceAddressType: ECorrespondenceAddressType.NONE,
      correspondenceAddress: {
        street: "",
        city: "",
      },
      statementType: EStatementType.SELF,
      partnerData: {
        name: "",
        document: {
          documentType: EDocumentType.PESEL,
          documentNumber: "",
        },
      },
      partnerAddressType: EPartnerAddressType.NONE,
      partnerAddress: {
        street: "",
        city: "",
      },
      ownershipType: EOwnershipType.YES,
      ownershipSourceType: EOwnershipSourceType.NOTARIAL_ACT,
      ownershipSource: "",
      energyDestinationType: EEnergyDestinationType.FLAT,
      energyDestination: "",
      jdgEnergyDestinationType: EJdgEnergyDestinationType.RENTING,
      jdgEnergyDestination: "",
      eInvoiceType: EEInvoiceType.NONE,
      eInvoiceAddressType: EEInvoiceAddressType.NONE,
      eInvoiceAddress: "",
      aggreeType: EAggreeType.NONE,
      plugin: EPlugInType.NONE,
      wallBox: EWallBoxType.NONE,
    },
    fileField: fileField,
  },
]
