import React, { Fragment } from "react"
import { Field } from "formik"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Grid, FormControlLabel, Radio, Button } from "@material-ui/core"
import { Theme, makeStyles } from "@material-ui/core/styles"
import { Input } from "@material-ui/icons"

import { CustomInput } from "./Input"
import RadioButtonsGroup from "./controls/RadioButtonsGroup"
import { EPowerOption } from "../models/OffersEnums"

interface WattageSelectorProps {
  inputFieldValue: any
  inputField: any
  showFieldError: boolean
  setFieldValue: any
  inputMetaError: string
  powerData: PowerData | null
  showSendUsEmailModal: () => void
}

export interface PowerData {
  defaultContractedPower: number
  minimumContractedPower: number
  maximumContractedPower: number
  unit: string
}

const useStyles = makeStyles((theme: Theme) => ({
  radioGroup: {
    display: "flex",
    flexDirection: "column",
  },
  infoPopup: {
    position: "absolute",
    top: "-150px",
    backgroundColor: theme.palette.primary.main,
    width: "327px",
    color: "#FFFFFF",
    zIndex: 99999,
    borderRadius: "5px",
    padding: "0 16px",
  },
  loadingButtonWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  fillPpeButton: {
    paddingLeft: "20px",
    paddingRight: "20px",
    minWidth: "126px",
    height: "37px",
    borderRadius: "7px",
    borderSize: "2.5px",
    padding: 0,
    fontSize: "12px",
    letterSpacing: "0.22px",
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      minWidth: "206px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "170px",
    },
    width: "100%",
  },
  buttonProgress: {
    color: "#00BDA8",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}))

const WattageSelector: React.FC<WattageSelectorProps> = ({
  inputFieldValue,
  inputField,
  showFieldError,
  setFieldValue,
  inputMetaError,
  powerData,
  showSendUsEmailModal,
}) => {
  const classes = useStyles()

  const powerOptions = powerData
    ? [
        {
          description: `${powerData.defaultContractedPower} ${powerData.unit}`,
          value: EPowerOption.DEFAULT,
        },
        {
          description: `Inna (wpisz liczbę bez miejsc po przecinku z zakresu od ${powerData.minimumContractedPower} ${powerData.unit} do ${powerData.maximumContractedPower} ${powerData.unit}):`,
          value: EPowerOption.CUSTOM,
        },
        {
          description: `Jeśli potrzebujesz mocy umownej o wartości wyższej niż wartość do ${powerData.maximumContractedPower} ${powerData.unit}, skontaktuj się z nami - sprawdzimy możliwości techniczne i przygotujemy dla Ciebie umowę`,
          value: EPowerOption.OTHER,
        },
      ]
    : []

  return (
    <Grid item xs={12} style={{ marginLeft: "16px" }}>
      {powerData === null ? (
        <>
          <p>
            W celu wybrania mocy umownej, najpierw musisz uzupełnić numery PZO/ZOT oraz licznika, a następnie pobrać
            dane.
          </p>
          <Grid item xs={3}>
            <div className={classes.loadingButtonWrapper}>
              <Button
                disabled={false}
                startIcon={<Input />}
                variant="outlined"
                color="primary"
                className={classes.fillPpeButton}
                component="span"
                onClick={() => scrollTo("#get-meters-data", "center")}
              >
                Uzupełnij dane
              </Button>
            </div>
          </Grid>
        </>
      ) : (
        <Field name={"offerData.contractedPowerType"}>
          {({ field, form, meta }) => {
            return (
              <>
                <RadioButtonsGroup
                  documentType={field.value}
                  form={form}
                  field={field}
                  className={classes.radioGroup}
                  horizontal={true}
                  setValue={(value) => {
                    setFieldValue("offerData.contractedPowerType", value)
                    if (value !== EPowerOption.CUSTOM) {
                      setFieldValue("offerData.contractedPower", "")
                    }
                  }}
                >
                  {powerOptions.map((option, index) => (
                    <Fragment key={`${option.value}-${index}`}>
                      <FormControlLabel
                        value={option.value}
                        control={<Radio />}
                        label={option.description}
                        onClick={() => {
                          index === 2 && showSendUsEmailModal()
                        }}
                      />
                      {index === 1 ? (
                        <Grid item xs={12} md={3} style={{ margin: "16px 0", position: "relative" }}>
                          <CustomInput
                            field={inputField}
                            metaError={inputMetaError}
                            showError={showFieldError}
                            placeholder={`Moc w ${powerData.unit}`}
                            onClick={() => {}}
                            disabled={Boolean(field.value !== EPowerOption.CUSTOM)}
                          />
                          {field.value === EPowerOption.CUSTOM &&
                          index === 1 &&
                          powerData &&
                          inputFieldValue !== "" &&
                          (Number(inputFieldValue) > powerData?.maximumContractedPower ||
                            Number(inputFieldValue) < powerData.minimumContractedPower) ? (
                            <div className={classes.infoPopup}>
                              <p>
                                Podaj wartość z opisanego zakresu. Jeśli potrzebujesz mocy umownej wyższej niż wartość
                                maksymalna z opisanego zakresu, skontaktuj się z nami, sprawdzimy możliwości techniczne
                                i przygotujemy dla Ciebie umowę
                              </p>
                            </div>
                          ) : null}
                        </Grid>
                      ) : null}
                    </Fragment>
                  ))}
                </RadioButtonsGroup>
              </>
            )
          }}
        </Field>
      )}
    </Grid>
  )
}

export default WattageSelector
