import {
  Theme,
  makeStyles,
  FormControlLabel,
  Radio,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"
import { Field } from "formik"
import React from "react"
import RadioButtonsGroup from "../controls/RadioButtonsGroup"
import { consents } from "./static-data/formularz"

export interface ConsentPanelProps {
  phoneFiledName: string
  emailFiledName: string
  setPhoneAgree: (value: boolean) => void
  setEmailAgree: (value: boolean) => void
}

export interface ConsentProps {
  header: string
  content: React.ReactNode
  fieldName: string
  setAgree: (value: boolean) => void
}

export const Consent: React.FC<ConsentProps> = (props: ConsentProps) => {
  const useStyles = makeStyles((theme: Theme) => ({
    radioGroup: {},
    agreeContainer: {
      display: "flex",
      flexDirection: "row",
    },
    smallAgreeContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    contentContainer: {
      flex: 1,
      paddingRight: "40px",
      "& p": {
        margin: "0px",
        textAlign: "justify",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.43,
        letterSpacing: "0.24px",
        color: "#414141",
      },
    },
    smallContentContainer: {
      flex: 1,
      paddingRight: "0px",
      "& p": {
        margin: "0px",
        textAlign: "justify",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.43,
        letterSpacing: "0.24px",
        color: "#414141",
      },
    },
    headerContainer: {
      paddingBottom: "8px",
      paddingTop: "24px",
    },
    header: {
      fontSize: "16px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "0.64px",
      textAlign: "left",
      color: "#414141",
    },
    labelPlacement: {
      textAlign: "left",
    },
  }))

  const classes = useStyles()
  const theme = useTheme<Theme>()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <div>
      <div className={classes.headerContainer}>
        <label className={classes.header}>{props.header}</label>
      </div>
      <div
        className={
          smallScreen ? classes.smallAgreeContainer : classes.agreeContainer
        }
      >
        <div
          className={
            smallScreen
              ? classes.smallContentContainer
              : classes.contentContainer
          }
        >
          <p>{props.content}</p>
        </div>
        <div>
          <Field name={props.fieldName}>
            {({ field, form, meta }) => {
              return (
                <RadioButtonsGroup
                  className={classes.radioGroup}
                  documentType={field.value ? "TAK" : "NIE"}
                  form={form}
                  field={field}
                  horizontal={true}
                  setValue={value => {
                    props.setAgree(value === "TAK")
                  }}
                >
                  <FormControlLabel
                    key={0}
                    value={"TAK"}
                    control={
                      <Radio
                        icon={<CheckBoxOutlineBlank />}
                        checkedIcon={<CheckBox />}
                      />
                    }
                    label={"TAK"}
                    className={classes.labelPlacement}
                  />
                  <FormControlLabel
                    key={1}
                    value={"NIE"}
                    control={
                      <Radio
                        icon={<CheckBoxOutlineBlank />}
                        checkedIcon={<CheckBox />}
                      />
                    }
                    label={"NIE"}
                    className={classes.labelPlacement}
                  />
                </RadioButtonsGroup>
              )
            }}
          </Field>
        </div>
      </div>
    </div>
  )
}

export const ConsentPanel: React.FC<ConsentPanelProps> = (
  props: ConsentPanelProps
) => {
  const useStyles = makeStyles((theme: Theme) => ({
    consentContainer: {
      maxWidth: "900px",
      margin: "auto",
    },
  }))

  const classes = useStyles()

  return (
    <div>
      <div className={classes.consentContainer}>
        <Consent
          header={consents[0].placeholder}
          content={consents[0].description}
          setAgree={props.setPhoneAgree}
          fieldName={props.phoneFiledName}
        />
        <Consent
          header={consents[1].placeholder}
          content={consents[1].description}
          setAgree={props.setEmailAgree}
          fieldName={props.emailFiledName}
        />
      </div>
    </div>
  )
}
