import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import { createStyles, useTheme } from "@material-ui/styles"
import { maxWidth, relativeContentPadding } from "../../styles/constants"
import { SmallLogo } from "../images/smallLogo"
import { EDocumentVersion } from "../../models/OffersEnums"
import StyledButton from "./../Button"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    simpleContainer: {
      position: "relative",
      ...relativeContentPadding(),
    },
    overlay: {
      zIndex: 0,
    },
    topContainer: {
      ...relativeContentPadding,
    },
    numberContainerOuter: {
      borderRadius: "50%",
      width: "50px",
      height: "50px",
      backgroundColor: "red",
      position: "relative",
    },
    numberContainerInner: {
      borderRadius: "50%",
      width: "46px",
      height: "46px",
      backgroundColor: "white",
      position: "absolute",
      top: "2px",
      left: "2px",
      textAlign: "center",
      "& p": {
        lineHeight: "46px",
      },
    },
    cardsContainer: {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      overflow: "hidden",
      flexDirection: "row",
      paddingBottom: "80px",
      "& .MuiCard-root": {
        // margin: "20px 30px",
        flex: 1,
        [theme.breakpoints.down("lg")]: {
          margin: "20px 15px",
        },
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    infoContainer: {
      ...maxWidth,
      display: "flex",
      alignItems: "flex-start",
      overflow: "hidden",
      flexDirection: "row",
      paddingBottom: "80px",
      "& .MuiCard-root": {
        // margin: "20px 30px",
        flex: 1,
        [theme.breakpoints.down("lg")]: {
          margin: "20px 15px",
        },
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
      },
      paddingTop: "80px",
    },
    endSection: {
      paddingTop: "100px",
      paddingBottom: "100px",
      margin: 0,
      marginRight: "-30px",
      [theme.breakpoints.down("lg")]: {
        marginRight: "-15px",
      },
      [theme.breakpoints.down("md")]: {
        width: "auto",
        margin: 0,
        maxWidth: "100%",
      },
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    endCard: {
      paddingTop: "60px",
      textAlign: "center",
    },
    endCardBoldP: {
      fontWeight: 700,
      lineHeight: "17px",
      marginBottom: "20px",
    },
    endCardNormalP: {
      lineHeight: "17px",
      marginBottom: "20px",
    },
    formSection: {
      margin: 0,
      marginRight: "-30px",
      [theme.breakpoints.down("lg")]: {
        marginRight: "-15px",
      },
      [theme.breakpoints.down("md")]: {
        width: "auto",
        margin: 0,
        maxWidth: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        // display: "none",
      },
      width: "100%",
      position: "relative",
      ...relativeContentPadding(),
      [theme.breakpoints.down("lg")]: {
        paddingLeft: "95px",
        paddingRight: "95px",
      },
    },
    formButton: {
      position: "fixed",
      bottom: "12px",
      right: "12px",
      width: "65px",
      height: "65px",
      borderRadius: "32.5px",
      zIndex: 999,
    },
    mainCard: {
      flex: "1 1 auto !important",
      width: "100%",
    },
    clickableCard: {
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
    },
    infoSection: {
      display: "flex",
      flexWrap: "wrap",
      flex: "1 1 auto",
      margin: 0,
      marginLeft: "-30px",
      "& h2": {
        textAlign: "center",
        marginBottom: "20px",
        marginTop: 0,
      },
      "& b": {
        marginBottom: "15px",
        display: "block",
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: "-15px",
      },
      [theme.breakpoints.down("md")]: {
        margin: 0,
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        margin: 0,
        "& .MuiCard-root": {
          margin: "20px 0",
          flex: 1,
          width: "100%",
        },
      },
    },
    infoImg: {
      height: "145px",
      marginBottom: "20px",
      borderRadius: "8px",
    },
    endCardContainer: {
      maxWidth: "660px",
      background: "#12BEAB",
    },
    iconContainer: {
      marginBottom: "-50px",
      position: "relative",
      textAlign: "center",
      zIndex: 1,
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
    button: {
      borderColor: "#12beab",
      color: "#12beab",
      display: "block",
      margin: "30px auto",
      marginBottom: "0",
      width: "60%",
      height: "48px",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        width: "60vw",
      },
    },
  })
)

interface ILightsOnSummaryProps {
  setShowSummary?: any
  documentVersion: EDocumentVersion
}

export const LightsOnSummary: React.FC<ILightsOnSummaryProps> = ({
  setShowSummary,
  documentVersion,
}) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()

  return (
    <div className={styles.endSection}>
      <Card className={styles.endCardContainer}>
        <div className={styles.iconContainer}>
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M96.7969 48.3789C96.7969 59.8828 92.7344 70.4492 85.9766 78.6914C84.9609 77.5781 83.7891 76.5039 82.5 75.4883C74.8047 69.4726 62.6367 65.5664 48.9453 65.5664C35.2539 65.5664 23.0859 69.4531 15.3906 75.4883C14.1016 76.5039 12.9297 77.5781 11.9141 78.6914C5.15625 70.4297 1.09375 59.8633 1.09375 48.3789C1.09375 21.9531 22.5195 0.527344 48.9453 0.527344C75.3711 0.527344 96.7969 21.9336 96.7969 48.3789Z"
              fill="#E4F9F5"
            />
            <path
              d="M91.0547 50.0195C91.0547 59.5898 87.8711 68.418 82.4805 75.4883C74.7852 69.4727 62.6172 65.5664 48.9258 65.5664C35.2344 65.5664 23.0664 69.4531 15.3711 75.4883C10.0195 68.418 6.83594 59.5898 6.83594 50.0195C6.83594 26.7578 25.6836 7.91016 48.9453 7.91016C72.207 7.91016 91.0547 26.7578 91.0547 50.0195Z"
              fill="#CEF2EC"
            />
            <path
              d="M74.6289 88.3398H23.2812C21.6211 88.3398 20.2734 86.9922 20.2734 85.332V50.8789C20.2734 49.2187 21.6211 47.8711 23.2812 47.8711H74.6289C76.2891 47.8711 77.6367 49.2187 77.6367 50.8789V85.3125C77.6367 86.9922 76.2891 88.3398 74.6289 88.3398ZM23.2812 50.0195C22.8125 50.0195 22.4219 50.4102 22.4219 50.8789V85.3125C22.4219 85.7812 22.8125 86.1719 23.2812 86.1719H74.6289C75.0976 86.1719 75.4883 85.7812 75.4883 85.3125V50.8789C75.4883 50.4102 75.0976 50.0195 74.6289 50.0195H23.2812Z"
              fill="#12BEAB"
            />
            <path
              d="M52.2461 92.793H45.6445V86.1914H52.2461V92.793ZM47.793 90.6445H50.0977V88.3398H47.793V90.6445Z"
              fill="#12BEAB"
            />
            <path
              d="M59.8633 97.2461H38.0469C36.3867 97.2461 35.0391 95.8984 35.0391 94.2383V93.6719C35.0391 92.0117 36.3867 90.6641 38.0469 90.6641H59.8633C61.5234 90.6641 62.8711 92.0117 62.8711 93.6719V94.2383C62.8711 95.8984 61.5234 97.2461 59.8633 97.2461ZM38.0469 92.793C37.5781 92.793 37.1875 93.1836 37.1875 93.6523V94.2187C37.1875 94.6875 37.5781 95.0781 38.0469 95.0781H59.8633C60.332 95.0781 60.7227 94.6875 60.7227 94.2187V93.6523C60.7227 93.1836 60.332 92.793 59.8633 92.793H38.0469Z"
              fill="#12BEAB"
            />
            <path
              d="M71.6797 37.9687V69.8828C71.6797 70.4688 71.1914 70.957 70.6055 70.957H26.6406C26.0547 70.957 25.5664 70.4688 25.5664 69.8828V33.6914C25.5664 33.1055 26.0547 32.6172 26.6406 32.6172H66.3477C66.6406 32.6172 66.9141 32.7344 67.1094 32.9297L71.3672 37.1875C71.5625 37.4023 71.6797 37.6758 71.6797 37.9687Z"
              fill="white"
            />
            <path
              d="M71.3672 37.207H66.8945V32.7344L71.3672 37.207Z"
              fill="#12BEAB"
            />
            <path
              d="M63.8086 42.207H42.1484C41.5625 42.207 41.0742 41.7188 41.0742 41.1328C41.0742 40.5469 41.5625 40.0586 42.1484 40.0586H63.8086C64.3945 40.0586 64.8828 40.5469 64.8828 41.1328C64.8828 41.7188 64.4141 42.207 63.8086 42.207Z"
              fill="black"
            />
            <path
              d="M35.332 38.0273C33.6133 38.0273 32.2266 39.4141 32.2266 41.1328C32.2266 42.8516 33.6133 44.2383 35.332 44.2383C37.0508 44.2383 38.4375 42.8516 38.4375 41.1328C38.418 39.4141 37.0312 38.0273 35.332 38.0273ZM35.332 43.6133C33.9648 43.6133 32.8516 42.5 32.8516 41.1328C32.8516 39.7656 33.9648 38.6523 35.332 38.6523C36.6992 38.6523 37.8125 39.7656 37.8125 41.1328C37.8125 42.5 36.6992 43.6133 35.332 43.6133ZM36.5234 39.9805L34.9219 41.582C34.8047 41.6992 34.6094 41.6992 34.4922 41.582L34.1211 41.2109C34.0039 41.0938 33.8086 41.0938 33.6914 41.2109C33.5742 41.3281 33.5742 41.5234 33.6914 41.6406L34.4922 42.4414C34.6094 42.5586 34.8047 42.5586 34.9219 42.4414L36.9727 40.3906C37.0898 40.2734 37.0898 40.0781 36.9727 39.9609C36.8555 39.8438 36.6406 39.8437 36.5234 39.9805Z"
              fill="#12BEAB"
            />
            <path
              d="M63.8086 52.8516H42.1484C41.5625 52.8516 41.0742 52.3633 41.0742 51.7773C41.0742 51.1914 41.5625 50.7031 42.1484 50.7031H63.8086C64.3945 50.7031 64.8828 51.1914 64.8828 51.7773C64.8828 52.3633 64.4141 52.8516 63.8086 52.8516Z"
              fill="black"
            />
            <path
              d="M35.332 48.6914C33.6133 48.6914 32.2266 50.0781 32.2266 51.7969C32.2266 53.5156 33.6133 54.9024 35.332 54.9024C37.0508 54.9024 38.4375 53.5156 38.4375 51.7969C38.418 50.0781 37.0312 48.6914 35.332 48.6914ZM35.332 54.2578C33.9648 54.2578 32.8516 53.1445 32.8516 51.7773C32.8516 50.4102 33.9648 49.2969 35.332 49.2969C36.6992 49.2969 37.8125 50.4102 37.8125 51.7773C37.8125 53.1445 36.6992 54.2578 35.332 54.2578ZM36.5234 50.6445L34.9219 52.2461C34.8047 52.3633 34.6094 52.3633 34.4922 52.2461L34.1211 51.875C34.0039 51.7578 33.8086 51.7578 33.6914 51.875C33.5742 51.9922 33.5742 52.1875 33.6914 52.3047L34.4922 53.1055C34.6094 53.2227 34.8047 53.2227 34.9219 53.1055L36.9727 51.0547C37.0898 50.9375 37.0898 50.7422 36.9727 50.625C36.8555 50.5078 36.6406 50.5078 36.5234 50.6445Z"
              fill="#12BEAB"
            />
            <path
              d="M63.8086 64.1211H42.1484C41.5625 64.1211 41.0742 63.6328 41.0742 63.0469C41.0742 62.4609 41.5625 61.9727 42.1484 61.9727H63.8086C64.3945 61.9727 64.8828 62.4609 64.8828 63.0469C64.8828 63.6328 64.4141 64.1211 63.8086 64.1211Z"
              fill="black"
            />
            <path
              d="M35.332 59.9414C33.6133 59.9414 32.2266 61.3281 32.2266 63.0469C32.2266 64.7656 33.6133 66.1523 35.332 66.1523C37.0508 66.1523 38.4375 64.7656 38.4375 63.0469C38.418 61.3281 37.0312 59.9414 35.332 59.9414ZM35.332 65.5273C33.9648 65.5273 32.8516 64.4141 32.8516 63.0469C32.8516 61.6797 33.9648 60.5664 35.332 60.5664C36.6992 60.5664 37.8125 61.6797 37.8125 63.0469C37.8125 64.4141 36.6992 65.5273 35.332 65.5273ZM36.5234 61.8945L34.9219 63.4961C34.8047 63.6133 34.6094 63.6133 34.4922 63.4961L34.1211 63.125C34.0039 63.0078 33.8086 63.0078 33.6914 63.125C33.5742 63.2422 33.5742 63.4375 33.6914 63.5547L34.4922 64.3555C34.6094 64.4727 34.8047 64.4727 34.9219 64.3555L36.9727 62.3047C37.0898 62.1875 37.0898 61.9922 36.9727 61.875C36.8555 61.7773 36.6406 61.7773 36.5234 61.8945Z"
              fill="#12BEAB"
            />
          </svg>
        </div>
        <Card className={styles.endCard}>
          <p className={styles.endCardNormalP}>
            Na podany przez Ciebie adres e-mail niezwłocznie wyślemy dokumenty.
          </p>
          <p className={styles.endCardNormalP}>
            Maksymalny czas przygotowania Umowy wraz z załącznikami to 3 dni robocze.
          </p>
          <p className={styles.endCardNormalP}>
            Zapoznaj się z nimi. Jeżeli nie będziesz miał dodatkowych pytań bądź
            uwag potwierdź zawarcie umowy.
          </p>
          {documentVersion === EDocumentVersion.PZO && (
            <p className={styles.endCardBoldP}>
              Pamiętaj, że jeżeli w ciągu 14 dni nie podpiszesz umowy, ze
              względów bezpieczeństwa, zablokujemy licznik.
            </p>
          )}
          {documentVersion === EDocumentVersion.OT && (
            <p className={styles.endCardBoldP}>
              Pamiętaj, że dopiero po podpisaniu przez Ciebie umowy możemy podać
              napięcie. Zazwyczaj zajmuje nam to nie dłużej niż 7 dni roboczych
              od dnia podpisania umowy.
            </p>
          )}
          <p className={styles.endCardNormalP}>Dziękujemy za zaufanie.</p>
          <div>
            <SmallLogo />
          </div>

          <p className={styles.endCardBoldP}><br />Pomyślnie zakończono, możesz zamknąć tę stronę.</p>
        </Card>
      </Card>
    </div>
  )
}
