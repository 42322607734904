import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { ReactNode } from "react"

export interface CustomInputFieldProps {
  options: string[]
  inputValue: string
  id: string
  disabled?: boolean
  loading: boolean
  renderInput: (params) => ReactNode
  onInputChange: (event, value, reason) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    display: "flex",
    minHeight: 40,
    padding: theme.spacing(2, 3),
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    "&[aria-selected=true]": {
      // backgroundColor: autocompleteBackgroundColor,
    },
    "&[aria-selected=false][data-focus=true]": {
      backgroundColor: "white",
    },
    "& .MuiIconButton-root": {
      height: 20,
      width: 20,
      backgroundColor: "transparent",
    },
    "&:last-of-type": {
      marginBottom: theme.spacing(3),
    },
  },
  noOptions: {
    borderRadius: 4,
  },
  popper: {
    "& .MuiAutocomplete-paper": {
      padding: 0,
      marginTop: -1.8,
    },
    "& .MuiAutocomplete-listbox": {
      maxHeight: 136,
      border: `1.7px solid #E2E4E3`,
      borderRadius: 4,
    },
    "& .MuiPaper-rounded": {
      borderRadius: 4,
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
      border: "none",
    },
  },
  inputRoot: {
    "& input": {
      padding: "2px !important",
    },
  },
}))

export const CustomAutocompleteField: React.FC<CustomInputFieldProps> = ({
  options,
  renderInput,
  onInputChange,
  inputValue,
  id,
  disabled,
  loading,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Autocomplete
      disablePortal
      disableListWrap
      noOptionsText="Brak opcji"
      multiple={false}
      loadingText="Ładowanie"
      id={id}
      options={options ?? []}
      clearText=""
      clearOnBlur={false}
      getOptionLabel={item => {
        return item ?? ""
      }}
      classes={{
        popper: `${classes.popper} ${classes.popper ?? ""}`,
        option: classes.option,
        noOptions: classes.noOptions,
        root: classes.inputRoot,
      }}
      renderTags={() => null}
      freeSolo={true}
      onInputChange={onInputChange}
      disabled={disabled}
      loading={loading}
      getOptionSelected={(option, value) => option === value}
      inputValue={inputValue}
      renderInput={renderInput}
    />
  )
}
