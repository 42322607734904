import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { Dialog, DialogContent } from "@material-ui/core"
import StyledButton from "../Button"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      padding: 0,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      "& h1": {
        margin: 0,
        fontSize: "38px",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "& h3": {
        margin: "25px 0 0",
        fontSize: "15px",
        color: "#656D76",
        opacity: 0.55,
      },
      [theme.breakpoints.down("sm")]: {
        "& h1": {
          fontSize: "30px",
        },
        "& h3": {
          fontSize: "14px",
        },
      },
    },
    button: {
      display: "block",
      margin: "40px 0 12px",
      width: "225px",
      height: "48px",
      fontSize: "17px",
      [theme.breakpoints.down("xs")]: {
        width: "45vw",
      },
    },
    link: {
      color: "#00BDA8",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
)

interface SendUsEmailModalProps {
  show: boolean
  toggleModal: () => void
}

export const SendUsEmailModal: React.FC<SendUsEmailModalProps> = ({ toggleModal, show }) => {
  const classes = useStyles()

  return (
    <Dialog
      aria-describedby="send-us-email-modal"
      open={show}
      onClose={toggleModal}
      fullWidth
      className={classes.contentWrapper}
    >
      <DialogContent className={classes.content}>
        <h1>Wypełnij wniosek</h1>
        <h3>
          Prosimy o wypełnienie wniosku o wydanie warunków przyłączenia do sieci elektroenergetycznej Polenergia
          Dystrybucja i przesłanie go na adres{" "}
          <a className={classes.link} href="mailto:bok@polenergia.pl">
            bok@polenergia.pl
          </a>
          . Wniosek znajdziesz tutaj:{" "}
          <a className={classes.link} href="https://polenergia-dystrybucja.pl/dokumenty/" target="_blank">
            https://polenergia-dystrybucja.pl/dokumenty/
          </a>
        </h3>
        <StyledButton color="primary" variant="contained" onClick={() => toggleModal()} className={classes.button}>
          Powrót
        </StyledButton>
      </DialogContent>
    </Dialog>
  )
}
