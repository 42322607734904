import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { BaseProps } from "../../utils/baseProps"
import { useMediaQuery } from "@material-ui/core"
import theme from "../../styles/theme"

const LandingImage: React.FC<BaseProps> = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobileImage: file(relativePath: { eq: "bg-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = [
    { ...data.desktopImage.childImageSharp.fluid },
    {
      ...data.mobileImage.childImageSharp.fluid,
      media: `(max-width: 600px)`,
    },
  ]

  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Img
      alt="tło"
      className={className}
      fluid={sources}
      imgStyle={
        smallScreen
          ? {
              height: "70vh",
              objectFit: "cover",
              width: "100%",
              objectPosition: "top",
            }
          : {
              objectPosition: "center",
            }
      }
    />
  )
}

export default LandingImage
