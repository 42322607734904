import React from "react"

export const ForeignClientModalContent = () => {
  return (
    <>
      <h1>Formularz zgłoszeniowy</h1>
      <h2>
        W celu zawarcia umowy/zmiany oferty skontaktuj się z&nbsp;nami
        lub zamów kontakt w&nbsp;dogodnym dla Ciebie terminie.
      </h2>
    </>
  )
}
